<template>
    <div class="playground">
        <h1>Playground</h1>
        <SellerShareTable/>
    </div>
</template>

<script>
import SellerShareTable from '@/components/SellerShareTable.vue';

    export default {
        name: 'playground',
        components: {
            SellerShareTable
        }
    }
</script>

<style lang="less" scoped>
.playground {

}
</style>
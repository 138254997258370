<template>
    <div :class="['mp-field', 'flex', 'py-2', 'px-4', {'flex-row-reverse': !rtl}]" :style="{'height': `${height}px`}">
        <img v-if="icon && !canCopy" :src="getIcon()" />
        <copy class="ml-3" @click.native="copyToClipboard" v-if="canCopy && !isCopied" style="cursor: pointer;" />
        <copy class="ml-3" @click.native="copyToClipboard" v-if="canCopy && isCopied" color="#1b8161" style="cursor: pointer;"/>
        <input 
            ref="inputForCopy"    
            v-bind="$attrs"  
            v-on="$listeners"     
            :style="{'direction': rtl ? 'rtl' : 'ltr', 'background-color': '#fff'}"
            class="w-full"
            style="outline: none;"
            :type="type"
            :placeholder="placeholder"
            :disabled="disable"
        >
    </div>
    
</template>

<script>
import { Copy } from "@/components/svg";
export default {
  name: "MpField",
  components: { Copy },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    getIcon() {
      return require(`@/assets/${this.icon}`);
    },
    async copyToClipboard() {
      let inputForCopy = this.$refs.inputForCopy;
      inputForCopy.select();
      await navigator.clipboard.writeText(inputForCopy.value);
      this.isCopied = true;
    },
  },
  props: {
    placeholder: {
      type: String,
    },
    rtl: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    height: {
      type: Number,
      default: 48,
    },
    type: {
      type: String,
      default: "text",
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.mp-field {
    border: 1px solid #F1F1F1;
    outline: none;
    width: 100%;
    border-radius: 8px;  
    background-color: #fff;       
    input {
        &::placeholder{
                color: rgba(0, 0, 0, 0.6);
                font-size: 14px;        
        }
    }
        
    
}
</style>
<script>
const RESEND_TIMER_DURATION = 60; // in second

export default {
    name: "LmnResendOTP",
    props: {
        method: {
            type: 'email' | 'mobile'
        },
        value: {
            type: String
        }
    },
    data() {
        return {
            canResend: false,
            remainTimeToResend: RESEND_TIMER_DURATION,
        };
    },
    created() {
        this.resendOTPTimer();
    },
    methods: {
        resendOTPHandler() {
            if (this.canResend) {
                this.$emit("onResend");
                this.resendOTPTimer();
                return;
            }
        },
        resendOTPTimer() {
            this.canResend = false;
            this.remainTimeToResend = RESEND_TIMER_DURATION;
            const timer = setInterval(() => {
                this.remainTimeToResend--;
                if (this.remainTimeToResend === 0) {
                    clearInterval(timer);
                    this.canResend = true;
                }
            }, 1000);
        },
    },
};
</script>

<template>
    <div class="lmn-resend-otp">
        <div class="lmn-resend-otp__description">
            <p>
                <span v-if="method === 'mobile'" style="display: block;">
                    کد از طریق پیامک به شماره
                    <span>
                        {{ value || "{mobileNumber}" }}
                    </span>
                    ارسال شد.
                </span>
                <span v-if="method === 'email'" style="display: block;">
                    کد به ادرس ایمیل
                    <span>
                        {{ value || "{email}" }}
                    </span>
                    ارسال شد.
                </span>
                <span style="display: block" v-if="remainTimeToResend">
                    لطفا
                    <span>
                        {{ remainTimeToResend || "{ramainTimeToResendOTP}" }}
                    </span>
                    ثانیه منتظر بمانید.
                </span>
                <span>در صورتی که کد برای شما ارسال نشد از دکمه زیر استفاده کنید</span>
            </p>
        </div>
        <div class="lmn-resend-otp__actions">
            <div class="lmn-resend-otp__action-container">
                <p v-if="remainTimeToResend" style="color: #757575" >{{remainTimeToResend}} ثانیه تا ارسال مجدد</p>
                <button v-else type="button" @click="resendOTPHandler()" :class="[
                  'lmn-resend-otp__btn',
                  canResend
                    ? 'lmn-resend-otp__btn--enabled'
                    : 'lmn-resend-otp__btn--disabled',
                ]">
                    ارسال مجدد
                </button>
            </div>
        </div>
    </div>
</template>


<style lang="less">
.lmn-resend-otp {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__description {
        display: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
        letter-spacing: -0.2px;
        color: #757575;
    }

    &__actions {}

    &__action-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        letter-spacing: -0.2px;
    }

    &__btn {
        padding: 4px;

        &--disabled {
            color: #ccc;
        }

        &--enabled {
            color: #3D7534;
        }
    }
}
</style>

<template>
    <div class="">
      <div
        :class="['btn', 'text-IranSans-Medium', { btn_disable: disable }]"
        v-bind="$attrs"
        :id="id"
        v-on="$listeners"
      >
        <div v-if="!loading" class="flex gap-2">
          <p>{{ title }}</p>
          <img src="@/assets/tara.webp" class="w-6 h-6" alt="tara service logo" />
        </div>
        <div v-else :class="{ 'loading-spinner': loading }"></div>
      </div>
      <p
          v-if="!disable"
        class="px-2 text-center text-IranSans-Medium text-xs mt-2 btn__description"
      >
        {{ description }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: "MpButton",
    props: {
      id: {
      type: String,
    },
      title: {
        type: String,
        require,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disable: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: "",
      },
    },
  };
  </script>
  
  <style lang="less" scoped>

.loader {
    border: 5px solid #fff;
    border-top: 5px solid #F1F1F1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
  }
  .loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #559847;
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: white;
    border: 1px solid #312782 !important;
    color: #be087f !important;
    border-radius: 4px;
    cursor: pointer;
  
    &_disable {
      background-color: rgb(180, 180, 180);
      border-color: white !important;
      color: white !important;
      cursor: not-allowed;
    }
  
    &__description {
      direction: rtl !important;
      color: #312782 !important;
    }
  }
  </style>
  
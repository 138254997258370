import { makeObservable, observable, computed, action, runInAction } from "mobx";
import { ProductServices } from "@/services";
import { configStore } from '@/stores/index';

class ProductStore {
    sellerProducts = null;
    filterValue = "";
    filterTags = []
    tags = [];

    constructor() {
        makeObservable(this, {
            sellerProducts: observable,
            filterValue: observable,
            filterTags: observable,
            tags: observable,
            filteredProducts: computed,
            getAllSellerProducts: action,
            setFilterValue: action,
            getTags: action
        });
    }

    async getAllSellerProducts() {
        let result = await ProductServices.getAllSellerProducts();
        if (!result.err) {
            runInAction(() => {
                this.sellerProducts = result;
                this.getTags();
            });
            return true;
        }
        return result;
    }

    setFilterValue(value) {
        this.filterValue = value;
    }

    getTags() {
        runInAction(() => {
            configStore.configService.productTags 
                ? this.tags = configStore.configService.productTags 
                : this.tags = [];
        });
    }

    get filteredProducts() {
        return this.sellerProducts.filter(item => {
            let hasTag = false;
            let hasFilterValue = item.title.includes(this.filterValue);

            if (this.filterTags.length === 0 && hasFilterValue)
                return true;

            item.meta?.tags?.forEach(tag => {
                this.filterTags.forEach(filterTag => {
                    if (tag.label === filterTag) {
                        hasTag = true;
                    }
                })
            })

            if (hasTag && hasFilterValue)
                return true

            return false;
        });
    }
}

export const productStore = new ProductStore();

import { makeObservable, observable, computed, action,reaction  } from "mobx";
import { GiftService } from "@/services";
class GiftStore {
  _giftItem = {};
  _actionGift=false;
  constructor() {
    makeObservable(this, {
        _giftItem: observable,
        _actionGift: observable,
        toggleActionGift:action,
        setGiftItem:action,
       

    });
    reaction(
        () => this._actionGift,
        (actionGift) => {
          if (actionGift) {
          }
        }
      );
  }
  
  get giftItem() {
    return this._giftItem;
  }
  toggleActionGift(payload){
    this._actionGift = payload
  }
  setGiftItem(payload) {
    this._giftItem = payload
  }
  async getChallenges(param){
    try {
        let result = await GiftService.getChallenges(param)
        return result;
      } catch (error) {
        throw error;
      }
    
  }
}
export const giftStore = new GiftStore();

<template>
  <div>
    <div v-if="currentOrder && isOrderExpired" class="flex flex-col h-screen items-center justify-center">
      <p class="text-IranSans-Bold text-2xl text-red-500 mb-5 text-center" style="direction: rtl;">
        لینک شما منقضی شده است!
      </p>
      <p class="text-IranSans-Medium text-gray-700 text-center mb-4" style="direction: rtl;">
        جهت دریافت <span class="font-bold"> لینک جدید</span>  با <span class="font-bold"> مشاور </span> خود ارتباط بگیرید.      </p>
      <p class="text-IranSans-Medium text-gray-700 text-center mb-4" style="direction: rtl;">
        در صورتی که شماره مشاور خود را ندارید، فرم زیر را پر کنید:
      </p>
      <a href="https://survey.porsline.ir/s/jzO0CZlX" class="text-green-500 text-center"
        style="direction: rtl;">
        فرم دریافت لینک جدید
      </a>
    </div>

    <div v-else-if="
      currentOrder &&
      (checkLoadingState === 'SUCCESS' || checkLoadingState === 'FAILED')
    " class="payment mx-auto">
      <p class="text-IranSans-Bold text-base text-right mb-4 px-4 pt-4">سبد خرید</p>
      <p class="text-IranSans-Light text-xs text-right mb-6 px-4 pt-4" style="direction: rtl">
        {{ customerFullName }}
        عزیز شما با شماره {{ currentOrder.customer.mobile }} در حال خرید خدمات زیر هستید.
        برای ادامه، روی دکمه {{ stateCustomer }}
        کلیک کنید.
      </p>
      <mp-card :border="true" class="my-4 px-4" v-for="product in products" :key="product.id">
        <template v-if="getIcon(product)" #icon>
          <img class="ml-3" :src="getIcon(product)" />
        </template>
        <template #title>
          <p class="text-IranSans-Bold text-xs">{{ product.meta.title }}</p>
        </template>
        <template #price>
          <div class="flex flex-col justify-between items-center" style="color: #696969">
            <p class="text-IranSans-Medium text-xs">
              ({{ product.count || 1 }} عدد)

              {{ numberCommas(getPriceByCount(product)) }} تومان
            </p>
            <p class="text-IranSans-Medium text-xs">
              {{ numberCommas(product.productPrice) }}
              {{ product.currency }}
            </p>
          </div>
        </template>
      </mp-card>
      <div>
        <div v-if="OrderStatus === 'PAYMENT_PENDING' && isC2C === true"></div>
        <div v-else>
          <IsGiftCheckbox :isGift="isGift" :loadingForCheckBox="loadingForCheckBox"
            @checkbox-change="handleCheckboxChange" />
        </div>
      </div>

      <div v-if="
        (OrderStatus === 'PAYMENT_PENDING' && isC2C === true) ||
        OrderStatus === 'PAYMENT_SUCCESS'
      " class="fixed bottom-48 left-0 py-6 px-4 w-full flex justify-center">
        <div v-if="OrderStatus === 'PAYMENT_PENDING'"
          class="rounded border border-slate-300 bg-gray-50 flex justify-center items-center lg:w-3/6 sm:w-5/6 xs:w-5/6">
          <p class="text-sm font-normal m-4 text-center leading-6 text-IranSans-Bold">
            پرداخت شما در حال بررسی توسط پشتیبانی است
          </p>
        </div>
        <div v-if="OrderStatus === 'PAYMENT_SUCCESS'"
          class="rounded border border-green-200 bg-gray-50 flex justify-center items-center lg:w-3/6 sm:w-5/6 xs:w-5/6">
          <p class="text-sm font-normal m-4 text-center leading-6 text-IranSans-Bold">
            این سفارش قبلا پرداخت شده است
          </p>
        </div>
      </div>
      <PaymentButton :allowedPaymentGatwaysList="allowedPaymentGatwaysList" :OrderStatus="OrderStatus" :isC2C="isC2C"
        :formStep="formStep" :isGift="isGift" :totalFinalPrice="totalFinalPrice" :loading="loading"
        :isSnappPaymentEligible="isSnappPaymentEligible" :snappPayDescription="snappPayDescription" :messages="messages"
        @openAddressForm="openAddressForm" @openPaymentBox="openPaymentBox" @backToBeforeState="backToBeforeState"
        @checkout="checkout" @gotoC2cCheckout="gotoC2cCheckout" />
    </div>

    <div v-else class="flex flex-col h-screen items-center justify-center">
      <p class="text-IranSans-Bold">... در حال بارگیری اطلاعات</p>
    </div>
    <div v-if="currentOrder && !isOrderExpired" class="w-full h-96"></div>
    <MpAddressForm @formAddressSubmitted="handleformAddressSubmitted" :sellerCustomerOrderId="orderCustomerId"
      :orderAddress="orderAddress" :orderIdForAddress="currentOrder.order?.id" ref="MpAddressForm" />
    <MpGiftForm :isGift="isGift" :isShipmentAddress="isShipmentAddress" :orderAddressGift="orderAddressGift"
      :orderIdForAddress="currentOrder.order?.id" @giftFormAddressSubmited="handleGiftFormAddressSubmited"
      ref="MpGiftForm" />
    <div class="fixed bottom-0 mt-auto w-full payment h-7 bg-gray-100 z-0">
      <p class="text-IranSans-Medium text-xs text-gray-800 p-1">
        .تمامی حقوق مادی و معنوی این سایت محفوظ می باشد
      </p>
    </div>
    <MpNotification ref="notificationRef" />
  </div>
</template>

<script>
import MpButton from "@/components/MpButton.vue";
import MpButtonSnapp from "@/components/MpButtonSnapp.vue";
import MpButtonTara from "@/components/MpButtonTara.vue";
import MpCard from "@/components/MpCard.vue";
import MpProductDetail from "@/components/MpProductDetail.vue";
import MpAddressForm from "@/components/MpAddressForm.vue";
import MpGiftForm from "@/components/MpGiftForm.vue";
import PaymentButton from "@/components/PaymentButton.vue";
import IsGiftCheckbox from "@/components/IsGiftCheckbox.vue";
import { ArrowRight } from "@/components/svg";
import MpNotification from "@/components/MpNotification.vue";
import { OrderSevices } from "@/services/index";
import { orderStore, configStore } from "@/stores";
import { Converter } from "@/utils";
import { observer } from "mobx-vue";
import lodash from "lodash";
export default observer({
  name: "Payment",
  components: {
    MpCard,
    MpButton,
    MpProductDetail,
    MpButtonSnapp,
    MpButtonTara,
    MpAddressForm,
    MpGiftForm,
    ArrowRight,
    MpNotification,
    PaymentButton,
    IsGiftCheckbox,
  },
  data() {
    return {
      totalFinalPrice: 0,
      notification: {
        visible: false,
        text: "",
        type: "",
        closable: true,
      },
      loadingForCheckBox: false,
      isPaymentEligible: false,
      isGift: false,
      isShipmentAddress: false,
      formAddressSubmitted: false,
      giftFormAddressSubmited: false,
      orderAddress: null,
      orderAddressGift: null,
      isOrderExpired: false,
      currentOrder: {
        order: null,
        sellerCustomerOrder: null,
        seller: null,
        customer: null,
      },
      loading: false,
      isSnappPaymentEligible: false,
      checkLoadingState: "IDLE", // FAILED, SUCCESS, PENDING
      OrderStatus: "", // PAYMENT_FAILURE, CREATED, PAYMENT_PENDING,PAYMENT_SUCCESS
      isC2C: false,
      recipientIsThere: false,
      snappPayDescription: "",
      messages: {
        checkout: {
          status: null,
          desc: null,
        },
      },
    };
  },
  props: ["orderCustomerId"],
  created() {
    this.initialize();
    this.pageView();
    this.setAnalytics();
  },
  methods: {
    async initialize() {
      try {
        this.checkLoadingState = "PENDING";
        let res = await orderStore.fetchOrderById(this.orderCustomerId);
        this.currentOrder = res;

        this.checkOrderExpiration();

        await this.getInvoiceData(this.currentOrder.sellerCustomerOrder.orderId);
        if (this.allowedPaymentGatwaysList.includes("ZEENAJI_SNAPP_PAY") || this.allowedPaymentGatwaysList.includes("SNAPP_PAY")) {
          this.checkSnappPaymentEligibility(this.totalFinalPrice);
        }
        this.currentOrder.order.basketItems.map((item) => {
          if (item.meta.needShipment && item.meta.needShipment === true) {
            this.isShipmentAddress = true;
          }
        });
        window.sessionStorage.setItem("humanizedId", this.currentOrder.order.humanizedId);
        window.localStorage.setItem("service", this.currentOrder.order.service);
        await this.checkOrderStatus(this.currentOrder.order.orderStatuses);
        await this.checkHasAlreadyAddress(this.currentOrder.order);
        await this.checkIsGift(this.currentOrder.sellerCustomerOrder);
        setTimeout(() => { this.checkLoadingState = "SUCCESS"; }, 1500);
      } catch (error) {
        console.log('errr: ', error)
        this.checkLoadingState = "FAILED";
      }
    },
    checkOrderExpiration() {
      const createdAt = new Date(this.currentOrder.order.createdAt);
      const now = new Date();
      const twoMonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
      this.isOrderExpired = createdAt < twoMonthsAgo;
    },
    async continueFetchConfig() { },
    async handleCheckboxChange() {
      if (this.isGift) {
        if (this.currentOrder.sellerCustomerOrder.meta?.isGift) {
          this.loadingForCheckBox = true;
          try {
            await orderStore.deleteOrderIsGift(this.currentOrder.order.id);
            this.loadingForCheckBox = false;
            this.isGift = false;
            this.currentOrder.sellerCustomerOrder.meta.isGift = false;
            this.$refs.notificationRef.showNotification("با موفقیت حذف شد", "success");
            this.getOrder();
          } catch (error) {
            console.log(error);
            this.loadingForCheckBox = false;
            this.isGift = true;
            this.currentOrder.sellerCustomerOrder.meta.isGift = true;
            this.$refs.notificationRef.showNotification(
              "با خطا مواجه شد",
              "error",
              5000,
              true
            );
          }
        } else {
          this.isGift = false;
        }
      } else {
        this.isGift = true;
        this.isPaymentEligible = false;
      }
    },
    async openPaymentBox() {
      if (this.isGift && !this.currentOrder.sellerCustomerOrder.meta.isGift) {
        await this.activeIsGift();
      } else {
        this.isPaymentEligible = true;
      }
    },
    async activeIsGift() {
      const { firstName, lastName, mobile, country, state, city, details, postalCode } =
        this.orderAddressGift || {};

      if (firstName && lastName && mobile) {
        try {
          const recipient = { firstName, lastName, mobile };

          if (this.isShipmentAddress) {
            Object.assign(recipient, {
              country,
              state,
              city,
              details,
              postalCode,
            });
          }

          const postData = { isGift: true, recipient };
          const response = await orderStore.createOrderIsGift(
            this.currentOrder.order.id,
            postData
          );

          if (response.data.id) {
            this.$refs.notificationRef.showNotification(
              "هدیه ثبت شد",
              "success",
              5000,
              true
            );
            this.isPaymentEligible = true;
            this.isGift = true;
            this.currentOrder.sellerCustomerOrder.meta.isGift = true;
          } else {
            this.handleGiftError();
          }
        } catch (error) {
          this.handleGiftError();
        }
      } else {
        this.$refs.MpGiftForm.$refs.mpActionSheet.open();
      }
    },
    handleGiftError() {
      this.isGift = false;
      this.$refs.notificationRef.showNotification(
        "ثبت هدیه با خطا مواجه شد",
        "error",
        5000,
        true
      );
      this.getOrder();
    },
    backToBeforeState() {
      this.isPaymentEligible = false;
      this.getOrder();
    },
    handleformAddressSubmitted(form) {
      if (form) {
        this.getOrder();
        this.formAddressSubmitted = true;
      }
    },
    handleGiftFormAddressSubmited(form) {
      if (form) {
        this.getOrder();
        this.giftFormAddressSubmited = true;
      }
    },
    openAddressForm() {
      if (this.isGift) {
        this.$refs.MpGiftForm.$refs.mpActionSheet.open();
      } else {
        this.$refs.MpAddressForm.$refs.mpActionSheet.open();
      }
    },
    async checkSnappPaymentEligibility(price = 0) {
      try {
        const snappResponse = await orderStore.checkSnappPayEligibity({
          amount: price * 10,
        });
        if (snappResponse && snappResponse.response) {
          this.isSnappPaymentEligible = snappResponse.response.eligible;
          this.snappPayDescription = snappResponse.response.description;
        } else {
          this.isSnappPaymentEligible = false;
          this.showPaymentMethodsPopupLoading = false;
        }
      } catch (error) {
        this.isSnappPaymentEligible = false;
        this.showPaymentMethodsPopupLoading = false;
      }
    },
    gotoC2cCheckout() {
      this.$router.push({
        name: "c2c-payment",
        params: { orderCustomerId: this.orderCustomerId },
      });
    },
    async checkOrderStatus(orderStatuses) {
      if (orderStatuses.length > 0) {
        const sortedOrderStatus = orderStatuses.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.OrderStatus = sortedOrderStatus[0].status;
        if (this.OrderStatus === "PAYMENT_PENDING") {
          try {
            const result = await orderStore.getPaymentById(
              this.currentOrder.order.paymentId
            );
            if (result.err) {
              this.isC2C = false;
            }
            if (result && result.gateway === "C2C") {
              this.isC2C = true;
            }
          } catch (error) {
            this.isC2C = false;
            throw error;
          }
        }
      }
    },

    async getOrder() {
      try {
        const result = await orderStore.fetchOrderById(this.orderCustomerId);
        this.currentOrder = result;
        await this.checkHasAlreadyAddress(this.currentOrder.order);
        await this.checkIsGift(this.currentOrder.sellerCustomerOrder);
      } catch (error) { }
    },
    async getInvoiceData(id) {
      try {
        await orderStore.getInvoiceById(id);
      } catch (error) { }
      this.totalFinalPrice = orderStore.invoice.totalFinalPrice;
    },
    checkShowGift() {
      if (window.sessionStorage.getItem("showGift")) {
        if (window.sessionStorage.getItem("showGift") === "true") {
          this.showGift = true
        }
        if (window.sessionStorage.getItem("showGift") === "false") {
          this.showGift = false
        }
      }
    },
    async checkIsGift(params) {
      if (params && params.meta && params.meta.isGift) {
        this.isGift = true;
      } else {
        this.isGift = false;
      }


    },
    async checkHasAlreadyAddress(order) {
      if (order.recipient && order.recipient.id) {
        this.orderAddress = order.recipient;
        this.orderAddressGift = order.recipient;
        this.giftFormAddressSubmited = true;
        this.formAddressSubmitted = true;
        order.recipient && !order.recipient.isDeleted
          ? (this.recipientIsThere = true)
          : (this.recipientIsThere = false);
      }
    },

    async checkout(type) {
      const gateways = {
        ONLINE: "ZEENAJI_VANDAR",
        SNAPP_PAY: "ZEENAJI_SNAPP_PAY",
        RABEEEN_TARA: "RABEEEN_TARA",
        LIMOOME_SHOP_ONLINE: "ONLINE",
        LIMOOME_SHOP_SNAPP_PAY: "SNAPP_PAY",
      };
      const Sco = this.$route.params.orderCustomerId || '';
      const gateway = gateways[type] || "";
      if (!gateway) return; // Add proper error handling if needed

      const config = {
        mobile: this.currentOrder.customer.mobile
          ? `+98${this.currentOrder.customer.mobile.substring(1)}`
          : null,
      };

      this.loading = true;
      this.resetData();

      try {
        const result = await orderStore.checkout(
          this.currentOrder.order.basketItems[0].orderId,
          this.currentOrder.customer.id,
          gateway,
          Sco,
          config
        );
        if (result?.err) {
          this.messages.checkout.desc = result.err.message;
          this.messages.checkout.status = "error";
        }
      } catch (error) {
        this.messages.checkout.desc = error.message || "An error occurred";
        this.messages.checkout.status = "error";
      } finally {
        this.loading = false;
      }
    },
    resetData() {
      this.messages.checkout.desc = null;
      this.messages.checkout.status = null;
    },
    pageView() {
      gtag("event", "view-link", {
        event_category: "sale_network",
        event_label: `${this.orderCustomerId}`,
      });
    },
    async setAnalytics() {
      await OrderSevices.setAnalytics(this.orderCustomerId);
    },
    getIcon(product) {
      if (product?.meta?.images?.icon) {
        return require(`@/assets/products/${product?.meta?.images?.icon}`);
      } else {
        return require(`@/assets/package.svg`);
      }
    },
    getPriceByCount(product) {
      return product.productPrice / (product.count ? product.count : 1);
    },
    numberCommas(x) {
      return Converter.numberWithCommas(x);
    },
  },
  watch: {
    formAddressSubmitted(newState, oldState) {
      if (newState) {
        this.stateCustomer;
      }
    },
  },
  computed: {
    formStep() {
      let result = null;
      if (this.isPaymentEligible) {
        result = "3";
      } else {
        if (this.isGift && this.isShipmentAddress) {
          if (
            this.formAddressSubmitted ||
            this.giftFormAddressSubmited ||
            this.recipientIsThere
          ) {
            result = "2";
          } else {
            result = "1";
          }
        }
        if (this.isGift && !this.isShipmentAddress) {
          if (this.giftFormAddressSubmited || this.recipientIsThere) {
            result = "2";
          } else {
            result = "1";
          }
        }
        if (!this.isGift && this.isShipmentAddress) {
          if (this.formAddressSubmitted) {
            result = "2";
          } else {
            result = "1";
          }
        }
        if (!this.isGift && !this.isShipmentAddress) {
          result = "3";
        }
      }

      return result;
    },
    stateCustomer() {
      if (this.isShipmentAddress && !this.formAddressSubmitted) {
        return "تکمیل خرید";
      } else {
        return "پرداخت";
      }
    },
    products() {
      return this.currentOrder.order.basketItems.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
    },
    allowedPaymentGatwaysList() {
      if (configStore.configService.service === 'limoome_shop') {
        return ['LIMOOME_SHOP_ONLINE', 'C2C', 'SNAPP_PAY']
      } else {
        if (this.products.length > 0) {
          const allProductsPayementGateways = this.products.reduce((acc, curr) => {
            acc.push(curr?.meta?.paymentGateways);
            return acc;
          }, []);
          return lodash.intersection(...allProductsPayementGateways);
        }
        return [];
      }

    },
    customerFullName() {
      return (
        this.currentOrder.order.customer.firstName +
        " " +
        this.currentOrder.order.customer.lastName
      );
    },
    sellerFullName() {
      return this.currentOrder.seller.firstName + " " + this.currentOrder.seller.lastName;
    },
    count() {
      const count = {};
      this.products.forEach((product) => {
        count[product.id] = (count[product.id] || 0) + 1;
      });
      return count;
    },
  },
});
</script>

<style lang="less" scoped>
.payment {
  max-width: 600px;
  color: #333333;

  &_selected-product {
    width: 100%;
  }

  &_btn {
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
    max-width: 600px;
  }

  &_error {
    color: rgba(255, 107, 107, 1);
    direction: rtl;
  }

  .btn-edit-address {
    color: #559847;
    background-color: white;
    border: 1px solid #559847;
  }

  .border-green-app {
    border: 1px solid #559847;
  }

  .color-green-app {
    color: #559847;
  }

  .background-green-app {
    background-color: #559847;
  }

  .loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #559847;
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  } 

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>

<template>
    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25001 12.9401L1.36 8.05006C0.782505 7.47256 0.782505 6.52756 1.36 5.95006L6.25001 1.06006" stroke="#559847" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#4B4C4D'
        }
    }
}

</script>
import { makeObservable, observable,runInAction, computed,action } from "mobx";
import { OrderSevices } from "@/services";
import { userStore, productStore } from "@/stores";
import moment from 'moment-jalaali';
class OrderStore {
  saleHistory = null;
  basketItems = [];
  basketTaraItems =[];
  currentOrder = {};
  invoice = null;
  constructor() {
    makeObservable(this, {
      currentOrder:observable,
      invoice:observable,
      saleHistory: observable,
      basketItems: observable,
      hashMappedBasketItems: computed,
      sellerBasket: computed,
      basketItemsTotalSellerShare: computed,
      addToBasketItems: action,
      removeFromBasketItems: action,
      basketTaraItems:observable,
    });
  }

  get sellerBasket() {
    let result = [];
    this.basketItems.forEach((bi) => {
      productStore.sellerProducts.forEach((sp) => {
        if (sp.id === bi.sellerProductId) result.push(sp);
      });
    });
    return result;
  }

  get hashMappedBasketItems() {
    if (this.basketItems.length == 0) return null;
    let result = this.basketItems?.reduce(function (map, obj) {
      map[obj?.sellerProductId] = obj;
      return map;
    }, {});
    return result;
  }

  get basketItemsTotalSellerShare() {

    // console.log("this.basketItems",this.basketItems);
    if (this.basketItems.length > 0) {
      return this.basketItems.reduce(
        (acc, curr) => (acc += curr.options.sellerShare * curr.count),
        0
      );
    } else {
      return 0;
    }
  }

  get basketItemsTotalExtraShare() {
    if (this.basketItems.length > 0) {
      return this.basketItems.reduce(
        (acc, curr) =>
          curr.options.extraShareForNewCustomer
            ? (acc += curr.options.extraShareForNewCustomer)
            : (acc += 0),
        0
      );
    } else {
      return 0;
    }
  }



  addToBasketItems(sellerProductId, options = {}) {
    const objWithIdIndex = this.basketItems.findIndex(
      (obj) => obj.sellerProductId === sellerProductId
    );
    const hasPermissiontoAddMore = () => {
      if (this.basketItems[objWithIdIndex].options.maxCountPerOrder && this.basketItems[objWithIdIndex].options.maxCountPerOrder > 0) {
        if (this.basketItems[objWithIdIndex].count < this.basketItems[objWithIdIndex].options.maxCountPerOrder) {
          return true;
        } else {
          return false;
        }
      }else{
        return false
      }
    }
    if (objWithIdIndex === -1) {
      this.basketItems.push({
        sellerProductId: sellerProductId,
        count: 1,
        options: { ...options },
      });
    }
    if (objWithIdIndex > -1 ) {
      if ( hasPermissiontoAddMore()) {
        this.basketItems[objWithIdIndex].count = this.basketItems[objWithIdIndex].count + 1;
        let newItem = {...this.basketItems[objWithIdIndex]};
        this.basketItems[objWithIdIndex] = newItem;
      }else{
        this.basketItems[objWithIdIndex].count = this.basketItems[objWithIdIndex].count;
        let newItem = {...this.basketItems[objWithIdIndex]};
        this.basketItems[objWithIdIndex] = newItem;
      }
    }
  }
  addToBasketTaraItems(sellerProductId, options = {}) {
    // const objWithIdIndex = this.basketTaraItems.findIndex(
    //   (obj) => obj.sellerProductId === sellerProductId
    // );
    // let count = 0;
    // if (objWithIdIndex > -1) {
    //   count = this.basketTaraItems[objWithIdIndex].count;
    //   this.basketTaraItems.splice(objWithIdIndex, 1);
    // }
    this.basketTaraItems.push({
      sellerProductId: sellerProductId,
      count:1,
      options: { ...options },
    });
  }
  removeToBasketTaraItems(sellerProductId) {
    this.basketTaraItems = [];
  }
  removeFromBasketItems(sellerProductId) {
    const objWithIdIndex = this.basketItems.findIndex(
      (obj) => obj.sellerProductId === sellerProductId
    );
    if (objWithIdIndex === -1){
    }else{
      if (objWithIdIndex > -1 && this.basketItems[objWithIdIndex].count > 1) {
        this.basketItems[objWithIdIndex].count = this.basketItems[objWithIdIndex].count - 1;
        let newItem = {...this.basketItems[objWithIdIndex]};
        this.basketItems[objWithIdIndex] = newItem;
      }else{
        this.basketItems.splice(objWithIdIndex, 1);
      }
    }
  }

  async createOrder(param) {
    let result = await OrderSevices.createOrder(param, this.basketItems);
    return result;
  }
  async fetchOrderById(id){
    try {
      let result = await OrderSevices.getOrder(id)
      this.currentOrder = result;
      return result;
    } catch (error) {
      throw error;
    }

  }
  async getInvoiceById(id){
    let result = await OrderSevices.getInvoiceById(id)
    this.invoice = result;
  }
  async patchOrderDetails(sellerCustomerOrderId, details) {
    let result = await OrderSevices.setOrderDetails(sellerCustomerOrderId, details);
    return result;
  }  async createTaraOrder(customer){
    let result = await OrderSevices.createOrder(param, this.basketTaraItems);
    return result;
  }
  async getAllSaleHistory() {
    let results = await OrderSevices.getOrdersBySellerID();
    this.saleHistory = results;
  }
  async getSellerMonthlyShare(){
    const result =  await OrderSevices.getSellerMonthlyShare()
    return result;
  }
  getAllSalePrice(sh) {
    if (!sh) return 0;
    let sum = 0;
    sh.forEach((o) => {
      if (this.isOrderSuccessful(o.order.orderStatuses)) {
        sum += o.order.basketItems.reduce((accumulator, object) => {
          return accumulator + object.productPrice;
        }, 0);
      }
    });
    return sum;
  }

  getAllContributePrice(sh) {
    if (!sh) return 0;
    let sum = 0;
    sh.forEach((o) => {
      if (this.isOrderSuccessful(o.order.orderStatuses))
        sum += o.order.basketItems.reduce((accumulator, object) => {
          return accumulator + object.meta.sellerShare;
        }, 0);
    });
    return sum;
  }
  getMonthRange() {
    const today = moment();
    const jalaliYear = today.jYear();
    const jalaliMonth = today.jMonth();
    const firstDay = moment().jYear(jalaliYear).jMonth(jalaliMonth).startOf('jMonth');
    const lastDay = moment().jYear(jalaliYear).jMonth(jalaliMonth).endOf('jMonth');
    const startDate = firstDay.format('YYYY-MM-DD');
    const endDate = lastDay.format('YYYY-MM-DD');
    return { gte: startDate, lte: endDate };
  }
  async getAllRewardPrice(){
    const dateRange = this.getMonthRange();
   const result =  await OrderSevices.getAllRewardUserPerDate(dateRange)
    return result;
  }
  getOrderLastStatus(saleHistory) {
    let lastStatus =
      saleHistory.order.orderStatuses[saleHistory.orderStatuses.length - 1];
    return lastStatus;
  }

  isOrderSuccessful(orderStatuses) {
    let paymentSuccessStatus = orderStatuses.find(
      (orderStatus) => orderStatus.status === "PAYMENT_SUCCESS"
    );
    let result = paymentSuccessStatus
      ? this.isThisMonth(paymentSuccessStatus.createdAt)
      : false;
    return result;
  }

  isThisMonth(paymentSuccessStatusDate) {
    let date = paymentSuccessStatusDate;
    let dateP = new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: "IRAN" }
      )
    ).toLocaleString("fa-IR-u-nu-latn");
    dateP = dateP.split(",")[0].split("/");
    let now = new Date();
    let nowP = new Date(
      (typeof now === "string" ? new Date(now) : now).toLocaleString("en-US", {
        timeZone: "IRAN",
      })
    ).toLocaleString("fa-IR-u-nu-latn");
    nowP = nowP.split(",")[0].split("/");
    if (nowP[0] === dateP[0] && nowP[1] === dateP[1]) return true;
    return false;
  }

  async checkout(orderId, customerId, gateway, Sco, config = {}) {
    let result = await OrderSevices.checkout(orderId, customerId, gateway, Sco, config);
    if (gateway === "RABEEEN_TARA") {
      this.gotoTaraGateway(result);
    }else{
      if (gateway === "RABEEEN_TARA") {
        this.gotoTaraGateway(result);
    }else{
        if (!result.err) {
          window.location.href = result.paymentLink;
        } else return result;
      }
    }
  }
  gotoTaraGateway({token, username}){
    var form = document.createElement("form");
    var element1 = document.createElement("input");
    var element2 = document.createElement("input");
    form.method = "POST";
    if (window.location.host === "panel.rabeeen.com") {
      form.action = "https://pay.tara360.ir/pay/api/ipgPurchase";
    } else {
      form.action = "https://stage-pay.tara360.ir/pay/api/ipgPurchase";
    }
    form.style.display = "none";
    element1.value=token;
    element1.name="token";
    form.appendChild(element1);
    element2.value=username;
    element2.name="username";
    form.appendChild(element2);
    document.body.appendChild(form);
    form.submit();
  }
async deleteOrderIsGift(id){
    let result = await OrderSevices.deleteOrderIsGift(id);
    return result;
  }
async createOrderIsGift(id,body){
  let result = await OrderSevices.createOrderIsGift(id,body);
    return result;
  }
async getPaymentById(id){
    let result = await OrderSevices.getPaymentById(id);
    return result;
  }
async checkSnappPayEligibity(body){
    let result = await OrderSevices.checkSnappPayEligibity(body);
    return result;
  }
}
export const orderStore = new OrderStore();

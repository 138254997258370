export function toEnNumber(value) {

    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

    if (typeof value === 'string') {
        for (let i = 0; i < 10; i++) {
            value = value.replace(persianNumbers[i], String(i)).replace(arabicNumbers[i], String(i));
        }
    }
    return value;

}

export function     numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
<template>
  <div class="order-card py-4 px-4">
      <div v-if="source" class="order-card_status text-IranSans-Light px-2" :style="{'background-color': getColor(source)}">{{getTitle(source)}}</div> 
      <!-- <div v-else class="bg-gray-300 rounded-md animate-pulse order-card_status h-4 w-20"></div>  -->
      <div v-if="source">
        <div class="flex flex-row-reverse items-center justify-between mb-4">
                            <div class="flex items-center justify-start">
                                <p class="text-IranSans-Bold text-sm">{{ getCustomer(source) }}</p>
                                <img class="ml-2" src="@/assets/pesonel-green.svg" />
                            </div>
                            <div class="flex flex-row-reverse items-center">
                                <p class="text-IranSans-Medium text-sm text-black">{{
                               numberWithCommas(getTotalBasketPrice(source.order)) }}</p>
                                <p class="text-IranSans-Medium text-xs mr-2" style="color: #858585;">
                                    {{ getCurrency(source.order.basketItems[0]) }}</p>
                            </div>
                        </div>
                        <div class="flex flex-row-reverse items-center justify-between">
                            <div class="flex items-center justify-start">
                                <p class="text-IranSans-Light text-xs" style="color: #5C5C5C;">{{
                                getLastStatusDate(source.order.createdAt) }}</p>
                            </div>
                            <div   @click="() => showBasketDetails(source)" class="flex flex-row-reverse items-center"
                                style="cursor: pointer;">
                                <p class="text-IranSans-Medium text-sm" style="color: #559847;">
                                    جزئیات سفارش
                                </p>
                                <arrow-left class="mr-2" />
                            </div>
                        </div>
                        <div class="flex flex-row-reverse items-center justify-between">
                            <div v-show="checkTaraStatus(source.sellerCustomerOrder)"
                                class="flex items-center justify-start">
                                <p class="text-IranSans-Light text-xs mt-2">

                                    وضعیت حساب تارا :
                                    <span v-if="checkTaraIsThereInCustomer(source.sellerCustomerOrder.customer)">
                                        <span class="text-green-500 font-bold"> دارد</span>
                                    </span>
                                    <span v-else>
                                        <span class="text-gray-500 font-bold">مشخص نیست</span>
                                    </span>
                                </p>
                            </div>
                        </div>
      </div>    
      <mp-basket-deails :hide="hide" ref="mpBasketDetail"></mp-basket-deails>
  </div>
</template>

<script>
import { ArrowLeft } from "@/components/svg"
import OrderStatus from "@/configs/OrderStatus.json"
import MpBasketDeails from "@/components/MpBasketDeails.vue"
import {  configStore } from '@/stores'
export default {
    components:{ArrowLeft,MpBasketDeails},
    data(){
        return{
            hide:false
        }
    },
    props: {

        source:{
            type:Object,
            default() {
                return {}; 
            }
        },
       
    },
    mounted(){
        this.initialize()
    },
    methods:{
        
        initialize() {
            this.hide = configStore.configService.service === "limoome_shop" ? true : false;
        },
        getCurrency(product) {
            return product.currency;
        },
        getCustomer(saleHistory) {
            // console.log("saleHistory",saleHistory);
            
            return saleHistory.customer.firstName + ' ' + saleHistory.customer.lastName

        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getTotalBasketPrice(sh) {
            const sum = sh.basketItems.reduce((accumulator, bi) => {
                return accumulator + bi.productPrice;
            }, 0);
            return sum;
        },
        getLastStatusDate(dateParam) {
            let date = new Date(dateParam.substring(0, 10)).toLocaleDateString('fa-IR');
            return date;
        },
        showBasketDetails(sh) {
            this.$refs.mpBasketDetail.saleHistory = sh;
            this.$refs.mpBasketDetail.$refs.mpActionSheet.open();
        },
        checkTaraStatus(sellerCustomerOrder) {
            return sellerCustomerOrder.meta && sellerCustomerOrder.meta.type && sellerCustomerOrder.meta.type === "ONLY_TARA";
        },
        checkTaraIsThereInCustomer(customer) {
            return customer.meta && customer.meta.taraStatus === "CREDITED"
        },
        getTitle(source){
            return OrderStatus[source.order.orderStatuses[0].status].title;
        },
        getColor(source){
            return OrderStatus[source.order.orderStatuses[0].status].color;
        }
    }
}
</script>

<style lang="less" scoped>
.order-card {
    position: relative;;    
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  
    &_status {
        position: absolute;               
        color: #fff;
        font-size: 0.65rem;
        border-radius: 4px;
        top: 0;
        right: 0;
        transform: translate(-20%, -50%);
    }
}
</style>
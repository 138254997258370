import jwt_decode from "jwt-decode";
import axios from 'axios'

export async function getSettlmentBySellerId() {
    let token = localStorage.getItem('token')
    let sellerId = jwt_decode(token).sub;
    let result = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/settlements?where%5BsellerId%5D[equals]=${sellerId}`,
    }).then((res) => {
        if (!res.data.err) {
            return res.data;
        }
        else {
            return {
                err: {
                    code: "404",
                    details: null,
                    message: "مشکل در دریافت تاریخچه پرداخت",
                }
            };
        }
    })
        .catch((e) => {
            return {
                err: {
                    code: "404",
                    details: null,
                    message: "لطفا اتصال خود به اینترنت را بررسی کنید",
                }
            };
        })
    return result;
}
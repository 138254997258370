import { render, staticRenderFns } from "./MpNotification.vue?vue&type=template&id=04b6c3cf&scoped=true"
import script from "./MpNotification.vue?vue&type=script&lang=js"
export * from "./MpNotification.vue?vue&type=script&lang=js"
import style0 from "./MpNotification.vue?vue&type=style&index=0&id=04b6c3cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b6c3cf",
  null
  
)

export default component.exports
<script>
export default {
  name: "LoginRegisterPageTemplate",
};
</script>

<template>
  <div class="login-register-page-template">
    <div class="top-nav">
      <slot name="top-nav"></slot>
    </div>
    <div class="main">
      <slot name="main"></slot>
    </div>
  </div>
</template>


<style lang="less" scoped>
.login-register-page-template {
  height: 100vh;
  width: 584px;
  max-width: 584px;

  @media screen and (max-width: 584px) {
    max-width: 100%
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  margin: 0 auto;

  .main {
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;
    padding: 2.25rem;
  }

}
</style>

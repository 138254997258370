<template>
  <div v-show="!hide" class="seller-share-table border rounded-xl overflow-hidden p-4">
    <div v-if="items.length > 0">
      <div class="flex flex-col gap-2">
        <div v-for="item in items" :key="item.label" class="flex justify-between">
          <p v-if="item.type === 'text'" class="label">{{ item.label }}</p>
          <p v-if="item.type === 'anouncment'" class="anouncment">{{ item.label }}</p>
          <p class="value" v-if="loading">...</p>
          <p class="value" v-else>
            <span v-if="item.amount >= 0 && item.type === 'text'" class="amount">{{ item.amount !== 0 ? (item.amount ?? 0).toLocaleString("fa") : 0 }}</span>
            <span v-if="item.currency" class="currency mr-2">{{ item.currency }}</span>
          </p>
        </div>
      </div>
      <hr class="my-4" />
      <div class="flex justify-between">
        <p class="label">جمع موارد</p>
        <p class="value" v-if="loading">...</p>
        <p class="value" v-else>
          <span class="amount">{{ (total ?? 0).toLocaleString("fa") }}</span>
          <span class="currency mr-2">{{ "تومان" }}</span>
        </p>
      </div>
    </div>
    <div v-else class="text-center">
      <p>در حال حاضر محصولی در سبد خرید شما قرار ندارد.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "seller-share-table",
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
    total: {
      type: Number,
      default: 0,
      required: false,
    },
  },
};
</script>

<style lang="less" scoped>
.seller-share-table {
  direction: rtl;
  text-align: right;
}
.anouncment {
  font-size: 11px;
  color: rgba(252, 199, 5, 1);
  font-family: "IranSans-Medium";
}
</style>
<template>
  <div :class="['lmn-top-nav', { 'lmn-top-nav--rtl text-IranSans-Medium': rtl }]">
    <div class="lmn-top-nav__back-btn" @click="onPrevRouteClick()">
      <img :class="[
        'lmn-top-nav__back-btn__icon',
        { 'lmn-top-nav__back-btn__icon--rtl': rtl },
      ]" src="@/assets/arrow-right.svg" alt="prev route" />
      <span>بازگشت</span>
    </div>
    <img class="lmn-top-nav__logo" :src="logo" alt=" logo" />
  </div>
</template>

<script>
import { configStore } from "@/stores/index";
export default {
  props: ['handlePrevRoute'],
  name: "LmnTopNav",
  props: {
    rtl: {
      type: Boolean,
    },
    handlePrevRoute: {
      type: Function,
    }
  },
  data(){
    return{
      logo:null
    }
  },
  created(){
    this.initialize()
  },
  methods: {
    onPrevRouteClick() {
      if (this.handlePrevRoute) {
        this.handlePrevRoute()
        return
      }
      this.$router.back()
    },
    async initialize(){
     this.logo = require(`@/assets/${configStore.configService.loginLogoURL}`)
    }
  },
};
</script>

<style lang="less" scoped>
.lmn-top-nav {
  &--rtl {
    direction: rtl !important;
  }

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: #fff;
  padding: 1rem;

  &__logo {
    height: 2rem;
  }

  &__back-btn {
    :hover {
      cursor: pointer;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #212121;

    &__icon {
      transform: rotateY(180deg);

      &--rtl {
        transform: rotateY(0deg);
      }
    }
  }
}
</style>

import { makeObservable, observable, action } from "mobx";
import { AuthServices } from "@/services";
import router from "@/router";
import jwt_decode from "jwt-decode";

const MOBILE_NUMBER_KEY_IN_STORAGE = 'mobileNumber'

class UserStore {
  countryID = "1";
  isRegistered = false;
  isLoggedIn = false;
  sellerInfo = { firstName: null, lastName: null, mobileNumber: null };
  conversionRate = { lastDay: {}, lastWeek: {}, lastMonth: {} };
  gender = null; // Male, Female, null
  roles = [];
  service = null;
  constructor() {
    makeObservable(this, {
      sellerInfo: observable,
      countryID: observable,
      isRegistered: observable,
      isLoggedIn: observable,
      gender: observable,
      conversionRate:observable,
      registerMobileNumberToSendOTP: action,
      loginWithMobileNumber: action,
      checkAuthWithMobileNumber: action,
      checkIsLoggedIn: action,
      sellerIdentify: action,
      logout: action,
      setSellerInfo: action,
      setIsRegistered: action,
      setIsLoggedIn: action,
      setService: action,
      setConversionRate:action,
    });
      this.initializeSellerInfo();
  }

    initializeSellerInfo() {
    const storedMobileNumber = localStorage.getItem(MOBILE_NUMBER_KEY_IN_STORAGE);
    if (storedMobileNumber) {
      this.sellerInfo.mobileNumber = storedMobileNumber;
    } else {
      this.sellerInfo.mobileNumber = null;
    }
  }
  setConversionRate(payload) {
    this.conversionRate = payload;
  }
  setSellerInfo(firstName, lastName, mobileNumber) {
    this.sellerInfo = { firstName, lastName, mobileNumber };
  }

  setIsRegistered(isRegistered) {
    this.isRegistered = isRegistered;
  }

  setIsLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
  }

  setService(service) {
    this.service = service.toUpperCase();
  }

  getConversionRate({  queryId = "SELLER_CONVERSION_RATE",mobile }) {
    return new Promise((resolve, reject) => {
      AuthServices.getSellerConversionRates({
        queryId,
        mobile,
      }).then(
        (res) => {

          this.setConversionRate(res)
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  registerMobileNumberToSendOTP({ mobileNumber, countryID = "1" }) {
    this.sellerInfo.mobileNumber = mobileNumber;
    return new Promise((resolve, reject) => {
      AuthServices.registerMobileNumberToSendOTP({
        mobileNumber,
        countryID,
      }).then(
        (res) => {
          setMobileInStorage(mobileNumber)
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  loginWithMobileNumber({ mobileNumber, countryID = "1", OTPCode }) {
    return new Promise((resolve, reject) => {
      AuthServices.loginWithMobileNumber({
        mobileNumber,
        countryID,
        OTPCode,
      }).then(
        (res) => {
          setMobileInStorage(mobileNumber)
          let decodedToken = jwt_decode(res.accessToken)
          this.roles = decodedToken.roles;
          let decodedService = decodedToken.aud
          localStorage.setItem("service", decodedService.toUpperCase())
          this.setService(decodedService);
          let isSeller = this.roles.some(r => r === `${AuthServices.getService()}.seller`)
          if (isSeller)
            localStorage.setItem('token', res.accessToken)

          else {
            reject({
              err: {
                code: 403,
                details: null,
                message: "عدم دسترسی",
              }
            });
          }
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  checkAuthWithMobileNumber({ mobileNumber, countryID = "1" }) {
    this.mobileNumber = mobileNumber;
    this.countryID = countryID;

    return new Promise((resolve, reject) => {
      AuthServices.checkAuthWithMobileNumber({
        mobileNumber: this.mobileNumber,
        countryID: this.countryID,
      }).then(
        (res) => {
          this.setIsRegistered(res.isRegistered);
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getService() {
    let isExpired = isTokenExpired()
    let serviceStore;
    if (isExpired) {
      serviceStore = window.service
    } else {
      serviceStore = getServiceFromToken() !== null ? getServiceFromToken() : localStorage.getItem("service")
    }
    this.setService(serviceStore);
    return serviceStore.toUpperCase()
  }

  checkIsLoggedIn() {
    let token = localStorage.getItem('token');
    if (token && token.length > 10) {
      this.setIsLoggedIn(true);
      let decodedToken = jwt_decode(token)
      this.roles = decodedToken.roles;
    } else {
      this.setIsLoggedIn(false);
    }
  }

  async sellerIdentify() {
    let result = await AuthServices.getSellerIdentify();
    const itemHasMobile = result.authMethods.find((method) => method.mobile);
    setMobileInStorage(itemHasMobile.mobile)
    this.setSellerInfo(result.firstName, result.lastName, itemHasMobile.mobile);
  }

  logout() {
    AuthServices.logout();
    clearMobileFromStorage()
    router.push('/enter')
  }
  loginWithToken(token){
    return new Promise((resolve, reject) => {  
          let decodedToken = jwt_decode(token)
          this.roles = decodedToken.roles;
          let decodedService = decodedToken.aud
          localStorage.setItem("service", decodedService.toUpperCase())
          this.setService(decodedService);
          localStorage.setItem('token', token)
          resolve(true);
        
       
      
    })

  }
}

// helpers 
function isTokenExpired() {
  try {
    const decoded = jwt.decode(localStorage.getItem('token'));
    const currentTime = Math.floor(Date.now() / 1000)
    return decoded.exp < currentTime
  } catch (e) {
    return true
  }
}

function getServiceFromToken() {
  try {
    const decoded = jwt.decode(localStorage.getItem('token'))
    const service = decoded.aud
    return service.toUpperCase()
  } catch (e) {
    return null
  }
}

function setMobileInStorage(mobileNumber = '') {
  window.localStorage.setItem(MOBILE_NUMBER_KEY_IN_STORAGE, mobileNumber)
}

function clearMobileFromStorage() {
  if (window.localStorage.getItem(MOBILE_NUMBER_KEY_IN_STORAGE)) {
    window.localStorage.removeItem(MOBILE_NUMBER_KEY_IN_STORAGE)
  }
}

export const userStore = new UserStore();
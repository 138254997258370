<template>
  <div class=" h-full mx-auto" style="max-width: 600px;">
    
    <div  class="w-full h-full  " dir="rtl" >
      <div class=" w-full h-32 mb-3 p-4 header">
        <form class="flex flex-col h-full w-full" @submit.prevent="LeadRequest">
          <div class="flex">
            <label for="supplier" class="ml-2 text-nowrap leading-10">انتخاب تامین کننده:</label>
          <select name="supplier" id="supplier" class="bg-gray-100 flex-1 text-sm border" v-model="selectedSupplier">
          <option class="text-sm " v-for="(item,index) in suppliers" :key="index"  :value="item.id">{{item.title}}</option>
        </select>
          </div>
        
        <button
         class="btn-form my-2 mt-auto  rounded-md px-3 py-2   text-sm   transition-all duration-10">درخواست لید</button>
        </form>
 
      </div>
        <div
        v-show="!loading"
          v-for="(item, index) in leadList"
          :key="index"
       
          class=" p-4 mb-6   card  rounded-lg hover:bg-gray-100 transition min-h-15 flex flex-row"
        >
        <LeadItem :item="item" @initAgain="()=>initialize()" :key="`litm-${item.id}`"/>
        </div>
  
    </div>
    <div v-show="loading" class="flex flex-col">
      <div
        v-for="(item, index) in 2"
        :key="`placeholder-${index}`"
        class="w-full h-44 bg-gray-300 animate-pulse rounded-lg my-4 mx-1"
      ></div>
    </div>
 
    <MpNotification ref="notificationRef" />
  </div>
</template>

<script>
import { leadStore } from "@/stores";
import MpNotification from "@/components/MpNotification.vue";
import { Observer } from "mobx-vue";
import LeadItem from "@/components/LeadItem.vue"
export default Observer({
  name:"Lead",
  components:{LeadItem,MpNotification },
  data() {
    return {
      notification: {
                visible: false,
                text: "",
                type: "",
                closable: true,
            },
      leadStore,
      loading: true,
      leadList: [],
      suppliers: [
    ],
    selectedSupplier:null,
    };
  },
  methods: {
    async runSequentialSteps() {
    try {
      await this.getToken();  
      await this.initialize();  
      await this.getSuppliers();  
    } catch (error) {
      console.error("An error ", error);
    }
  },
    async getToken(){
      try {
          const leadData = await leadStore.getLeadToken()
          if (leadData && leadData.jwt) {
            localStorage.setItem("leadToken",`Bearer ${leadData.jwt}`)
            leadStore.setLeadToken(`Bearer ${leadData.jwt}`)
          }
        } catch(ex) {}
    },

    async getSuppliers(){
      try {
        let result = await leadStore.getSuppliers();   
        if (result.suppliers.length > 0) {
          this.suppliers = result.suppliers
          this.selectedSupplier  =result.suppliers[0].id
        }   
      
      } catch (error) {
 
      }
    },
    async initialize() {
      try {
        let result = await leadStore.fetchLeadList();    
        this.leadList = result;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async LeadRequest(){  
      if (!this.selectedSupplier) {
        this.$refs.notificationRef.showNotification("   لطفا تامین کننده را انتخاب نمایید", "error");    
      }
        try {
        this.loading = true;
        let result = await leadStore.sendLeadRequest({ supplierId: this.selectedSupplier });     
        if (result) {
          this.loading = false;
          let resultLeadList = await leadStore.fetchLeadList(); 
          this.leadList = resultLeadList;
          if (this.leadList.length == 0) {
            this.$refs.notificationRef.showNotification("برای این تامین کننده در حال حاضر لید وجود ندارد.", "error");
          }
        }
        
      } catch (error) {
        setTimeout(() => {

          this.$refs.notificationRef.showNotification( error.message ? error.message:"با خطا مواجه شد", "error");
          this.loading = false;
        }, 1000);
       
             }  
    },


  },
  mounted() {
  
    this.runSequentialSteps();
  },
});
</script>

<style lang="less" scoped>
.header{
    background-color: #eef5ed;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: sticky;
    top: 0;
    z-index: 10;
}
#supplier {
    background-color: #f3f4f6;
    color: #333;
    padding: 5px;
    border-radius: 5px;
}

#supplier option:hover {
    background-color: green;
    color: white; 
}
#supplier:focus>option:checked {
  background: green;
  /* selected */
}
.btn-form{
  background-color: #4F7A28;
  color: white;
}
.card{
  border: 1px solid #EBEBEB;
}
.text-justify {
  text-align: justify;
}

.text-wrap {
  word-wrap: break-word;
  word-break: break-word;
}

.card {
  border: 1px solid #EBEBEB;
  width: 100%; /* Ensure card takes the full width */
  box-sizing: border-box;
}


</style>

import { makeObservable, observable, computed, action,reaction  } from "mobx";
class NavStore {
  _tabId = null
  constructor() {
    makeObservable(this, {
        _tabId: observable,
        getTabId : computed,
        changeTabId:action
    });
    const storedTabId = sessionStorage.getItem("tabId");
    if (storedTabId) {
      this._tabId = parseInt(storedTabId, 10);
    }else{
      this._tabId = 1
    }
    reaction(
        () => this._tabId,
        (tabId) => {
          if (tabId) {
            sessionStorage.setItem("tabId", tabId);
          }
        }
      );
  }
  
  get getTabId() {
    return this._tabId;
  }
  changeTabId(payload){
    this._tabId = payload
  }
}

export const navStore  = new NavStore();

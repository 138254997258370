<script>
import LmndEnterMobile from '@/components/login/LmndEnterMobile.vue'
import { configStore } from "@/stores/index";
export default {
  name: "EnterMobilePage",
  components: {
    LmndEnterMobile,
  },
  data(){
    return{
      logo:null
    }
  },
  created(){
    this.initialize()
  },
  methods:{
    async initialize(){
     this.logo = require(`@/assets/${configStore.configService.loginLogoURL}`)
    }
  }
};
</script>

<template>
  <div class="enter-mobile">
    <div class="enter-mobile__logo-container">
      <img  class="enter-mobile__logo" style="width: 50px;" :src="logo" alt="rabeeen logo">
    </div>
    <lmnd-enter-mobile class="lmnd-enter-mobile" />
  </div>
</template>

<style lang="less" scoped>
.enter-mobile {
  height: 100vh;
  width: 584px;
  max-width: 584px;

  @media screen and (max-width: 584px) {
    max-width: 100%;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  margin: 0 auto;

  &__logo-container {
    margin: 72px auto;
  }

  .lmnd-enter-mobile {
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;
    padding: 2.25rem;
  }

}
</style>

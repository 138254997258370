<template>
    <div class="flex flex-col justify-center items-center relative">
        <div v-if="orderStore.basketItems.length > 0" class="flex items-center justify-center text-white absolute" style="background-color: #559847; width: 18px; height: 18px; border-radius: 50%; transform: translate(12px, -5px);">
            <p class="text-IranSans-Medium text-xs">{{orderStore.basketItems.length}}</p>
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 14.25C8.5 16.17 10.08 17.75 12 17.75C13.92 17.75 15.5 16.17 15.5 14.25" :stroke="stroke" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.80994 2L5.18994 5.63" :stroke="stroke" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.1899 2L18.8099 5.63" :stroke="stroke" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 7.8501C2 6.0001 2.99 5.8501 4.22 5.8501H19.78C21.01 5.8501 22 6.0001 22 7.8501C22 10.0001 21.01 9.8501 19.78 9.8501H4.22C2.99 9.8501 2 10.0001 2 7.8501Z" :stroke="stroke" stroke-width="1.5"/>
            <path d="M3.5 10L4.91 18.64C5.23 20.58 6 22 8.86 22H14.89C18 22 18.46 20.64 18.82 18.76L20.5 10" :stroke="stroke" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    </div>
    
</template>

<script>
import {orderStore} from "@/stores/index"
import { observer } from "mobx-vue"
export default observer ({
    data() {
        return {
            orderStore
        }
    },
    props: {
        fill: {
            type: String,
            default: '#fff'
            // #559847
        },
        stroke: {
            type: String,
            default: '#696969'
        },
    }
})
</script>

<style>
</style>
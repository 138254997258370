<template>
  <div class="p-6">
    <AnnounceItem ref="announceActionSheet" :announce="announceSelectedItem" />
    <div v-if="loading" class="flex flex-col">
      <div
        v-for="(item, index) in 8"
        :key="`placeholder-${index}`"
        class="w-full h-16 bg-gray-300 animate-pulse rounded-lg my-4"
      ></div>
    </div>
    <div v-else>
      <ul>
        <li
          v-for="(item, index) in announceList"
          :key="index"
          @click="handleClick(item)"
          class="cursor-pointer p-4 mb-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition min-h-15 flex flex-row"
        >
          <div class="flex flex-col items-start">
            <!-- <div class="flex-none bg-gray-50 w-10 h-10 rounded-full cursor-pointer mr-auto flex justify-center items-center"><ThreeDots class="  " fill="#d2d2d2" /></div> -->
            <div class="mt-1">
              <p>{{ item.date_created ? getDate(item.date_created) : '' }}</p>
            </div>
          </div>

          <div class="flex-1 flex-col items-start">
            <p  v-html="item.title" class="text-right font-medium text-md">
            </p>
            <!-- <p class="text-sm text-right mt-2">این یک پاراگراف تستی است و برای تست استفاده می شود</p> -->
            <p class="text-sm text-right mt-2"></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { announceStore } from '@/stores';
import { Observer } from 'mobx-vue';

import AnnounceItem from '@/components/announcement/AnnounceItem.vue';
import ThreeDots from '@/components/svg/ThreeDots.vue';
export default Observer({
  components: { AnnounceItem, ThreeDots },
  methods: {
    getDate(dateParam) {
      let date = new Date(dateParam.substring(0, 10)).toLocaleDateString(
        'fa-IR',
      );
      return date;
    },
    async initialize() {
      try {
        this.loading = true;
        let result = await announceStore.fetchAnnounceList();
        this.announceList = result.data;
        this.loading = false;
      } catch (error) {
        console.log('error', error);
        this.loading = false;
      }
    },
    handleClick(item) {
      this.announceSelectedItem = item;
      this.$refs.announceActionSheet.$refs.mpActionSheet.open();
    },
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      announceStore,
      loading: false,
      announceList: [],
      announceSelectedItem: {},
    };
  },
});
</script>

<style scoped></style>

<template>
    <div class="card-wrap shadow-md" :style="{border:`1px solid ${backgroundColor}`, borderTop:`20px solid ${backgroundColor}`}">
      <!-- <div :class="['card-header', headerClass]" :style="{ background: backgroundColor }"> -->
        <!-- <i v-if="icon" :class="['fa','font-icon','m-4',icon]"></i>
        <img v-else-if="imageUrl" :src="imageUrl" alt="Card Image" class="card-image"> -->
      <!-- </div> -->
      <div class="card-content">
        <div class="card-title">{{ title }}</div>
        <div class="mt-auto">
          <p class="card-text">{{ subtitle }}</p>
     
     <button :class="['card-btn', headerClass]" :style="{ background: backgroundColor }" @click="clickBtn()">{{ (buttonText || "توضیحات") }}</button>
  
        </div>
        </div>
    </div>
  </template>
   
  <script>
  import { giftStore } from '@/stores';
import { observer } from 'mobx-vue';
  
  export default observer({

    name: 'GiftCard',
    data(){
      return{
        giftStore
      }
    },
    props: {
      title: String,
      subtitle: String,
      description: String,
      icon: String,
      imageUrl: String,
      color: String,
      buttonText: String,
      headerClass: String
    },
    computed: {
      backgroundColor() {
        if (!this.color || typeof this.color !== 'string' || this.color.length === 0) {
        return "#4F7A28"; 
        }else{
          return `${this.color}`;
        }
       
      }
    },
    methods: {
      
      clickBtn(){
       let obj ={
        title:this.title,
        description:this.description,
        subtitle:this.subtitle,
        icon:this.icon,
        imageUrl:this.imageUrl,
        color:this.color,
        buttonText:this.buttonText,
        headerClass:this.headerClass
       } 
       this.giftStore.setGiftItem(obj)
       this.giftStore.toggleActionGift(true)
       
      },
      adjustColorBrightness(color, amount) {
        if (!color || typeof color !== 'string' || color.length === 0) {
          color = "#4F7A28"; 
        }
        let usePound = false;
        if (color[0] == "#") {
          color = color.slice(1);
          usePound = true;
        }
  
        let num = parseInt(color, 16);
        let r = (num >> 16) + amount;
        let g = ((num >> 8) & 0x00FF) + amount;
        let b = (num & 0x0000FF) + amount;
  
        if (r > 255) r = 255;
        else if (r < 0) r = 0;
  
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
  
        if (b > 255) b = 255;
        else if (b < 0) b = 0;
  
        return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));
      }
    }
  })
  </script>
  
  <style scoped>
  .font-icon{
    font-size: 20px !important;
  }
  .card-wrap {
  direction: rtl;
    width: 220px;
    min-width: 220px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    color: var(--color-text);
    cursor: pointer;
    transition: all .2s ease-in-out;
    margin: 20px;
    padding: 10px;
  }
  .card-wrap:hover {
    transform: scale(1.05);
  }
  .card-header {
    height: 60px;
    width: 100%;
    background: red;
    border-radius:100% 0% 100% 0% / 0% 50% 50% 100%;
    display: flex;
    justify-content: center;
  }
  .card-header i {
    color: #fff;
    font-size: 72px;
  }
  .card-header img.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 150px;
    margin: 0 auto;
  }
  .card-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .card-text {
    text-align: center;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: 10px;

  }
  .card-btn {
    border: none;
    border-radius: 8px;
    padding: 3px 15px;
    color: #fff;

    margin-bottom: 5px;
    text-transform: uppercase;
  }
  </style>
  
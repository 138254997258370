<template>
  <div
    class="spinner"
    :style="{
      width: size + 'px',
      height: size + 'px',
      borderColor: color,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000",
    },
    size: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style>
.spinner {
  border: 3px solid;
  border-top-color: transparent !important;
  border-radius: 50%;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
    <div :class="['card', 'py-2', 'px-2', {'card_border': border}]" :style="[{'background-color': bgColor}]">
        <div class="flex items-center">
            <slot name="icon"></slot>       
            <div class="flex flex-col items-start justify-between">
                
                <slot name="title"></slot>
                <slot name="desc"></slot>
                <slot name="price"></slot> 
            </div>            
        </div> 
        <div class="flex flex-col items-end justify-center">
            <slot name="left"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {  
        bgColor: {
            type: String,
            default: '#fff'
        },
        height: {
            type: String,
            default: '56px'
        } ,
        border: {
            type: Boolean,
            default: false
        }   
    }
}
</script>

<style lang="less" scoped>
.card {
    direction: rtl;
    display: flex;  
    justify-content: space-between;
    width: 100%;    
    border-radius: 8px;
    cursor: pointer;

    &_border {
        border: 1px solid #F1F1F1;
    }
}
</style>
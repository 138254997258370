<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, GaugeChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
  name: "GaugeChart",
  components: {
    VChart,
  },
  props: {
    chartValue: {
      default: 0,
      type: Number,
    },
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        series: [
          {
            min: 0,
            max: 100,
            type: "gauge",
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [0.2, "#a83232"],
                  [0.4, "#eb9310"],
                  [0.6, "#ebd510"],
                  [0.8, "#099126"],
                  [1, "#095291"],
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              distance: -10,
              length: 5,
              lineStyle: {
                color: "#fff",
                width: 0,
              },
            },
            splitLine: {
              distance: -10,
              length: 2,
              lineStyle: {
                color: "#fff",
                width: 10,
              },
            },
            axisLabel: {
              color: "inherit",
              distance: 23,
              fontSize: 14,
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}% ",
              color: "inherit",
            },
            data: [
              {
                value: parseInt(parseInt(this.chartValue * 100).toFixed(0)), // Initialize with prop value
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    chartValue(newVal) {
      if (newVal !== null) {
        this.option.series[0].data[0].value = parseInt(parseInt(newVal * 100).toFixed(0));
      }
    },
  },
  mounted() {
    // If you need to perform additional initialization, do it here.
  },
};
</script>

<style scoped>
.chart {
  width: 250px;
  height: 250px;
}
</style>

<template>     
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <path :fill="fill" :stroke="stroke" d="M 17.847656 5.78125 C 17.554688 5.78125 17.320312 5.542969 17.320312 5.253906 L 17.320312 0.695312 C 17.320312 0.402344 17.554688 0.167969 17.847656 0.167969 C 18.136719 0.167969 18.375 0.402344 18.375 0.695312 L 18.375 5.253906 C 18.375 5.542969 18.136719 5.78125 17.847656 5.78125 Z M 17.847656 5.78125 "/>
    <path :fill="fill" :stroke="stroke" d="M 0.660156 18.972656 C 0.367188 18.972656 0.132812 18.738281 0.132812 18.445312 L 0.132812 0.695312 C 0.132812 0.402344 0.367188 0.167969 0.660156 0.167969 C 0.949219 0.167969 1.1875 0.402344 1.1875 0.695312 L 1.1875 18.445312 C 1.1875 18.738281 0.949219 18.972656 0.660156 18.972656 Z M 0.660156 18.972656 "/>
    <path :fill="fill" :stroke="stroke" d="M 20.582031 23.804688 C 20.289062 23.804688 20.054688 23.570312 20.054688 23.277344 C 20.054688 22.988281 20.289062 22.75 20.582031 22.75 C 21.617188 22.75 22.488281 22.035156 22.722656 21.070312 L 17.847656 21.070312 C 17.554688 21.070312 17.320312 20.835938 17.320312 20.542969 C 17.320312 20.253906 17.554688 20.019531 17.847656 20.019531 L 23.3125 20.019531 C 23.605469 20.019531 23.839844 20.253906 23.839844 20.542969 C 23.839844 22.34375 22.378906 23.804688 20.582031 23.804688 Z M 20.582031 23.804688 "/>
    <path :fill="fill" :stroke="stroke" d="M 17.847656 21.070312 C 17.554688 21.070312 17.320312 20.835938 17.320312 20.542969 L 17.320312 14.136719 C 17.320312 13.847656 17.554688 13.613281 17.847656 13.613281 C 18.136719 13.613281 18.375 13.847656 18.375 14.136719 L 18.375 20.542969 C 18.375 20.835938 18.136719 21.070312 17.847656 21.070312 Z M 17.847656 21.070312 "/>
    <path :fill="fill" :stroke="stroke" d="M 3.390625 23.804688 C 1.59375 23.804688 0.132812 22.34375 0.132812 20.542969 L 0.132812 18.445312 C 0.132812 18.15625 0.367188 17.917969 0.660156 17.917969 C 0.949219 17.917969 1.1875 18.15625 1.1875 18.445312 L 1.1875 20.542969 C 1.1875 21.761719 2.175781 22.75 3.390625 22.75 C 3.683594 22.75 3.917969 22.988281 3.917969 23.277344 C 3.917969 23.570312 3.683594 23.804688 3.390625 23.804688 Z M 3.390625 23.804688 "/>
    <path :fill="fill" :stroke="stroke" d="M 20.582031 23.804688 L 3.390625 23.804688 C 3.101562 23.804688 2.867188 23.570312 2.867188 23.277344 C 2.867188 22.988281 3.101562 22.75 3.390625 22.75 L 20.582031 22.75 C 20.871094 22.75 21.105469 22.988281 21.105469 23.277344 C 21.105469 23.570312 20.871094 23.804688 20.582031 23.804688 Z M 20.582031 23.804688 "/>
    <path :fill="fill" :stroke="stroke" d="M 3.390625 23.804688 C 3.101562 23.804688 2.867188 23.570312 2.867188 23.277344 C 2.867188 22.988281 3.101562 22.75 3.390625 22.75 C 4.609375 22.75 5.597656 21.761719 5.597656 20.542969 C 5.597656 20.253906 5.835938 20.019531 6.125 20.019531 L 17.847656 20.019531 C 18.136719 20.019531 18.375 20.253906 18.375 20.542969 C 18.375 20.835938 18.136719 21.070312 17.847656 21.070312 L 6.609375 21.070312 C 6.359375 22.621094 5.011719 23.804688 3.390625 23.804688 Z M 3.390625 23.804688 "/>
    <path :fill="fill" :stroke="stroke" d="M 11.128906 13.847656 L 4.3125 13.847656 C 4.023438 13.847656 3.789062 13.613281 3.789062 13.320312 C 3.789062 13.03125 4.023438 12.796875 4.3125 12.796875 L 11.128906 12.796875 C 11.417969 12.796875 11.65625 13.03125 11.65625 13.320312 C 11.65625 13.613281 11.417969 13.847656 11.128906 13.847656 Z M 11.128906 13.847656 "/>
    <path :fill="fill" :stroke="stroke" d="M 18.847656 10.773438 C 18.5625 10.773438 18.332031 10.550781 18.320312 10.269531 C 18.039062 10.257812 17.816406 10.027344 17.816406 9.746094 C 17.816406 9.453125 18.054688 9.21875 18.34375 9.21875 C 18.910156 9.21875 19.371094 9.679688 19.371094 10.246094 C 19.371094 10.535156 19.136719 10.773438 18.847656 10.773438 Z M 18.34375 10.269531 L 18.347656 10.269531 Z M 18.34375 10.269531 "/>
    <path :fill="fill" :stroke="stroke" d="M 17.847656 8.496094 C 17.554688 8.496094 17.320312 8.257812 17.320312 7.96875 L 17.320312 7.347656 C 17.320312 7.054688 17.554688 6.820312 17.847656 6.820312 C 18.136719 6.820312 18.375 7.054688 18.375 7.347656 L 18.375 7.96875 C 18.375 8.257812 18.136719 8.496094 17.847656 8.496094 Z M 17.847656 8.496094 "/>
    <path :fill="fill" :stroke="stroke" d="M 18.34375 10.269531 L 17.347656 10.269531 C 16.78125 10.269531 16.320312 9.808594 16.320312 9.242188 L 16.320312 8.46875 C 16.320312 7.902344 16.78125 7.441406 17.347656 7.441406 L 18.34375 7.441406 C 18.910156 7.441406 19.371094 7.902344 19.371094 8.46875 C 19.371094 8.761719 19.136719 8.996094 18.847656 8.996094 C 18.5625 8.996094 18.332031 8.773438 18.320312 8.496094 L 17.375 8.496094 L 17.375 9.21875 L 18.34375 9.21875 C 18.636719 9.21875 18.871094 9.453125 18.871094 9.746094 C 18.871094 10.035156 18.636719 10.269531 18.34375 10.269531 Z M 18.34375 10.269531 "/>
    <path :fill="fill" :stroke="stroke" d="M 17.847656 12.667969 C 17.554688 12.667969 17.320312 12.433594 17.320312 12.140625 L 17.320312 11.519531 C 17.320312 11.230469 17.554688 10.992188 17.847656 10.992188 C 18.136719 10.992188 18.375 11.230469 18.375 11.519531 L 18.375 12.140625 C 18.375 12.433594 18.136719 12.667969 17.847656 12.667969 Z M 17.847656 12.667969 "/>
    <path :fill="fill" :stroke="stroke" d="M 18.34375 12.046875 L 17.347656 12.046875 C 16.78125 12.046875 16.320312 11.585938 16.320312 11.019531 C 16.320312 10.726562 16.558594 10.492188 16.847656 10.492188 C 17.128906 10.492188 17.359375 10.714844 17.375 10.992188 L 18.320312 10.992188 L 18.320312 10.246094 C 18.320312 9.953125 18.554688 9.71875 18.847656 9.71875 C 19.136719 9.71875 19.375 9.953125 19.375 10.246094 L 19.375 11.019531 C 19.371094 11.585938 18.910156 12.046875 18.34375 12.046875 Z M 17.375 11.019531 Z M 17.375 11.019531 "/>
    <path :fill="fill" :stroke="stroke" d="M 14.429688 17.046875 L 4.3125 17.046875 C 4.023438 17.046875 3.789062 16.808594 3.789062 16.519531 C 3.789062 16.226562 4.023438 15.992188 4.3125 15.992188 L 14.429688 15.992188 C 14.71875 15.992188 14.957031 16.226562 14.957031 16.519531 C 14.957031 16.808594 14.71875 17.046875 14.429688 17.046875 Z M 14.429688 17.046875 "/>
    <path :fill="fill" :stroke="stroke" d="M 15.699219 3.042969 C 15.578125 3.042969 15.457031 3 15.359375 2.917969 L 13.550781 1.386719 L 11.742188 2.917969 C 11.546875 3.082031 11.257812 3.082031 11.0625 2.917969 L 9.253906 1.386719 L 7.445312 2.917969 C 7.25 3.082031 6.960938 3.082031 6.761719 2.917969 L 4.957031 1.386719 L 3.148438 2.917969 C 2.953125 3.082031 2.664062 3.082031 2.464844 2.917969 L 0.316406 1.097656 C 0.0976562 0.910156 0.0703125 0.574219 0.257812 0.355469 C 0.445312 0.132812 0.777344 0.105469 1 0.292969 L 2.808594 1.824219 L 4.613281 0.292969 C 4.8125 0.125 5.101562 0.125 5.296875 0.292969 L 7.105469 1.824219 L 8.914062 0.292969 C 9.109375 0.125 9.398438 0.125 9.59375 0.292969 L 11.402344 1.824219 L 13.210938 0.292969 C 13.40625 0.125 13.695312 0.125 13.890625 0.292969 L 15.699219 1.824219 L 17.507812 0.292969 C 17.726562 0.105469 18.058594 0.132812 18.25 0.355469 C 18.4375 0.574219 18.410156 0.910156 18.1875 1.097656 L 16.039062 2.917969 C 15.941406 3 15.820312 3.042969 15.699219 3.042969 Z M 15.699219 3.042969 "/>
    <path :fill="fill" :stroke="stroke" d="M 17.847656 14.664062 C 15.105469 14.664062 12.878906 12.4375 12.878906 9.695312 C 12.878906 6.957031 15.105469 4.726562 17.847656 4.726562 C 20.585938 4.726562 22.816406 6.957031 22.816406 9.695312 C 22.816406 12.4375 20.585938 14.664062 17.847656 14.664062 Z M 17.847656 5.78125 C 15.6875 5.78125 13.929688 7.535156 13.929688 9.695312 C 13.929688 11.855469 15.6875 13.613281 17.847656 13.613281 C 20.003906 13.613281 21.761719 11.855469 21.761719 9.695312 C 21.761719 7.535156 20.003906 5.78125 17.847656 5.78125 Z M 17.847656 5.78125 "/>
    <path :fill="fill" :stroke="stroke" d="M 9.269531 10.652344 L 4.3125 10.652344 C 4.023438 10.652344 3.789062 10.417969 3.789062 10.125 C 3.789062 9.835938 4.023438 9.597656 4.3125 9.597656 L 9.269531 9.597656 C 9.5625 9.597656 9.796875 9.835938 9.796875 10.125 C 9.796875 10.417969 9.5625 10.652344 9.269531 10.652344 Z M 9.269531 10.652344 "/>
    <path :fill="fill" :stroke="stroke" d="M 11.128906 7.457031 L 4.3125 7.457031 C 4.023438 7.457031 3.789062 7.222656 3.789062 6.929688 C 3.789062 6.640625 4.023438 6.402344 4.3125 6.402344 L 11.128906 6.402344 C 11.417969 6.402344 11.65625 6.640625 11.65625 6.929688 C 11.65625 7.222656 11.417969 7.457031 11.128906 7.457031 Z M 11.128906 7.457031 "/>
</svg>
</template>

<script>
export default {
    name: 'SettlmentSvg',
    props: {
        fill: {
            type: String,
            default: '#696969'
            // #559847
        },
        stroke: {
            type: String,
            default: 'none'
        }
    }
}
</script>
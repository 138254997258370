<template>
    <div class="parent-container">
        <mp-header class="hidden" @getSaleHistories="getSaleHistoriesHandler" />
        <div class="  ">
            <div class="header w-full">
                <div class="flex flex-row justify-between w-full p-4 ">
                <p class="text-IranSans-Bold text-base text-left cursor-pointer bg-white px-4 py-2" @click="showFilterForm()"> جستجو</p>

                <p class="text-IranSans-Bold text-base text-right px-4 py-2">لیست فروش </p>
            </div>
            </div>
            
            <div class="px-4" v-if="saleHistory">
                <div   v-if="this.saleHistory.length != 0">
                    <virtual-list 
                        ref="scrollContainer"
                        :data-key="'sellerCustomerOrderId'" 
                        :keeps="50"
                        :data-sources="saleHistory"
                        :data-component="itemComponent"
                        :estimate-size="10"
                        @tobottom="loadMore"
                        class="scroll-container">
                        <!-- Optional footer slot for showing a loader -->
                        <div slot="footer" class="loading-spinner" v-if="loading">
                       ...    در حال بار گذاری 
                        </div>
                      
                    </virtual-list>
                </div>
                <div v-else-if="this.saleHistory.length == 0">
                    <p class="text-IranSans-Bold text-base">سفارشی برای شما ثبت نشده است</p>
                </div>
           
                <HistoryFilterForm ref="filterActionSheet" @filter="handleFilter"></HistoryFilterForm>
               
            </div>
            <!-- bg-gray-300 rounded-md animate-pulse w-32 h-5 -->
            <div v-else>
                <div v-for="num in [0, 1, 3]" :key="num" class="order-card py-4 px-4">
                    <div class="flex flex-row-reverse items-center justify-between mb-4">
                        <div class="flex items-center justify-start">
                            <p class="bg-gray-300 rounded-md animate-pulse w-20 h-5"></p>
                            <div class="bg-gray-300 rounded-md animate-pulse h-5 w-5"></div>
                        </div>
                        <div class="flex flex-row-reverse items-center">
                            <p class="bg-gray-300 rounded-md animate-pulse w-14 h-5"></p>
                            <p class="bg-gray-300 rounded-md animate-pulse mr-2 w-9 h-5"></p>
                        </div>
                    </div>
                    <div class="flex flex-row-reverse items-center justify-between">
                        <div class="flex items-center justify-start">
                            <p class="bg-gray-300 rounded-md animate-pulse w-12 h-5"></p>
                        </div>
                        <div class="flex flex-row-reverse items-center" style="cursor: pointer;">
                            <p class="bg-gray-300 rounded-md animate-pulse w-24 h-5"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'
import { orderStore, userStore, configStore } from '@/stores'
import OrderStatus from "@/configs/OrderStatus.json"
import MpHeader from './MpHeader.vue'
import MpBasketDeails from "@/components/MpBasketDeails.vue"
import HistoryFilterForm from "@/components/HistoryFilterForm.vue"
import { ArrowLeft } from "@/components/svg"
import MpOrderCard from "@/components/MpOrderCard.vue"
import VirtualList from 'vue-virtual-scroll-list';
import { observer } from 'mobx-vue'
import { OrderSevices } from '@/services'
export default observer({
    name: 'History',
    data() {
        return {
            filter:{},
            hide: false,
            saleHistory: null,
            orderStore,
            userStore,
            skip: 50,
            take: 50,
            loading: false, // For loading state when fetching new data
            itemComponent: MpOrderCard,
            screenHeight: window.innerHeight, 

        }
    },
    components: {
        ProductCard, MpHeader, MpBasketDeails, ArrowLeft, MpOrderCard, HistoryFilterForm, VirtualList
    },
    mounted() {
        this.initialize()
        window.addEventListener('resize', this.updateScreenHeight); 
    },
    methods: {
        updateScreenHeight() {
            this.screenHeight = window.innerHeight; // Update the screen height on resize
        },
        async getSaleHistories() {
            this.loading = true; // Set loading state
            let paginate = { skip: this.skip, take: this.take };

            try {
                let results = await OrderSevices.getOrdersBySellerID(Object.keys(this.filter).length > 0  ? this.filter:null, paginate);
                // Append new results to the existing saleHistory
                this.saleHistory = [...this.saleHistory, ...results]; 
                this.skip += this.take; // Update skip for the next batch
            } catch (error) {
                console.error("Error fetching sale histories:", error);
            } finally {
                this.loading = false; // Reset loading state
            }
        },
        loadMore() {
         
            
            if (!this.loading) {
                this.getSaleHistories();
            }
        },

        initialize() {
            this.hide = configStore.configService.service === "limoome_shop" ? true : false;
        },
        getSaleHistoriesHandler(sh) {
            sh.sort(this.compareSaleHistory)
            this.saleHistory = sh;
        },
        compareSaleHistory(a, b) {
            if (a.order.createdAt < b.order.createdAt) {
                return 1;
            }
            if (a.order.createdAt > b.order.createdAt) {
                return -1;
            }
            return 0;
        },
        // getOrderStatus(saleHistory){           
        //     let lastStatus = saleHistory.orderStatuses[saleHistory.orderStatuses.length - 1];                  
        //     return lastStatus;
        // },
        // getPersianOrderStatus(lastStatus){     
        //     return OrderStatus[lastStatus.status];
        // },

        getTotalBasketPrice(sh) {
            const sum = sh.basketItems.reduce((accumulator, bi) => {
                return accumulator + bi.productPrice;
            }, 0);
            return sum;
        },
        getTotalBasketContribution(sh) {
            const sum = sh.basketItems.reduce((accumulator, bi) => {
                return accumulator + bi?.meta?.sellerShare;
            }, 0);
            return sum;
        },
        showBasketDetails(sh) {
            this.$refs.mpBasketDetail.saleHistory = sh;
            this.$refs.mpBasketDetail.$refs.mpActionSheet.open();
        },
        showFilterForm() {
            this.$refs.filterActionSheet.$refs.mpActionSheet.open();
        },
        async handleFilter(filter) {           
            this.filter = filter;
            let results = await OrderSevices.getOrdersBySellerID(filter,{skip:0,take:50});
            this.getSaleHistoriesHandler(results);
        }
    }


})
</script>
<style scoped>
.parent-container {
    height: 100vh; /* Full height of the viewport */
    /* overflow: hidden; */
}

.scroll-container {
    height: calc(100vh - 150px); /* Full height of the parent */
    overflow-y: scroll;
    /* padding-right: 17px; Increase/decrease this value for cross-browser compatibility */
    /* box-sizing: content-box; So the width will be 100% + 17px */
}
/* .scroll-container::-webkit-scrollbar {
  display: none;
} */
.header{
    background-color: #eef5ed;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.order-card {
    position: relative;;    
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  
    &_status {
        position: absolute;               
        color: #fff;
        font-size: 0.65rem;
        border-radius: 4px;
        top: 0;
        right: 0;
        transform: translate(-20%, -50%);
    }
}
</style>
<template>
    <section class="info-box overflow-hidden rounded-2xl px-4 py-3" :style="{'background-color': bgColor}">
      <div class="flex w-full gap-2 dir-rtl items-center">
        <div class="min-w-max flex flex-col items-center">
          <InfoCircle class="h-6" :fill="iconColor" />
        </div>
        <p class="text-sm text-justify" :style="{'color': textColor}">
          <slot></slot>
        </p>
      </div>
    </section>
  </template>
  
  <script>
  const VARIANTS = {
    default: {
      bgColor: "white",
      color: "black",
      iconColor: "black",
    },
    danger: {
      bgColor: "rgba(255, 0, 0, 0.1)",
      color: "rgb(255, 0, 0)",
      iconColor: "#FF0000",
    },
    warning: {
      bgColor: "rgba(247, 229, 148, 0.2)",
      color: "#000",
      iconColor: "rgba(252, 199, 5, 1)",
    },
    success: {
      bgColor: "rgba(160, 242, 165, 0.1)",
      color: "rgb(62, 181, 70)",
      iconColor: "#3eb546",
    }
  };
  import { InfoCircle } from "@/components/svg";
  export default {
    name: "info-box",
    props: {
      variant: {
        type: String,
        required: false,
        default: 'default'
      },
    },
    data() {
      return {
        variants: VARIANTS
      }
    },
    components: {
      InfoCircle,
    },
    computed: {
        iconColor() {
          return this.variants[this.variant].iconColor
        },
        bgColor() {
          return this.variants[this.variant].bgColor
        },
        textColor() {
          return this.variants[this.variant].color
        },
    }
  };
  </script>
  
  <style lang="less" scoped>
  .info-box {
    direction: rtl !important;
  }
  </style>
  
<template>
    <div class="h-screen flex flex-col items-center">
        <div class="flex flex-grow"></div>
        <img class="mx-auto my-auto" src="@/assets/close-circle.svg" />
        <p class="text-IranSans-Bold text-base mt-7">{{message}}</p>
      <div class="w-36 mt-4">
        <MpButton @click="ClickBtn"  title=" پرداخت مجدد "> </MpButton>
      </div>
       
        <div class="flex flex-grow"></div>
        <slot></slot>
    </div>
</template>

<script>
import MpButton from "@/components/MpButton.vue";
export default {
    components:{
        MpButton
    },
    methods:{
        ClickBtn(){
            this.$router.push(`/payment/${this.$route.query.Sco}`)
        }
    },
    name: "FailedPayment",   
    props: {
        message: {
            type: String,
            default: 'پرداخت با موفقیت انجام نشد'
        }
    } 
}
</script>
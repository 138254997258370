<template>
  <div class="basket">
    <!-- <div class="px-4">
      <p class="text-IranSans-Bold text-base text-right mb-4">سبد فروش</p>

      <div v-if="orderStore.sellerBasket.length > 0">
        <product-card
          @click.native="(e) => getProductDetail(e, product.id)"
          class="mb-4"
          :border="true"
          v-for="product in orderStore.sellerBasket"
          :key="product.id"
        >
          <template v-if="getIcon(product)" #icon>
            <img class="ml-2" :src="getIcon(product)" />
          </template>
          <template #title>
            <p class="text-IranSans-Medium text-xs mb-1">{{ product.title }}</p>
          </template>
          <template #desc>
            <p v-show="!hide" class="text-IranSans-Light text-xs mt-1" style="color: #696969">
              سهم من {{ numberWithCommas(product.sellerShare) }}
              {{ product.currency }}
            </p>
          </template>
          <template #left>
            <div id="left">
              <div class="flex items-center justify-center">
                <plus
                  @click.native="orderStore.addToBasketItems(product.id,product)"
                  :canAdd="
                    orderStore.hashMappedBasketItems
                      ? orderStore.hashMappedBasketItems[product.id]
                        ? orderStore.hashMappedBasketItems[product.id].count >= countPermissions(product)
                          ? false
                          : true
                        : true
                      : true
                  "
                />
            
                <p class="mx-1">{{ orderStore.hashMappedBasketItems ? (orderStore.hashMappedBasketItems[product.id] ? orderStore.hashMappedBasketItems[product.id].count : 0) : 0}}</p>
                <minus
                  @click.native="orderStore.removeFromBasketItems(product.id)"
                  :canRemove="
                    orderStore.hashMappedBasketItems
                      ? orderStore.hashMappedBasketItems[product.id]
                        ? orderStore.hashMappedBasketItems[product.id].count > 0
                          ? true
                          : false
                        : false
                      : false
                  "
                />
              </div>
              <p class="text-IranSans-Medium text-xs">
                {{ numberWithCommas(product.price) }} {{ product.currency }}
              </p>
            </div>
          </template>
        </product-card>
      </div>
      <div v-else>
        <p class="text-IranSans-Medium text-sm text-center">
          سبد فروش خالی است
        </p>
      </div>
    </div> -->
    <div
      v-if="orderStore.sellerBasket.length > 0"
      @click="selectProduct"
      class="fixed bottom-16 basket_btn py-6 px-4 w-full"
    >
      <mp-button title="ارسال لینک خرید" />
    </div>
    <!-- Customer Action Sheet -->
    <mp-action-sheet ref="lmnBuyer" @closed="resetData()">
      <SellerShareTable
        :hide="hide"
        :items="[
          {
            label: 'سهم شما از فروش',
            type:'text',
            amount: shareAmount.primary,
            currency: 'تومان',
          },
          {
            label: 'در صورت خرید اقساطی مشتری از اسنپ پی 15 درصد از سهم فروش شما کسر خواهد شد.',
            type:'anouncment',
            amount: null,
            currency: null,
          },
          {
            label: 'سهم اضافه شما',
            type:'text',
            amount: shareAmount.extra,
            currency: 'تومان',
          },
        ]"
        :total="shareAmount.total"
        :loading="createOrderAndGetOrdersNetworkState === 'PENDING'"
      />
      <div v-show="!hide" class="h-16 mt-4 flex flex-col justify-center">
        <InfoBox
          v-show="
            createOrderAndGetOrdersNetworkState === 'SUCCESS' &&
            shareAmount.extra > 0
          "
          variant="success"
        >
        این شماره مشمول سهم اضافه میشود.
        </InfoBox>
        <InfoBox
          v-show="
            createOrderAndGetOrdersNetworkState === 'SUCCESS' &&
            shareAmount.extra <= 0
          "
          variant="warning"
        >
        این شماره مشمول سهم اضافه نمیشود.
        </InfoBox>
      </div>
      <p class="text-IranSans-Bold text-base text-right mb-4 my-6">
        مشخصات خریدار
      </p>
      <mp-field
        @blur="setFirstName"
        @input="setFirstName"
        :value="customer.firstName"
        placeholder="نام"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.firstName.$error &&
          $v.customer.firstName.$dirty &&
          !$v.customer.firstName.required
        "
      >
        لطفا نام را وارد کنید
      </lmn-field-error>

      <mp-field
        @blur="setLastName"
        @input="setLastName"
        :value="customer.lastName"
        class="mt-5"
        placeholder="نام خانوادگی"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.lastName.$error &&
          $v.customer.lastName.$dirty &&
          !$v.customer.lastName.required
        "
        >لطفا نام‌خانوادگی را وارد کنید</lmn-field-error
      >

      <mp-field
        @blur="setMobile"
        @input="setMobile"
        :value="customer.mobile"
        placeholder="09"
        class="mt-5"
        type="tel"
        :rtl="false"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.mobile.$error &&
          $v.customer.mobile.$dirty &&
          !$v.customer.mobile.required
        "
        >لطفا شماره موبایل را وارد کنید</lmn-field-error
      >
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.mobile.$error &&
          $v.customer.mobile.$dirty &&
          !$v.customer.mobile.IRMobileNumber
        "
        >لطفا شماره موبایل را با فرمت 09 وارد کنید</lmn-field-error
      >
      <!-- {{allAllowedPaymentGateWays}} -->
      <div v-if="hasPermisionShowGateWay" class="checkbox-wrapper" >
  <div class="checkbox-item" v-for="(gateway, index) in allAllowedPaymentGateWays" :key="index">
    <label>
      <input type="checkbox" v-model="selectedGateways" :value="gateway" class="custom-checkbox">
      <span class="checkbox-custom"></span>
      {{ checkBoxValue(gateway) }}
    </label>
  </div>
</div>

      <mp-button
        :disable="sendSmsBtnDisable"
        @click="createOrderAndGetOrders"
        class="mt-5"
        :loading="createOrderAndGetOrdersNetworkState === 'PENDING'"
        title="ارسال لینک خرید"
      />
      <template
        v-if="
          createOrderAndGetOrdersNetworkState === 'SUCCESS' ||
          createOrderAndGetOrdersNetworkState === 'FAILED'
        "
      >
        <div
          :class="[
            'mb-5',
            'mt-3',
            `basket_${messages.sendSms.status}`,
            'text-xs',
          ]"
        >
          {{ messages.sendSms.desc }}
        </div>
        <p
          v-if="orderCustomerUrl"
          class="text-right text-xs text-IranSans-Light mb-2"
          style="direction: rtl"
        >
          در صورت عدم ارسال پیامک، می‌توانید لینک زیر را کپی و ارسال کنید.
        </p>
        <mp-field
          v-if="orderCustomerUrl"
          :value="orderCustomerUrl"
          :disable="true"
          :rtl="false"
          :canCopy="true"
        />
      </template>
    </mp-action-sheet>
    <mp-product-detail
      :hide="hide"
      @changeView="changeViewHandler(2)"
      ref="mpProductDetail"
    />
  </div>
</template>

<script>
import { orderStore, productStore,configStore } from "@/stores/index";
import { OrderSevices } from "@/services/index";
import ProductCard from "@/components/ProductCard.vue";
import MpButton from "@/components/MpButton.vue";
import { Plus, Minus } from "@/components/svg";
import { observer } from "mobx-vue";
import { reaction } from "mobx";
import { required, helpers } from "vuelidate/lib/validators";
import LmnFieldError from "@/components/login/LmnErrorMsg.vue";
import MpActionSheet from "@/components/MpActionSheet.vue";
import MpField from "@/components/MpField.vue";
import MpProductDetail from "@/components/MpProductDetail.vue";
import InfoBox from "@/components/InfoBox.vue";
import SellerShareTable from "@/components/SellerShareTable.vue";
import lodash from "lodash";
const IRMobileNumber = helpers.regex(
  "IRMobileNumber",
  /^(((09|9)[0-9]{9})|((۰۹|۹)[۰-۹]{9})|(([\u06F0][\u06F9]|[\u06F9])[\u06F0-\u06F9]{9})|(([\u0660][\u0669]|[\u0669])[\u0660-\u0669]{9}))$/
);

export default observer({
  components: {
    ProductCard,
    Plus,
    Minus,
    MpButton,
    LmnFieldError,
    MpActionSheet,
    MpField,
    MpProductDetail,
    InfoBox,
    SellerShareTable,
  },
  data() {
    return {
      gatelist:{
        "RABEEEN_TARA":"تارا",
        "ZEENAJI_SNAPP_PAY":"اسنپ پی",
        "ZEENAJI_PAYPING":"آنلاین",
        "C2C":"کارت به کارت",
      },
      extraSellerShare:0,
      extraSellerShare:0,
      selectedGateways: [],
      allAllowedPaymentGateWays:[],
      hide:false,
      hasPermisionShowGateWay:false,
      hasPermisionShowGateWay:false,
      orderStore,
      productStore,
      sendSmsBtnDisable: true,
      pendingSendSms: null,
      selectedProductId: null,
      createOrderAndGetOrdersNetworkState: "IDLE",
      fetchOrderNetworkState: "IDLE",
      shareAmount: {
        primary: 0,
        extra: 0,
        total: 0,
      },
      customer: {
        firstName: null,
        lastName: null,
        mobile: null,
      },
      buyerActionSheetStatus: false,
      messages: {
        sendSms: {
          status: null,
          desc: null,
        },
      },
      orderCustomerUrl: null,
    };
  },
  validations: {
    customer: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      mobile: {
        required,
        IRMobileNumber,
      },
    },
  },
  mounted() {
   this.initialize()
   this.resetShareAmount()
  },
  created() {
    this.basketItemsReaction = reaction(
      () => orderStore.basketItems.slice(), 
      (basketItems) => {
        this.handleBasketItemsChange(basketItems);
      }
    );
  },
  beforeDestroy() {
    this.basketItemsReaction(); 
  },
  methods: {
    handleBasketItemsChange(basketItems) {
      this.resetShareAmount()
    },
    resetShareAmount(){
      this.getExtraSellerShare()
      this.setShareAmount({
      primary: orderStore.basketItemsTotalSellerShare,
      extra: this.extraSellerShare,
      extra: this.extraSellerShare,
      total: orderStore.basketItemsTotalSellerShare,
    });
    },
    getExtraSellerShare(){
      let x = orderStore.basketItems.reduce((acc, curr) => {    
      acc += curr.options.extraShareForNewCustomer || 0;
      return acc;
      }, 0);
       this.extraSellerShare = x
    },
    checkBoxValue(gateway) {
      return this.gatelist[gateway] || '';
    },
    initialize(){
      configStore.showPaymentGateways === true ? this.hasPermisionShowGateWay = true :this.hasPermisionShowGateWay = false;
      configStore.showPaymentGateways === true ? this.hasPermisionShowGateWay = true :this.hasPermisionShowGateWay = false;
      this.getAllAllowedPaymentGateways()
      this.hide = configStore.configService.service === "limoome_shop" ? true : false;
    },
    getAllAllowedPaymentGateways(){
      const allProductsPayementGateways = orderStore.sellerBasket.reduce((acc, curr) => {
          acc.push(curr?.meta?.paymentGateways);
          return acc;
        }, []);
       this.allAllowedPaymentGateWays = lodash.intersection(...allProductsPayementGateways);
       this.selectedGateways = [...this.allAllowedPaymentGateWays];
    },
    setShareAmount(
      { primary, extra, total } = { primary: 0, extra: 0, total: 0 }
    ) {
      this.shareAmount = {
        primary,
        extra,
        total,
      };
    },
    mapSellerShareItems(items = []) {
      if (items.length > 0) {
        return items.reduce();
      } else {
        return [];
      }
    },
    getIcon(product) {
      if (product?.images?.icon)
        return require(`@/assets/products/${product.images?.icon}`);
      else return require(`@/assets/package.svg`);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectProduct() {
      this.getAllAllowedPaymentGateways()
      this.$refs.lmnBuyer.open();
    },
    async createOrderAndGetOrders() {
      this.createOrderAndGetOrdersNetworkState = "PENDING";
      try {
        const customerOrderId = await this.createOrder();
        const orders = await this.getOrder(customerOrderId);
        this.createOrderAndGetOrdersNetworkState = "SUCCESS";
      } catch (error) {
        this.createOrderAndGetOrdersNetworkState = "FAILED";
      }
    },
    async createOrder() {
     
      if (this.sendSmsBtnDisable) return;
      try {
        this.pendingSendSms = true;
        this.customer.mobile = this.toEnNumber(this.customer.mobile);
        const data = await orderStore.createOrder({customer:this.customer,selectedGateways:this.selectedGateways});
        if (data.err) {
          throw data;
        }

        const customerOrderId = data.id;
        this.pendingSendSms = false;
        if (data.smsStatus === 'sent') {
          if (data.creditStatus && data.creditStatus === "PENDING_CREDIT") {
            this.messages.sendSms.desc = "لینک نصب برنامه تارا با موفقیت ارسال شد. لینک خرید بعد از نصب تارا ارسال خواهد شد.";
          this.messages.sendSms.status = "success"; 
          }else{
            this.messages.sendSms.desc = "✓ پیامک با موفقیت ارسال شد";
          this.messages.sendSms.status = "success";
          }
        } else if (data.smsStatus === 'unsent') {
          this.messages.sendSms.desc = "مشکل در ارسال پیامک. لطفا دوباره تلاش کنید";
          this.messages.sendSms.status = "error";
          
        }
        this.orderCustomerUrl = `${configStore.configService.originAddress}/payment/${customerOrderId}`;
        return customerOrderId;
      } catch (error) {
        this.pendingSendSms = false;
        if (error.err) {
        }
        this.messages.sendSms.desc = "متاسفانه مشکلی پیش آمده لطفا مجددا تلاش نمایید!";
        this.messages.sendSms.status = "error";
        throw error;
      }
    },
    async getOrder(customerOrderId = null) {
      try {
        this.fetchOrderNetworkState = "PENDING";
        let data = await OrderSevices.getOrder(customerOrderId);
        if (data.err) {
          throw data;
        }
      
        
        const sellerShare = data.order.basketItems.reduce(
          (acc, curr) => (acc += curr.meta.sellerShare || 0),
          0
        );
        const extraSellerShare = data.order.basketItems.reduce(
          (acc, curr) => (acc += curr.meta.extraShareForNewCustomer || 0),
          0
        );
        
        

        this.setShareAmount({
          primary: sellerShare - extraSellerShare,
          extra: extraSellerShare,
          total: sellerShare,
        });
    
        this.fetchOrderNetworkState = "SUCCESS";
        return data;
      } catch (error) {
        this.fetchOrderNetworkState = "FAILED";
        throw error;
      }
    },
    setFirstName(e) {
      this.customer.firstName = e.target.value;
      this.$v.customer.firstName.$touch();
      this.setSmsStatus();
    },
    setLastName(e) {
      this.customer.lastName = e.target.value;
      this.$v.customer.lastName.$touch();
      this.setSmsStatus();
    },
    setMobile(e) {
      this.customer.mobile = e.target.value;
      this.$v.customer.mobile.$touch();
      this.setSmsStatus();
    },
    resetData() {
      this.customer.firstName = null;
      this.customer.lastName = null;
      this.customer.mobile = null;
      this.messages.sendSms.status = null;
      this.messages.sendSms.desc = null;
      this.$v.customer.firstName.$reset();
      this.$v.customer.lastName.$reset();
      this.$v.customer.mobile.$reset();
      this.createOrderAndGetOrdersNetworkState = "IDLE";
    },
    setSmsStatus() {
      if (
        this.$v.customer.firstName.$invalid ||
        this.$v.customer.lastName.$invalid ||
        this.$v.customer.mobile.$invalid
      ) {
        this.sendSmsBtnDisable = true;
      } else {
        this.sendSmsBtnDisable = false;
      }
    },
    toEnNumber(value) {
      const persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
      ];
      const arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g,
      ];
      if (typeof value === "string") {
        for (let i = 0; i < 10; i++) {
          value = value
            .replace(persianNumbers[i], String(i))
            .replace(arabicNumbers[i], String(i));
        }
      }
      return value;
    },
    getProductDetail(e, sellerProductId) {
      let id = this.getParentId(e.target);
      if (id == "plus" || id == "minus" || id == "change-count" || id == "left")
        return;
      this.$refs.mpProductDetail.selectedSellerProductId = sellerProductId;
      this.$refs.mpProductDetail.$refs.mpActionSheet.open();
    },
    getParentId(el) {
      if (el.id) {
        return el.id;
      }
      return this.getParentId(el.parentElement);
    },
    changeViewHandler() {
      this.$emit("changeView", 2);
    },

  },
  computed: {
    countPermissions() {
      return (product) => {
        if (product.maxCountPerOrder && product.maxCountPerOrder > 0) {
          return product.maxCountPerOrder;
          
        }else{
          return 0;
        }
      };
  },

},

});
</script>

<style lang="less" scoped>
.basket {
  // font-family: "IranSans-Medium" !important;

  &_btn {
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
    max-width: 600px;
  }

  &_error {
    color: rgba(255, 107, 107, 1);
    direction: rtl;
  }

  &_success {
    color: #519b44;
    direction: rtl;
  }
}
.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.checkbox-item {
  margin: 5px;
}
.checkbox-item label {
  font-size: 12px;
  margin-left: 8px;
}
.checkbox-wrapper label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox-wrapper input:checked + .checkbox-custom {
  background-color: #4caf50; /* Custom color for checked state */
  border-color: #4caf50;
}

.checkbox-custom::after {
  content: '';
  position: absolute;
  top: 30%;
  left: 50%;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  display: none;
}

.checkbox-wrapper input:checked + .checkbox-custom::after {
  display: block;
}

.checkbox-wrapper input:focus + .checkbox-custom {
  box-shadow: 0 0 3px 3px rgba(0, 123, 255, 0.25);
}
</style>

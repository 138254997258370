import { makeObservable, observable, computed } from "mobx";
import { SettlmentService } from "@/services";


class SettlementStore {
    settlmentList = []
    fetchedSettlmentList = false;
    constructor() {
        makeObservable(this, {
            settlmentList: observable,
            fetchedSettlmentList: observable
        });
    }

    async getSettlmentBySellerId() {
        let result = await SettlmentService.getSettlmentBySellerId();
        if (!result.err) {
            this.fetchedSettlmentList = true;
            this.settlmentList = result;
        }
        return result;
    }

}

export const settlementStore = new SettlementStore();

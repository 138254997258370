<template>
  <div style="max-width: 600px" class="mx-auto">
    <component
     v-if="componentsOptions[selected]"
      @changeView="changeViewHandler"
      class="pb-20"
      :is="componentsOptions[selected].name"
      v-bind="componentsOptions[selected].props"
    />
    <custom-navigation
      backgroundColor="#EEF5ED"
      style="max-width: 600px"
      @update="changeNavHandler"
      :options="navigatorOptions"
      v-model="selected"
    />
  </div>
</template>

<script>
import CustomNavigation from '@/components/CustomNavigation.vue';
import History from './History.vue';
import Product from './Product.vue';
import Basket from './Basket.vue';
import Gift from './Gift.vue';
import Performance from './Performance.vue';
import Announcement from './Announcement.vue';
import { configStore, navStore } from '@/stores';
import { Observer } from 'mobx-vue';
import Lead from './Lead.vue'
export default Observer({
  components: {
    History,
    Product,
    CustomNavigation,
    Basket,
    Gift,
    Announcement,
    Performance,
    Lead,
  },
  data() {
    return {
      serviceIsSalesNetWork: false,
      configStore,
      navStore,
      selected: navStore._tabId,
      componentsOptions: {
        7: {
          name: 'Lead',
          props: {},
        },
        6: {
          name: 'Announcement',
          props: {},
        },
        5: {
          name: 'Performance',
          props: {},
        },
        4: {
          name: 'Gift',
          props: {},
        },
        3: {
          name: 'History',
          props: {},
        },
        2: {
          name: 'Basket',
          props: {},
        },
        1: {
          name: 'Product',
          props: {},
        },
      },
      navigatorOptions: [
      {
          id: 7,
          icon: {
            name: 'Lead',
            props: {
              fill: '#559847',
            },
          },
          title: 'لیدها',
          enabled: false,
          selected:false,
        },
        {
          id: 6,
          icon: {
            name: 'Announcement',
            props: {
              fill: '#559847',
            },
          },
          title: 'اعلانات',
          enabled: false,
          selected:false,
        },
        {
          id: 5,
          icon: {
            name: 'SettlmentSvg',
            props: {
              fill: '#559847',
            },
          },
          title: 'عملکرد',
          enabled: false,
          selected:false,
        },
        {
          id: 4,
          icon: {
            name: 'Reward',
            props: {
              stroke: '#559847',
            },
          },
          title: 'جوایز',
          enabled: false,
          selected:false,
        },
        {
          id: 3,
          icon: {
            name: 'Clock',
            props: {
              stroke: '#559847',
            },
          },
          title: 'تاریخچه',
          enabled: true,
          selected:false,
        },
        // {
        //   id: 2,
        //   icon: {
        //     name: 'BasketCount',
        //     props: {
        //       fill: '#EEF5ED',
        //       stroke: '#559847',
        //     },
        //   },
        //   title: 'سبد فروش',
        //   enabled: true,
        //   selected:false,
        // },
        {
          id: 1,
          icon: {
            name: 'Products',
            props: {
              fill: '#EEF5ED',
              stroke: '#559847',
            },
          },
          title: 'محصولات',
          enabled: true,
          selected:false,
        },
      ],
    };
  },
  // computed: {
  //   localOptions() {
  //     return this.navigatorOptions.filter(item => item.enabled);
  //   }
  // },
  methods: {
    initialize() {
      this.serviceIsSalesNetWork = configStore.configService.service === 'sales_network';
      let checkShowPerformanceTab = configStore.showPerformanceTab;
      let checkShowPrize = configStore.showPrize;
      let checkShowNews = configStore.showNews;
      let showLead = configStore.showLead;
      if (this.serviceIsSalesNetWork) {
        if (showLead) {
        this.setNavigatorOptionEnabled(0, true);
      }else{
        this.setNavigatorOptionEnabled(0, false);
      }
      if (checkShowNews) {
        this.setNavigatorOptionEnabled(1, true);
      }else{
        this.setNavigatorOptionEnabled(1, false);
      }
      if (checkShowPerformanceTab) {
        this.setNavigatorOptionEnabled(2, true);

      }else{
        this.setNavigatorOptionEnabled(2, false);
      }
      if (checkShowPrize) {
        this.setNavigatorOptionEnabled(3, true);
      }else{
        this.setNavigatorOptionEnabled(3, false);
      }
      }
     
      // console.log("checkShowPerformanceTab",checkShowPerformanceTab);
      // console.log("navStore._tabId",navStore._tabId);
      // console.log("navStore._tabId typeof",typeof navStore._tabId);
      
      
      if ([4, 5, 6].includes(navStore._tabId)) {
        if (navStore._tabId === 5) {
          if (checkShowPerformanceTab) {
            this.selected = 5;
          }else{
            this.selected = 1
          }
         
        }
        if (navStore._tabId === 4) {
          if (checkShowPrize) {
            this.selected = 4;
          }else{
            this.selected = 1;
          }
          
        }
        if (navStore._tabId === 6) {
          if (checkShowNews) {
            this.selected = 6;
          }else{
            this.selected = 1;
          }
         
        }
        if(navStore._tabId === 7){
          if (checkShowNews) {
            this.selected = 7
          }else{
            this.selected = 1
          }
        }
      } else {
        this.selected = navStore._tabId;
      }
   
      
    },
    setNavigatorOptionEnabled(index, value) {
      this.navigatorOptions = [
        ...this.navigatorOptions.slice(0, index),
        { ...this.navigatorOptions[index], enabled: value },
        ...this.navigatorOptions.slice(index + 1),
      ];
      console.log(" this.navigatorOption", this.navigatorOption);
      
    },
    changeNavHandler(e) {
      this.selected = e;
      navStore.changeTabId(e);
    },
    changeViewHandler(e) {
      this.selected = e;
    },
  },
  mounted() {
    this.initialize();
  },
});
</script>

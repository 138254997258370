<template>
    <div v-if="$route.query.status.toLowerCase() == 'ok'">
        <successful-payment></successful-payment>
    </div>
    <div v-else>
        <failed-payment></failed-payment>
    </div>
</template>

<script>
import SuccessfulPayment from "./SuccessfulPayment.vue"
import FailedPayment from "./FailedPayment.vue"
export default {
    name: 'PaymentResult',
    components: {
        SuccessfulPayment, FailedPayment
    },
}
</script>

<style scoped>

</style>

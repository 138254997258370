var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({ref:"bottomSheet",class:[
    'bottom-sheet',
    {
      opened: _vm.opened,
      closed: _vm.opened === false,
      moving: _vm.moving,
    },
  ],style:({
    'pointer-events':
      _vm.backgroundClickable && _vm.clickToClose == false ? 'none' : 'all',
  })},_vm.handlers),[(_vm.overlay)?_c('div',{staticClass:"bottom-sheet__backdrop",style:({ background: _vm.overlayColor })}):_vm._e(),_c('div',{ref:"bottomSheetCard",class:[
      'bottom-sheet__card',
      { stripe: _vm.stripe, square: !_vm.rounded },
      _vm.effect,
    ],style:([
      { bottom: _vm.cardP + 'px', maxWidth: _vm.maxWidth, maxHeight: _vm.maxHeight },
      { height: _vm.isFullScreen ? '100%' : 'auto' },
      { 'pointer-events': 'all' },
    ])},[_c('div',{ref:"pan",staticClass:"bottom-sheet__pan"},[_c('div',{staticClass:"bottom-sheet__bar"})]),_c('div',{ref:"bottomSheetCardContent",staticClass:"bottom-sheet__content px-4 pb-40",style:({ height: _vm.contentH })},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }

export async function fetchAnnounceList() { 
  try {
    let result = await fetch(`${process.env.VUE_APP_BACKEND_BLOG_URL}/items/rabeeen_news?filter[status][_eq]=published`)
    if (!result.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
  }
  return result.json();
  } catch (error) {
    return error
  }
  
  }
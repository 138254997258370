<template>
  <div class="product">
    <mp-header />
    <!-- Product List -->
    <p ref="productTitle" class="text-IranSans-Bold text-base text-right px-4 mb-2">
      محصولات
    </p>
    <div v-if="productStore.sellerProducts && !hasErrorFetchProducts" class="px-4">
      <div v-if="productStore.sellerProducts.length != 0">
        <div>
          <mp-field
            icon="search.svg"
            :height="40"
            @input="setFilter"
            :value="productStore.filterValue"
            @click="scrollToTopFilter"
            placeholder="نام محصول را وارد کنید ..."
          />
          <mp-horizontal
            :tags="productStore.tags"
            @setSelect="setSelectTagHandler"
            class="mt-2"
          />

          <product-card
            @click.native="(e) => getProductDetail(e, product.id)"
            class="my-4"
            :border="true"
            v-for="product in productStore.filteredProducts"
            :key="product.id"
          >
            <template #icon>
              <img v-if="getIcon(product)" class="ml-2" :src="getIcon(product)" />
            </template>
            <template #title>
              <p class="text-IranSans-Medium text-xs mb-1">{{ product.title }}</p>
            </template>
            <template #desc>
              <p
                v-show="!hide"
                class="text-IranSans-Light text-xs mt-1"
                style="color: #696969"
              >
                سهم من {{ numberWithCommas(product.sellerShare) }} {{ product.currency }}
              </p>
            </template>
            <!-- <template #price>
                            <p class="text-IranSans-Medium text-sm">{{ numberWithCommas(product.price) }} {{product.currency}}</p>
                        </template> -->
            <template #left>
              <div v-if="product.productType && checkTaraCondition(product.productType)">
                <MpButton
                  @click="openTaraForm(product.id, product)"
                  title="انتخاب "
                  class="px-4"
                ></MpButton>
              </div>
              <div v-else id="left">
                <div id="change-count" class="flex items-center justify-center">
                  <ProductPlusCounter :id="product.id" :product="product" />
                  <ProductCount :id="product.id" />
                  <ProductReduceCounter :id="product.id" />
                </div>
                <p class="text-IranSans-Medium text-xs mt-1">
                  {{ numberWithCommas(product.price) }} {{ product.currency }}
                </p>
              </div>
            </template>
          </product-card>
        </div>
      </div>
      <div v-else-if="productStore.sellerProducts.length == 0">
        <p class="text-IranSans-Bold text-base mt-5">محصولی جهت نمایش وجود ندارد</p>
      </div>
    </div>

    <div class="px-4" v-else-if="!productStore.sellerProducts && !hasErrorFetchProducts">
      <product-card class="my-4" :border="true">
        <template #title>
          <div class="bg-gray-300 rounded-md animate-pulse w-32 h-5"></div>
        </template>
        <template #desc>
          <div class="bg-gray-300 rounded-md animate-pulse w-16 h-5 my-2"></div>
        </template>
        <template #price>
          <div class="bg-gray-300 rounded-md animate-pulse w-16 h-5"></div>
        </template>
        <template #left>
          <div class="bg-gray-300 rounded-md animate-pulse w-24 h-5"></div>
          <div class="bg-gray-300 rounded-md animate-pulse w-8 h-5 my-2"></div>
          <div class="bg-gray-300 rounded-md animate-pulse w-8 h-5"></div>
        </template>
      </product-card>
    </div>

    <div v-else-if="hasErrorFetchProducts"></div>
    <!-- <mp-product-detail @changeView="changeViewHandler" ref="mpProductDetail" />  -->
    <MpTaraForm ref="taraActionSheet" :isFullScreen="true" :maxHeight="'100%'" />
    <!-- <mp-product-detail @changeView="changeViewHandler" ref="mpProductDetail" />  -->
    <MpTaraForm ref="taraActionSheet" :isFullScreen="true" :maxHeight="'100%'" />
    <Basket />
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import { orderStore, userStore, productStore, configStore } from "@/stores";
import MpHeader from "./MpHeader.vue";
import { observer } from "mobx-vue";
import MpHorizontal from "@/components/MpHorizontal.vue";
import { Plus, Minus } from "@/components/svg";
import MpField from "@/components/MpField.vue";
import MpProductDetail from "@/components/MpProductDetail.vue";
import MpTaraForm from "@/components/MpTaraForm.vue";
import MpButton from "@/components/MpButton.vue";
import ProductCount from "@/components/ProductCount.vue";
import ProductPlusCounter from "@/components/ProductPlusCounter.vue";
import ProductReduceCounter from "@/components/ProductReduceCounter.vue";
import Basket from "./Basket.vue";
export default observer({
  name: "Product",
  components: {
    ProductCard,
    MpHeader,
    MpHorizontal,
    MpField,
    Plus,
    Minus,
    MpProductDetail,
    MpTaraForm,
    MpButton,
    ProductCount,
    ProductPlusCounter,
    ProductReduceCounter,
    Basket,
  },
  data() {
    return {
      hide: false,
      orderStore,
      productStore,
      userStore,
      configStore,
      hasErrorFetchProducts: false,
    };
  },
  methods: {
    addToBasket(id, product) {
      orderStore.addToBasketItems(id, product);
    },
    removeFromBasket(id) {
      orderStore.removeFromBasketItems(id);
    },
    initialize() {
      this.hide = configStore.configService.service === "limoome_shop" ? true : false;
    },
    checkTaraCondition(type) {
      if (typeof type === "string" && type.includes("RABEEEN_TARA")) {
        return true;
      } else {
        return false;
      }
    },
    openTaraForm(id, product) {
      this.orderStore.addToBasketTaraItems(id, product);
      this.$refs.taraActionSheet.$refs.mpActionSheetTaraForm.open();
    },
    async getSellerProducts() {
      let result = await productStore.getAllSellerProducts();
      if (result.err) this.hasErrorFetchProducts = true;
    },
    setFilter(e) {
      productStore.setFilterValue(e.target.value);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getIcon(product) {
      if (product?.images?.icon)
        return require(`@/assets/products/${product.images?.icon}`);
      else return require(`@/assets/package.svg`);
    },
    scrollToTopFilter() {
      let filterElement = this.$refs.productTitle;
      var top = filterElement.offsetTop;
      window.scrollTo(0, top);
    },
    setSelectTagHandler(values) {
      this.productStore.filterTags = values;
    },
    getProductDetail(e, sellerProductId) {
      let id = this.getParentId(e.target);
      if (id == "plus" || id == "minus" || id == "change-count" || id == "left") return;
      this.$refs.mpProductDetail.selectedSellerProductId = sellerProductId;
      this.$refs.mpProductDetail.$refs.mpActionSheet.open();
    },
    getParentId(el) {
      if (el.id) {
        return el.id;
      }
      return this.getParentId(el.parentElement);
    },
    changeViewHandler() {
      this.$emit("changeView", 2);
    },
  },
  created() {
    this.initialize();
    this.getSellerProducts();
  },
  computed: {
    // getProductCount(id) {
    //   console.log("hi", id);
    //   return 0;
    //   //   return (id) => {
    //   //     return this.orderStore.hashMappedBasketItems &&
    //   //       this.orderStore.hashMappedBasketItems[id]
    //   //       ? this.orderStore.hashMappedBasketItems[id].count
    //   //       : 0;
    //   //   };
    // },
    countPermissions() {
      return (product) => {
        if (product.maxCountPerOrder && product.maxCountPerOrder > 0) {
          return product.maxCountPerOrder;
        } else {
          return 0;
        }
      };
    },
  },
});
</script>

//
<style lang="less" scoped>
// .product {
//     font-family: 'IranSans-Medium' !important;
// }
//
</style>

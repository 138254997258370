var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('minus',{attrs:{"canRemove":_vm.orderStore.hashMappedBasketItems
      ? _vm.orderStore.hashMappedBasketItems[_vm.id]
        ? _vm.orderStore.hashMappedBasketItems[_vm.id].count > 0
          ? true
          : false
        : false
      : false},nativeOn:{"click":function($event){return _vm.orderStore.removeFromBasketItems(_vm.id)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
export async function getChallenges(param) { 
    let result = await axios({
      method: 'get',
      url: `${process.env.VUE_APP_BACKEND_API}/api/challenges?mobile=${param.mobile}`,
    })
    if (result.data) {
      if (result.data.err) {
        throw result.data.err;
      }
      return result.data;
    }
    throw {
  
      err: {
        code: result.status,
        details: null,
        message: result.statusText,
      },
    };
  }
<template>
  <div v-if="loading">
    <div class="w-full h-full flex flex-col">
      <div class="flex flex-row justify-center p-6 min-h-24">
        <div class="w-44 h-40 m-4 bg-gray-300 animate-pulse rounded-md"></div>
        <div class="w-44 h-40 m-4 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div class="flex flex-col p-1 py-4 mt-12">
        <div class="w-full h-16 my-4 bg-gray-300  animate-pulse rounded-md"></div>
        <div class="w-full h-16 my-4 bg-gray-300 animate-pulse rounded-md"></div>
        <div class="w-full h-16 my-4 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
    </div>

  </div>
    <div v-else>
      <GiftCardCarousel :cards="availableChallenges" />
      <GiftVerticalList :items="wonChallenges" />
      <GiftCardDetails ref="giftCardDetail" ></GiftCardDetails>
    </div>
  </template>
  
  <script>
  import GiftCardCarousel from '@/components/gift/GiftCardCarousel.vue';
  import GiftVerticalList from '@/components/gift/GiftVerticalList.vue';
  import GiftCardDetails from '@/components/gift/GiftCardDetails.vue';
  import { giftStore } from '@/stores';
  import { Observer } from 'mobx-vue';
  import { reaction } from 'mobx';
  export default Observer({
    methods:{
      openActionSheet(){
         this.$refs.giftCardDetail.$refs.mpActionSheet.open();
      },
      async initialize(){
        let mobile = `${localStorage.getItem("mobileNumber")}`;
        if (mobile && mobile.charAt(0) !== '0') {
          mobile = '0' + mobile;
         }
         try {
          this.loading = true
        let result = await giftStore.getChallenges({mobile:mobile})
        this.availableChallenges = result.availableChallenges
        this.wonChallenges = result.wonChallenges
        this.loading = false
         } catch (error) {
          console.log("error",error);
          
         }
       
      }
    },
    components: {
      GiftCardCarousel,GiftVerticalList,GiftCardDetails
    },
    mounted() {
      this.initialize()
    reaction(
      () => giftStore._actionGift,
      (actionGift) => {
        if (actionGift) {
          this.openActionSheet();
        }
      }
    );
  },
    data() {
      return {
        loading:false,
        giftStore,
        availableChallenges:[],
        wonChallenges:[],
      };
    }
  })
  </script>
  
  <style>

  </style>
  
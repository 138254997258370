<template>
  <mp-action-sheet ref="mpActionSheet" :isFullScreen="true" class="details pb-3">
    <div
      class="fixed right-3 flex flex-row-reverse items-center justify-between bg-white"
    >
      <img @click="close()" src="@/assets/close.svg" />
      <p class="text-IranSans-Bold text-base ml-11">جزئیات سفارش</p>
      <div></div>
    </div>
    <div
      class="flex w-full px-2 items-start justify-between pt-12"
      style="direction: rtl"
    >
      <div class="flex flex-col items-center justify-between mb-2">
        <p class="text-IranSans-Bold text-xs mb-2" style="color: #858585">
          مشخصات خریدار
        </p>
        <p class="text-IranSans-Medium text-xs">
          {{ getCustomerFullName() }} | {{ getCustomerMobile() }}
        </p>
      </div>
      <div class="flex flex-col justify-between items-center mb-2">
        <p class="text-IranSans-Bold text-xs mb-2" style="color: #858585">مبلغ سفارش</p>
        <p class="text-IranSans-Medium text-xs">
          {{ numberWithCommas(getTotalBasketPrice()) }}
        </p>
      </div>
      <div class="flex flex-col justify-between items-center">
        <p class="text-IranSans-Bold text-xs mb-2" style="color: #858585">تاریخ آخرین تغییر</p>
        <p class="text-IranSans-Medium text-xs">{{ getDate() }}</p>
      </div>
    </div>
    <div>
      <div class="flex flex-col justify-center">
        <textarea
          type="text"
          v-model="details"
          class="w-full h-40 my-2 p-1 border border-gray-100 text-IranSans-Medium"
        />
        <MpButton
          title="ثبت "
          @click="patchDetails()"
          class="text-IranSans-Medium"
        ></MpButton>
        <p v-show="successMessage" class="mt-2 p-1 text-green-500 text-IranSans-Medium">
          با موفقیت ثبت شد
        </p>
      </div>
    </div>
    <div class="details_dashed-seperator w-full my-4"></div>
    <p class="text-IranSans-Medium text-sm mb-4" style="color: #5c5c5c">لیست محصولات</p>

    <div v-for="basketItem in basketItems" :key="basketItem.id">
      <div
        v-if="basketItem.id != basketItems[0].id"
        class="details_seperator w-full"
      ></div>
      <div class="flex flex-row-reverse items-center justify-start">
        <div class="flex flex-row-reverse w-1/2 justify-between items-center my-4">
          <div class="flex flex-row-reverse items-center">
            <img class="ml-2" :src="getIcon(basketItem)" />
            <p class="text-IranSans-Medium text-xs" style="color: #5c5c5c">
              {{ basketItem?.meta?.title }}
            </p>
          </div>
        </div>
        <div v-show="!hide" class="flex flex-col items-end justify-center mr-auto">
          <p class="text-IranSans-Medium text-xs mb-1" style="color: #858585">سهم شما</p>
          <p class="text-IranSans-Medium text-xs" style="color: #333333; direction: rtl">
            {{ numberWithCommas(basketItem?.meta?.sellerShare) }}
            {{ basketItem.currency }}
          </p>
        </div>
      </div>
    </div>

    <div class="details_profit-btn fixed bottom-0 left-0 p-4 w-full">
      <mp-field :value="linkofPayment()" :disable="true" :rtl="false" :canCopy="true" />
      <div
        v-show="!hide"
        class="details_profit flex flex-row-reverse w-full mt-4 p-4 justify-between"
      >
        <p class="text-IranSans-Bold text-xs">  مجموع سهم شما</p>
        <p class="text-IranSans-Bold text-xs" style="direction: rtl">
          {{ numberWithCommas(getTotalBasketContribution()) }} تومان
        </p>
      </div>
    </div>
  </mp-action-sheet>
</template>

<script>
import MpActionSheet from "@/components/MpActionSheet.vue";
import ProductCard from "@/components/ProductCard.vue";
import OrderStatus from "@/configs/OrderStatus.json";
import MpButton from "@/components/MpButton.vue";
import { orderStore, productStore, configStore } from "@/stores/index";
import MpField from "@/components/MpField.vue";
import { observer } from "mobx-vue";
export default observer({
  components: { MpActionSheet, ProductCard, MpField, MpButton },
  data() {
    return {
      saleHistory: null,
      details: null,
      successMessage: false,
    };
  },
  props: {
    hide: {
      type: Boolean,
    },
  },
  methods: {
    async patchDetails() {
      const result = await orderStore.patchOrderDetails(
        this.saleHistory?.sellerCustomerOrderId,
        this.details
      );

      if (result.id) {
        this.successMessage = true;
        setTimeout(() => {
          this.successMessage = false;
        }, 1000);
      }
    },
    getContribution(product) {
      let contributionPercent = product?.meta.sellerShare;
      return contributionPercent;
    },
    getTotalBasketContribution() {
      const sum = this.saleHistory?.order?.basketItems.reduce((accumulator, bi) => {
        return accumulator + bi?.meta?.sellerShare;
      }, 0);
      return sum;
    },
    numberWithCommas(x) {
      return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDate() {
      let date = new Date(
        this.saleHistory?.order?.updatedAt.substring(0, 10)
      ).toLocaleDateString("fa-IR");
      return date;
    },
    getTotalBasketPrice() {
      const sum = this.saleHistory?.order?.basketItems?.reduce((accumulator, bi) => {
        return accumulator + bi.productPrice;
      }, 0);
      return sum;
    },
    getCustomerFullName() {
      return (
        this.saleHistory?.customer.firstName + " " + this.saleHistory?.customer.lastName
      );
    },
    getCustomerMobile() {
      return this.saleHistory?.customer.mobile;
    },
    getIcon(product) {
      if (product?.meta?.images?.icon)
        return require(`@/assets/products/${product?.meta?.images?.icon}`);
      else return require(`@/assets/package.svg`);
    },
    close() {
      this.$refs.mpActionSheet.close();
    },
    linkofPayment() {
      return `${configStore.configService.originAddress}/payment/${this.saleHistory?.sellerCustomerOrderId}`;
    },
  },
  created() {
    // Initialize details with saleHistory.sellerCustomerOrder.meta.details
    this.details = this.saleHistory?.sellerCustomerOrder?.meta?.details || null;
  },
  computed: {
    basketItems() {
      return this.saleHistory?.order?.basketItems;
    },
  },
  watch: {
    "saleHistory.sellerCustomerOrder.meta.details": {
      handler(newVal) {
        this.details = newVal;
      },
      deep: true,
    },
  },
});
</script>

<style lang="less" scoped>
.details {
  &_dashed-seperator {
    height: 2px;
    color: #d6d6d6;
    background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 25px 1px;
    background-repeat: repeat-x;
  }
  &_seperator {
    border-top: 1px solid #ebebeb;
  }

  &_profit-btn {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 16%);
    background-color: #fff;
  }

  &_profit {
    height: 48px;
    background-color: #eef5ed;
    border-radius: 8px;
  }
}
</style>

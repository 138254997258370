<template>
  <div class="h-screen flex flex-col items-center">
    <div class="flex flex-grow"></div>
    <img class="mx-auto my-auto" src="@/assets/tick-circle.svg" />
    <p class="text-IranSans-Bold text-base mt-7">{{ message }}</p>
    <p class="text-IranSans-Bold text-sm mt-7" dir="rtl">
      سفارش شما با کد پیگیری    {{ tracking_code }}    تا حداکثر ۲۴ ساعت آینده فعال   
      خواهد شد.
    </p>
    <div class="flex flex-grow"></div>
    <slot></slot>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "SuccessfulPayment",
  data() {
    return {
        orderCustomerId:'',
        tracking_code: window.sessionStorage.getItem("humanizedId")
    }
  },
 
  props: {
    message: {
      type: String,
      default: "پرداخت شما با موفقیت انجام شد",
    },
  },
};
</script>


<template>
  <img v-if="imageSource" :src="imageSource" :alt="`${imgName} picture`" />
</template>

<script>
export default {
  props: ['imgName', 'imgType'],
  computed: {
    imageSource () {
      return require(`@/assets/${this.imgName}.${this.imgType || 'svg'}`)
    }
  }
}
</script>

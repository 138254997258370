import { makeObservable, observable, computed, action,reaction  } from "mobx";
import { AnnounceService } from "@/services";
class AnnounceStore {
  _announceList = [];
  constructor() {
    makeObservable(this, {
        _announceList: observable,
        fetchAnnounceList:action,
    });

  }
  
  async fetchAnnounceList(){
    try {
        let result = await AnnounceService.fetchAnnounceList()
        return result;
      } catch (error) {
        throw error;
      }
    
  }
}
export const announceStore = new AnnounceStore();

<template>
  <div class="number-container">
    <span v-if="currentNumber !== undefined || currentNumber !== null || currentNumber !== '' || currentNumber !== NaN">{{ currentNumber.toLocaleString("fa") }}</span>
    <p v-if="currentNumber !== undefined || currentNumber !== null || currentNumber !== '' || currentNumber !== NaN"> {{unit}}</p>
  </div>
</template>

<script>
export default {
  name: "AnimateNumber",
  props: {
    targetNumber: {
      required: true,
    },
    unit:{
      required:true
    }
  },
  data() {
    return {
      currentNumber: 0,
      interval: null,
    };
  },
  watch: {
    targetNumber: {
      immediate: true,
      handler(newVal) {
        if (newVal !== null) {
          this.initializeAnimation(newVal);
        }
      },
    },
  },
  methods: {
    initializeAnimation(target) {
      const difference = target > 10 ? 5 : 3;
      this.currentNumber = Math.max(0, target - difference);
      this.startAnimation(target);
    },
    startAnimation(target) {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.currentNumber < target) {
          this.currentNumber++;
          if (this.currentNumber >= target) {
            this.currentNumber = target;
            clearInterval(this.interval);
            this.interval = null;
          }
        } else {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 200); // Adjust the interval time for speed
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style scoped>
.number-container {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.number-container p{
  font-size: 11px;
}
</style>

import axios from 'axios';
import jwt_decode from "jwt-decode";

export async function getAllSellerProducts() {
    let result = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/products`,
    }).then((res) => {
        if (!res.data.err) {
            return res.data;
        }
        else {
            return {
                err: {
                    code: "404",
                    details: null,
                    message: "مشکل در دریافت محصولات",
                }
            };
        }
    })
        .catch((e) => {
            return {
                err: {
                    code: "404",
                    details: null,
                    message: "لطفا اتصال خود به اینترنت را بررسی کنید",
                }
            };
        })
    return result;
}
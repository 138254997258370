<template>
  <div>
    <mp-action-sheet ref="mpActionSheet" :isFullScreen="true" class="details pb-3">
      <div>
        <!-- Header Section -->
        <div class="fixed right-3 flex flex-row-reverse items-center justify-between bg-white">
          <img @click="close()" src="@/assets/close.svg" />
          <p class="text-IranSans-Bold text-base mx-2"> تکمیل اطلاعات</p>
          <div></div>
        </div>

        <!-- Personal Information Section -->
        <div class="flex flex-col w-full px-2 items-start justify-between pt-12" style="direction: rtl;">
          <div class="w-full flex flex-col items-start ">
            <mp-field :value="personalInfo.firstName" @change="personalInfo.firstName = $event.target.value" id="firstName"
              class="ml-1 text-IranSans-Light" placeholder="نام" />
            <label v-if="$v.personalInfo.firstName.$error" class="label-field text-IranSans-Light" for="firstName">اجباری</label>
          </div>
          <div class="w-full flex flex-col items-start mt-5">
            <mp-field :value="personalInfo.lastName" @change="personalInfo.lastName = $event.target.value" id="lastName"
              class="ml-1 text-IranSans-Light" placeholder="نام خانوادگی" />
            <label v-if="$v.personalInfo.lastName.$error" class="label-field text-IranSans-Light" for="lastName">اجباری</label>
          </div>
          <div class="w-full flex flex-col items-start my-5">
            <mp-field :value="personalInfo.mobile" @change="personalInfo.mobile = $event.target.value" id="mobile"
              class="ml-1 text-IranSans-Light" placeholder="موبایل" />
            <label v-if="$v.personalInfo.mobile.$error" class="label-field text-IranSans-Light" for="mobile">اجباری</label>
          </div>

          <!-- Address Information Section -->
          <div v-if="isShipmentAddress" class="w-full">
            <div class="flex flex-row w-full justify-between">
              <div class="flex flex-col items-start ml-1 w-full">
                <mp-field :value="addressInfo.state" @change="addressInfo.state = $event.target.value" id="province"
                  class="w-full text-IranSans-Light" placeholder="استان" />
                <label v-if="$v.addressInfo.state.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
              </div>
              <div class="flex flex-col items-start mr-1 w-full">
                <mp-field @change="addressInfo.city = $event.target.value" :value="addressInfo.city"
                  class="w-full text-IranSans-Light" placeholder="شهر" />
                <label v-if="$v.addressInfo.city.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <textarea rows="5" @change="addressInfo.details = $event.target.value" :value="addressInfo.details"
                class="mt-5 text-IranSans-Light text-md w-full  border-gray-100 outline-none border p-2 rounded-md text-gray-600 placeholder-gray-600" placeholder="آدرس" ></textarea>
              <label v-if="$v.addressInfo.details.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
            </div>
            <div class="flex flex-col items-start w-full">
              <mp-field @input="limitPostalCodeLength" @change="addressInfo.postalCode = $event.target.value"
                :value="addressInfo.postalCode" class="mt-5 text-IranSans-Light" placeholder="کدپستی" type="number" />
              <span v-if="$v.addressInfo.postalCode.$error " class="label-field text-IranSans-Light">  {{ !$v.addressInfo.postalCode.minLength === true ? " کدپستی باید ۱۰ رقم باشد" :"اجباری" }}</span>
            </div>
          </div>

        </div>

        <!-- Submit Button -->
        <div class="details_profit-btn fixed bottom-auto  left-0 p-5 w-full">
      
          <MpButton @click="onclick" title="ثبت اطلاعات " class="px-4"></MpButton>
          <div v-show="errorNetwork" class="text-IranSans-Medium text-red-500 my-3">
            <p>
              <span class="mx-4 text-red-500 text-sm"> {{error.code}}</span>  
              خطایی  پیش آمده است
            </p>
          </div>
        </div>
      </div>
    </mp-action-sheet>
  </div>  
</template>

<script>
import MpActionSheet from "@/components/MpActionSheet.vue"
import MpField from '@/components/MpField.vue'
import MpButton from '@/components/MpButton.vue';
import { observer } from "mobx-vue"
import axios from "axios";
import { required, minLength, maxLength ,numeric,helpers } from 'vuelidate/lib/validators';

const IRMobileNumber = helpers.regex(
  "IRMobileNumber",
  /^(((09|9)[0-9]{9})|((۰۹|۹)[۰-۹]{9})|(([\u06F0][\u06F9]|[\u06F9])[\u06F0-\u06F9]{9})|(([\u0660][\u0669]|[\u0669])[\u0660-\u0669]{9}))$/
);

export default observer({
  components: { MpActionSheet, MpField, MpButton },
  props: {
    orderAddressGift:{
      type:Object,
      required:false
    },
    isShipmentAddress:{
      type:Boolean,
      required:true
    },
    orderIdForAddress:{
      type:String,
      required:false
    },
    isGift:{
      type:Boolean,
      required:false
    }
  },
  data() {
    return {
      loading: false,
      personalInfo: {
        firstName: null,
        lastName: null,
        mobile: null,
      },
      addressInfo: {
        state: null,
        city: null,
        details: null,
        postalCode: null,
      },
      isPatch:false,
      patchAddressID:null,
      errorNetwork:false,
      error:{code:'',message:''}
    }
  },
  validations: {
    personalInfo: {
      firstName: { required },
      lastName: { required },
      mobile: { IRMobileNumber },
    },
    addressInfo: {
      state: { required },
      city: { required },
      details: { required },
      postalCode: {
        required,
        minLength: minLength(10)
      }
    }
  },
  methods: {
    async onclick() {
      this.$v.$touch();
      if (this.$v.personalInfo.$invalid || (this.isShipmentAddress && this.$v.addressInfo.$invalid)) {
        return;
      }

      this.loading = true;

      try {
        let res;
        if (this.isPatch) {
          const url = `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${this.patchAddressID}/recipient`;
          let postData;
          if (this.isShipmentAddress) {
            postData = {
              isGift: this.isGift,
              recipient: {
                firstName: this.personalInfo.firstName,
                lastName: this.personalInfo.lastName,
                mobile: this.personalInfo.mobile,
                country: this.addressInfo.country,
                state: this.addressInfo.state,
                city: this.addressInfo.city,
                details: this.addressInfo.details,
                postalCode: this.addressInfo.postalCode
              }
            };
          } else {
            postData = {
              isGift: this.isGift,
              recipient: {
                firstName: this.personalInfo.firstName,
                lastName: this.personalInfo.lastName,
                mobile: this.personalInfo.mobile,
              }
            };
          }
          res = await axios.post(url, postData);
        } else {
          const url = `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${this.orderIdForAddress}/recipient`;
          let postData;
          if (this.isShipmentAddress) {
            postData = {
              isGift: this.isGift,
              recipient: {
                firstName: this.personalInfo.firstName,
                lastName: this.personalInfo.lastName,
                mobile: this.personalInfo.mobile,
                country: this.addressInfo.country,
                state: this.addressInfo.state,
                city: this.addressInfo.city,
                details: this.addressInfo.details,
                postalCode: this.addressInfo.postalCode
              }
            };
          } else {
            postData = {
              isGift: this.isGift,
              recipient: {
                firstName: this.personalInfo.firstName,
                lastName: this.personalInfo.lastName,
                mobile: this.personalInfo.mobile,
              }
            };
          }
          res = await axios.post(url, postData);
        }
        
        if (res.data.err) {
          console.error(res.data.err);
          this.loading = false;
          this.errorNetwork = true;
          this.error = res.data.err;
          setTimeout(() => {
            this.errorNetwork = false;
            this.error = {code:'',message:''};
          }, 3000);
        } else {
          this.loading = false;
          this.$refs.mpActionSheet.close();
          this.$emit('giftFormAddressSubmited', this.addressInfo);
        }
      } catch (error) {
             this.loading = false;
            this.errorNetwork = true
            this.error = {code:' ',message:'خطایی پیش آمده است لطفا مجدد تلاش نمایید'}
            setTimeout(() => {
              this.errorNetwork  = false;
              this.error = {code:'',message:''}
            }, 3000);
      }
    },
    close() {
      this.$refs.mpActionSheet.close();
    },
    limitPostalCodeLength(event) {
      const inputValue = event.target.value;
      const numericValue = inputValue.replace(/\D/g, '');
      this.addressInfo.postalCode = numericValue;
      if (numericValue.length > 10) {
        this.isValidPostalCode = false; 
        this.addressInfo.postalCode = numericValue.slice(0, 10);
      } else {
        this.isValidPostalCode = true; 
      }
    },
  },
  watch: {
    orderAddressGift: {
      handler: function(newVal) {
        if (newVal) {
          this.addressInfo.state = newVal.state || null;
          this.addressInfo.city = newVal.city || null;
          this.addressInfo.details = newVal.details || null;
          this.addressInfo.postalCode = newVal.postalCode || null;
          this.personalInfo.firstName = newVal.firstName || null;
          this.personalInfo.lastName = newVal.lastName || null;
          this.personalInfo.mobile = newVal.mobile || null;
          this.isPatch = true;
          this.patchAddressID = newVal.orderId;
        }
      },
      deep: true 
    },
    orderIdForAddress: {
      handler: function(newVal){
        if (newVal) {
          this.orderIdForAddress = newVal;
        }
      },
      deep:true
    }
  }
})
</script>
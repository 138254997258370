<template>
  <main>
    <vue-horizontal :button="false" class="horizontal text-IranSans-Bold" :displacement="0.5" :button-between="false">
      <div        
        class="item" v-for="(tag, index) in tags" :key="index">
        <button @click="setSelect" :class="['tag', {'selected': isSelected(tag.label)}]">
          {{ tag.label }}
        </button>
      </div>
    
    </vue-horizontal>
  </main>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
import { productStore } from '@/stores'
export default {
    components: {VueHorizontal},
    name: "MpHorizontal",
    data(){
        return {   
            selected: productStore.filterTags      
        }
    },
    props: {
        tags: {
            type: Array
        }
    },
    methods: {
        setSelect(e){ 
            let select = this.tags.find(tag => tag.label === e.target.innerText);   
            let isSelected = this.isSelected(e.target.innerText)            
            if(select && !isSelected)
                this.selected.push(select.label);
            else if(select && isSelected){
                const index = this.selected.indexOf(e.target.innerText);
                if (index > -1)
                    this.selected.splice(index, 1);               
            }                                    
            this.$emit('setSelect', this.selected)
        },
        isSelected(value){
            return this.selected.some(item => item === value)
        }
    }
}
</script>

<style scoped>
.tag {
  font-size: 12px;
  color: #777;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  line-height: 1;
  padding: 4px 24px;
  margin-right: 0.25rem;
}

.selected {
    background-color: rgb(85, 152, 71);
    color: #fff;
}

.btn-left, .btn-right {
  padding: 6px;
  height: 100%;
}

.btn-left {
  background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
}

.btn-right {
  background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
}

article {
  margin-top: 24px;
}
</style>
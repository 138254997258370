<template>
  <div class="flex flex-row text-right px-4">
    <span class="text-IranSans-Medium text-xs mx-2 ml-auto"
      >این سفارش را میخواهم هدیه بدهم</span
    >
    <input
      id="myCheckbox"
      type="checkbox"
      class="hidden"
      :checked="isGift"
      :disabled="loadingForCheckBox"
      @change="handleCheckboxChange"
    />
    <label
      for="myCheckbox"
      class="inline-block w-5 h-5 rounded-md border-2 cursor-pointer"
      :class="{
        'background-green-app': isGift && !loadingForCheckBox,
        'border-green-app bg-white': !loadingForCheckBox,
        'cursor-not-allowed': loadingForCheckBox,
        'bg-gray-300': loadingForCheckBox,
      }"
    >
      <svg
        v-if="isGift"
        class="w-4 h-4 mx-auto fill-current text-white"
        viewBox="0 0 20 20"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: "IsGiftCheckbox",
  props: {
    isGift: Boolean,
    loadingForCheckBox: Boolean,
  },
  methods: {
    handleCheckboxChange(event) {
      this.$emit("checkbox-change", event);
    },
  },
};
</script>
<style lang="less" scoped>
.border-green-app {
  border: 1px solid #559847;
}

.color-green-app {
  color: #559847;
}

.background-green-app {
  background-color: #559847;
}
</style>

<template>
  <div class="rg-btn-container-foreground" :style="cssVariables">
    <div  v-for="(button, index) in localOptions" class=" w-full "  :key="`ring-btn-${index}`">
    <div 
      v-if="button.enabled"
      class="rg-btn-container "
      @click="handleButtonClick(button)"
    >
    <div :class="{
        'rg-btn-item': true,
        'rg-btn-border': button.selected || false,
        'rg-btn-border-deselect': !button.selected
      }">
        <div
          :class="[
            'rg-btn-icon',
            { 'rg-btn-icon-active': button.selected  },
            { 'rg-btn-icon-deselect': !button.selected },
          ]"
        >
          <slot name="icon" :props="button">
            <component
              :is="button.icon.name"
              v-bind="button.selected ? button.icon.props : null"
            />
          </slot>
          <div v-if="button.badge > 0" class="rg-btn-badge" />
        </div>
        <div
          :class="['rg-btn-title', { 'rg-btn-title-active': button.selected }]"
        >
          <slot name="title" :props="button">{{ button.title }}</slot>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import MpImageLoader from '@/components/MpImageLoader.vue';
import {
  Products,
  Clock,
  BasketCount,
  SettlmentSvg,
  Reward,
  Announcement,
  Lead
} from '@/components/svg';

export default {
  components: {
    MpImageLoader,
    Products,
    Clock,
    BasketCount,
    SettlmentSvg,
    Reward,
    Announcement,
    Lead
  },
  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    iconColor: {
      type: String,
      default: '#669C35',
    },
    titleColor: {
      type: String,
      default: '#669C35',
    },
    borderColor: {
      type: String,
      default: '#4F7A28',
    },
    backgroundColor: {
      type: String,
      default: '#FFFFFF',
    },
    badgeColor: {
      type: String,
      default: '#FBC02D',
    },
    replaceRoute: {
      type: Boolean,
      default: false,
    },
  },
  data(){
   return{
    currSelected: null,
    localOptions: [],
   } 
  },
  computed: {
    cssVariables() {
      return {
        '--border-color': this.borderColor,
        '--icon-color': this.iconColor,
        '--background-color': this.backgroundColor,
        '--title-color': this.titleColor,
        '--badge-color': this.badgeColor,
      };
    },

  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        const targetIndex = this.localOptions.findIndex(option => option.id === newVal,);
        this.currSelected = targetIndex;
        if (targetIndex > -1) {
          this.handleButtonClick(this.localOptions[targetIndex]);
        }
      }
    },
    options: {
      handler(newOptions) {  
        // console.log("newOptions",newOptions);
          
        this.localOptions = newOptions.filter(item => item.enabled === true)
        this.initializeSelection();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {   
    this.initializeSelection();
  },
  methods: {
    initializeSelection() {  
        const initialIndex = this.localOptions.findIndex(item => item.id === this.value);
        this.currSelected = initialIndex;
        this.localOptions[initialIndex].selected = true
    },
    handleButtonClick(button) {
      const selectedIndex = this.localOptions.findIndex(
        item => item.id === button.id,
      );
      this.localOptions[this.currSelected].selected = false
      this.localOptions[selectedIndex].selected = true
      this.updateValue(button);
      this.$forceUpdate();
    },
    updateValue(button) {
      this.$emit('update', button.id);
      if (button.path && Object.keys(button.path).length) {
        if (this.replaceRoute) {
          this.$router.replace(button.path).catch(() => {});
        } else {
          this.$router.push(button.path).catch(() => {});
        }
      }
    },
  },
};
</script>
<style scoped>
.rg-btn-container-foreground {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  direction: ltr;
  bottom: 0;
  width: 100%;
  z-index: 999;
  height: 64px;
  background: var(--background-color);
  box-shadow: 0 0 5px 0 #eee;
  color: #0000008a;
  box-sizing: border-box;
  line-height: 1.5 !important;
  padding: 5px;
}

.rg-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-basis: 100%;
  transition: all 0.3s;
  box-sizing: border-box;
  /* padding-bottom: 10px; */
}

@media (min-width: 576px) {
  .rg-btn-container {
    cursor: pointer;
  }
}

.rg-btn-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 4px;
  border-bottom: 4px solid #EEF5ED;
  min-height: 67px;
}

.rg-btn-icon {
  position: relative;
}

.rg-btn-icon-active {
  color: var(--icon-color);
  animation: ring-button-selected 0.2s ease-out forwards;
}

.rg-btn-icon-deselect {
  animation: ring-button-deselect 0.1s ease-out forwards;
}

.rg-btn-border {
  border-bottom: 4px solid var(--border-color);
}

.rg-btn-border-deselect {
   border-bottom: 4px solid #EEF5ED;
}

.rg-btn-badge {
  position: absolute;
  top: -1px;
  right: -4px;
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: var(--badge-color);
}

.rg-btn-title {
  font-family: 'IranSans-Bold';
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 4px;
}

.rg-btn-title-active {
  font-family: 'IranSans-Bold';
  color: var(--title-color);
}

@keyframes ring-button-selected {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes ring-button-deselect {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<template>
  <div>
    <div v-if="!loading" class="flex flex-col w-full relative">
      <div>
        <div v-if="showData" class="flex flex-col w-full px-4 py-1">
          <div
            v-if="showData.result.receivedLeadsCount >= 10"
            class="w-full flex flex-col items-center shadow-md rounded-md"
          >
            <h2 class="my-2 text-2xl text-gray-600">نرخ تبدیل</h2>
            <GaugeChartConvertion
              :chartValue="calculateConvertion(showData.result.conversionRate) || 0"
              :chartLabel="showData.result.conversionRate"
            />
          </div>
          <!-- <div v-else class="w-full flex flex-col items-center shadow-md rounded-md">
            <p class="p-4">به دلیل لید کم نرخ تبدیل قابل محاسبه نیست</p>
          </div> -->
          <div
            v-if="showData"
            class="w-full flex flex-col items-center shadow-md rounded-md"
          >
            <h2 class="my-2 text-2xl text-gray-600">نرخ پیگیری</h2>
            <GaugeChartFollowUp :chartValue="showData.result.followUpRate ?showData.result.followUpRate :  0" />
          </div>
        </div>
        <div v-if="showData" class="flex flex-row w-full px-4 py-1">
          <div class="w-1/2 flex flex-col items-center rounded-md shadow-md">
            <h2 class="my-2 text-2xl text-gray-600">تعداد فروش</h2>
            <div
              class="w-32 h-32 flex justify-center items-center rounded-full border-4 border-green-800 circle-card"
            >
              <span class="text-lg">
                <AnimateNumber :targetNumber="showData.result.salesCount ?showData.result.salesCount :0" :unit="'عدد'" />
              </span>
            </div>
          </div>
          <!-- <div class="w-1/2 flex flex-col items-center rounded-md shadow-md">
            <h2 class="my-2 text-2xl text-gray-600">تعداد لید</h2>
            <div
              class="w-32 h-32 flex justify-center items-center rounded-full border-4 border-green-800 circle-card"
            >
              <span class="text-lg">
                <AnimateNumber :targetNumber="showData.result.receivedLeadsCount" />
              </span>
            </div>
          </div> -->
          <div class="w-1/2 flex flex-col items-center rounded-md shadow-md">
            <h2 class="my-2 text-2xl text-gray-600">درآمد</h2>
            <div
              class="w-32 h-32 flex justify-center items-center rounded-full border-4 border-green-800 circle-card"
            >
              <span class="text-lg">
                <AnimateNumber :targetNumber="showData.result.totalSellerShare ? showData.result.totalSellerShare:0" :unit="'تومان'" />
              </span>
            </div>
          </div>
        </div>
        <div v-if="showData" class="flex flex-row w-full px-4 py-1">
          <div class="w-1/2 flex flex-col items-center rounded-md shadow-md">
            <h2 class="my-2 text-2xl text-gray-600">تعداد لید</h2>
            <div
              class="w-32 h-32 flex justify-center items-center rounded-full border-4 border-green-800 circle-card"
            >
              <span class="text-lg">
                <AnimateNumber :targetNumber="showData.result.receivedLeadsCount ?showData.result.receivedLeadsCount:0" :unit="'عدد'" />
              </span>
            </div>
          </div>
          <div class="w-1/2 flex flex-col items-center my-4 rounded-md"></div>
        </div>
        <div
          v-if="!showData"
          class="flex flex-row justify-center items-center w-full px-4 py-6"
        >
          <div class="w-full flex flex-col items-center px-4 py-6 shadow-md rounded-md">
            <p>اطلاعاتی برای نمایش وجود ندارد</p>
          </div>
        </div>
        <div class="w-full h-36"></div>
        <div
          style="max-width: 600px"
          class="flex flex-row justify-center mt-5 fixed mx-auto bottom-16 w-full bg-white p-4"
        >
          <button
            :class="{
              'btn-date-rate-active': selectedButton === 30,
              'btn-date-rate': selectedButton !== 30,
            }"
            @click="selectButton(30)"
            class="p-4 m-1 text-white mx-1 rounded-md flex flex-col items-center justify-center"
          >
            <Day30
              :class="{
                'fill-white': selectedButton === 30,
                'fill-gray': selectedButton !== 30,
              }"
              class="w-8 h-8 my-2"
            />
            <span
              :class="{
                'txt-white': selectedButton === 30,
                'txt-gray': selectedButton !== 30,
              }"
              >30 روز
            </span>
          </button>
          <button
            :class="{
              'btn-date-rate-active': selectedButton === 7,
              'btn-date-rate': selectedButton !== 7,
            }"
            @click="selectButton(7)"
            class="p-4 m-1 text-white mx-1 rounded-md flex flex-col items-center justify-center"
          >
            <Day7
              :class="{
                'fill-white': selectedButton === 7,
                'fill-gray': selectedButton !== 7,
              }"
              class="w-8 h-8 my-2"
            />
            <span
              :class="{
                'txt-white': selectedButton === 7,
                'txt-gray': selectedButton !== 7,
              }"
              >7 روز
            </span>
          </button>

          <button
            :class="{
              'btn-date-rate-active': selectedButton === 1,
              'btn-date-rate': selectedButton !== 1,
            }"
            @click="selectButton(1)"
            class="p-4 m-1 text-white mx-1 rounded-md flex flex-col items-center justify-center"
          >
            <Day1
              :class="{
                'fill-white': selectedButton === 1,
                'fill-gray': selectedButton !== 1,
              }"
              class="w-8 h-8 my-2"
            />
            <span
              :class="{
                'txt-white': selectedButton === 1,
                'txt-gray': selectedButton !== 1,
              }"
            >
              1 روز
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col w-full h-full bg-gray-50 relative">
      <div class="flex flex-row w-full px-2 py-1">
        <div class="w-full flex flex-col items-center bg-white m-2">
          <h2 class="my-2 text-2xl text-gray-600 w-24 h-8 animate-pulse bg-gray-300"></h2>
          <div class="w-12 h-12 bg-gray-300 rounded-full animate-pulse my-1"></div>
        </div>
      </div>
      <div class="flex flex-row w-full px-2 py-1">
        <div class="w-full flex flex-col items-center bg-white m-2">
          <h2 class="my-2 text-2xl text-gray-600 w-24 h-8 animate-pulse bg-gray-300"></h2>
          <div class="w-24 h-24 bg-gray-300 rounded-full animate-pulse my-1"></div>
        </div>
      </div>
      <div class="flex flex-row w-full px-2 py-8 mt-8">
        <div class="w-1/2 flex flex-col items-center bg-white m-1 p-4">
          <h2 class="my-1 text-2xl text-gray-600 w-24 h-8 animate-pulse bg-gray-300"></h2>
          <div class="w-24 h-24 bg-gray-300 rounded-full animate-pulse my-1"></div>
        </div>
        <div class="w-1/2 flex flex-col items-center bg-white m-1 p-4">
          <h2 class="my-1 text-2xl text-gray-600 w-24 h-8 animate-pulse bg-gray-300"></h2>
          <div class="w-24 h-24 bg-gray-300 rounded-full animate-pulse my-1"></div>
        </div>
      </div>
      <!-- <div class="flex flex-row w-full px-2 py-1">
        <div class="w-1/2 flex flex-col items-center bg-white m-1">
          <h2 class="my-1 text-2xl text-gray-600 w-24 h-8 animate-pulse bg-gray-300"></h2>
          <div class="w-24 h-24 bg-gray-300 rounded-full animate-pulse my-1"></div>
        </div>
        <div class="w-1/2 flex flex-col items-center m-2"></div>
      </div> -->
      <div class="w-full h-32"></div>
      <div
        style="max-width: 600px"
        class="flex flex-row justify-center mt-5 fixed mx-auto bottom-16 w-full p-4"
      >
        <button
          class="p-4 m-1 mt-8 text-white mx-1 rounded-md flex flex-col items-center justify-center w-full h-24 bg-gray-300 animate-pulse"
        >
          <div class="w-12 h-12 my-2 bg-white"></div>
          <span class="w-4 h-2 bg-white"></span>
        </button>
        <button
          class="p-4 m-1 mt-8 text-white mx-1 rounded-md flex flex-col items-center justify-center w-full h-24 bg-gray-300 animate-pulse"
        >
          <div class="w-12 h-12 my-2 bg-white"></div>
          <span class="w-4 h-2 bg-white"></span>
        </button>
        <button
          class="p-4 m-1 mt-8 text-white mx-1 rounded-md flex flex-col items-center justify-center w-full h-24 bg-gray-300 animate-pulse"
        >
          <div class="w-12 h-12 my-2 bg-white"></div>
          <span class="w-4 h-2 bg-white"></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { observer } from "mobx-vue";
import { userStore } from "@/stores";
import GaugeChartFollowUp from "@/components/GaugeChartFollowUp.vue";
import GaugeChartConvertion from "@/components/GaugeChartConvertion.vue";
import AnimateNumber from "@/components/AnimateNumber.vue";
import { Day1, Day7, Day30 } from "@/components/svg";
export default observer({
  components: {
    GaugeChartFollowUp,
    GaugeChartConvertion,
    AnimateNumber,
    Day1,
    Day7,
    Day30,
  },
  data() {
    return {
      userStore,
      loading: true,
      selectedButton: null,
      allData: null,
      showData: {
        queryParams: {
          sellerMobile: null,
        },
        result: {
          totalSellerShare:0,
          salesCount: null,
          ordersCount: null,
          followUpRate: 0,
          linkSentRate: null,
          sellerMobile: null,
          linkSentCount: null,
          conversionRate: 0,
          linkNotSentRate: null,
          notAnsweredRate: null,
          linkNotSentCount: null,
          notAnsweredCount: null,
          packageSellingRate: null,
          receivedLeadsCount: 0,
          leadsWithStatusCount: null,
        },
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    calculateConvertion(value) {
      let result;
      if (value >= 0 && value < 0.03) {
        result = value * 6.6666;
      } else if (value >= 0.03 && value < 0.05) {
        result = value * 8;
      } else if (value >= 0.05 && value < 0.07) {
        result = value * 8.5714;
      } else if (value >= 0.07 && value < 0.3) {
        result = value * (8 / 3);
      } else if (value >= 0.3 && value <= 1) {
        let x = value * (8 / 3);
        if (x > 1) {
          result = 1;
        } else {
          result = x;
        }
      }
      return result;
    },
    setReset() {
      this.showData = {result:{
            receivedLeadsCount:0,
            totalSellerShare: 0,
            salesCount: 0,
            conversionRate:0,
            followUpRate:0
          }}
  },
    async initialize() {
      try {
        this.loading = true;
        let mobile = `${localStorage.getItem("mobileNumber")}`;
        if (mobile && mobile.charAt(0) !== '0') {
          mobile = '0' + mobile;
         }

        const res = await userStore.getConversionRate({
          queryId: "SELLER_CONVERSION_RATE",
          mobile: mobile,
        });
        if (res) {
          this.allData = res;
          this.selectedButton = 1;
          if (this.allData.lastDay.queryResults.length > 0) {
            this.showData = this.allData.lastDay.queryResults[0];
        }
          this.loading = false;
        }
      } catch (error) {}
    },
    selectButton(button) {
      if (button === 1) {
        if (this.allData.lastDay.queryResults.length > 0 ) {
            this.showData = this.allData.lastDay.queryResults[0];
        }   else{
          this.setReset()
        }  
      }
      if (button === 7) {
        if (this.allData.lastWeek.queryResults.length > 0) {
            this.showData = this.allData.lastWeek.queryResults[0];          
        } else{
          this.setReset()
        } 


      }
      if (button === 30) {
        if (this.allData.lastMonth.queryResults.length > 0) {
            this.showData = this.allData.lastMonth.queryResults[0];
        } else{
         this.setReset()
        } 
      }
      this.selectedButton = button;
    },
  },
});
</script>

<style lang="less" scoped>
.btn-date-rate {
  width: 90%;
  font-size: 14px;
  line-height: 12px;
  background-color: #e6e6e6;
  transition: 0.3s all;
  direction: rtl;
}
.btn-date-rate-active {
  direction: rtl;
  background-color: #559847;
  width: 100%;
  font-size: 14px;
  line-height: 12px;
  transition: 0.3s all;
}
.circle-card {
  border: 3px dotted #559847;
  margin: 10px 0px;
}
.fill-white {
  fill: #fff;
}
.fill-gray {
  fill: #9c9c9c;
}
.txt-white {
  color: #fff;
}
.txt-gray {
  color: #9c9c9c;
}
</style>

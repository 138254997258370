<template>
    <mp-action-sheet ref="mpActionSheet" :isFullScreen="true" class="details pb-3">
      <div>
        <div class="fixed right-3 flex flex-row items-center justify-between bg-white">
          <img @click="close()" src="@/assets/close.svg" />
          <p class="text-IranSans-Bold text-base mx-2"> تکمیل اطلاعات</p>
          <div></div>
        </div>
        <div class="flex flex-col w-full px-2 items-start justify-between pt-12" style="direction: rtl;">
          <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
          <div v-else class="flex flex-row w-full justify-around">
            <div class="flex flex-col items-start w-full">
            </div>
          </div>
  
          <div class="flex flex-col items-start w-full">
            <textarea rows="5" @change="form.sellerDescription = $event.target.value" :value="form.sellerDescription"
              class="mt-5 text-IranSans-Light text-md w-full  border-gray-100 outline-none border p-2 rounded-md text-gray-600 placeholder-gray-600" placeholder="توضیحات" ></textarea>
            <label v-if="$v.form.sellerDescription.$error" class="label-field text-IranSans-Light" for="province">اختیاری</label>
          </div>
          <div v-if="selectedStatuses?.value === 'CALL_RESCHEDULED'" class="flex flex-col items-start w-full">
            <input   
              :value="ShowDateFromDatePicker" 
              @input="ShowDateFromDatePicker = $event" 
              class="mt-5 text-IranSans-Medium px-4 py-2 border w-full rounded-sm custom-input"
              placeholder="  انتخاب تاریخ و ساعت"
            />
            <date-picker
              v-model="dateFromDatePicker"
              @change="changeDate($event)"
              clearable
              type="datetime" 
              simple
              format="YYYY-MM-DD"
              display-format="jYYYY-jMM-jDD HH:mm"
              custom-input=".custom-input"
              class="text-IranSans-Medium"
              color="#519b44"
            />
          </div>
        </div>
  
        <div class="details_profit-btn fixed bottom-auto  left-0 p-5 w-full">
          <MpButton @click="onclick" title="تایید  " class="px-4"></MpButton>
          <div v-show="errorNetwork" class="text-IranSans-Medium text-red-500 my-3">
            <p>   <span class="mx-4 text-red-500 text-sm"> {{error.code}}</span>  
              خطایی  پیش آمده است    </p>
          </div>
        </div>
      </div>
    </mp-action-sheet>
  </template>
  
  <script>
  import MpActionSheet from "@/components/MpActionSheet.vue"
  import MpButton from '@/components/MpButton.vue';
  import MpField from "@/components/MpField.vue";
  import { observer } from "mobx-vue"
  import axios from "axios";
  import { required, minLength, maxLength ,numeric } from 'vuelidate/lib/validators';
  import moment from 'moment-jalaali';
  
  export default observer({
    components: { MpActionSheet, MpButton, MpField },
    props: {
      selectedStatuses: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        dateFromDatePicker: null,
        ShowDateFromDatePicker: null, 
        loading: false,
        form: {
          sellerDescription: null, 
          callDate: null  
        },
        errorNetwork: false,
        error: { code:'', message:'' }
      }
    },
    validations: {
      form: {
        sellerDescription: { },
      }
    },
    methods: {
      changeDate(e) {
        this.form.callDate = moment(e).subtract(210, 'minutes').format();
        this.ShowDateFromDatePicker = moment(e._d).format('jYYYY/jMM/jDD HH:mm'); 
      },
      async onclick() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }else{
            this.$emit('submitForm', this.form);
        }
      
      },
      resetForm() {
      this.form.sellerDescription = null; 
      this.form.callDate = null;
      this.ShowDateFromDatePicker = null; 
      this.dateFromDatePicker = null; // Reset date picker value
    },
      close() {
        this.$refs.mpActionSheet.close();
      },
    }
  })
  </script>
  
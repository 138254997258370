import axios from 'axios';
import { leadStore } from '@/stores';
const getAuthToken = () => leadStore.getLeadToken();
var leadToken = localStorage.getItem("leadToken") || getAuthToken;
export async function getLeadToken (){
    
    try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/lead-manager-token`, {});
        leadToken =`Bearer ${response.data.jwt}` ;
        return response.data;
    } catch (error) {
        throw error
    }
}
export async function fetchLeadList() {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_LEAD_API}/api/lead/list`, {
            headers: {
                Authorization: `${leadToken}`
            }
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error.message || `Error: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}
export async function setStatus(param) {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_LEAD_API}/api/lead/setstatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${leadToken}`
            },
            body: JSON.stringify(param)
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error.message || `Error: ${response.status}`);
        }

        
        return response.ok;
    } catch (error) {
        throw error;
    }
}
export async function getSuppliers() {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_LEAD_API}/api/users/me`, {
            headers: {
                Authorization: `${leadToken}`
            }
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error.message || `Error: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}
export async function leadRequest(param) {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_LEAD_API}/api/leadrequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${leadToken}`
            },
            body: JSON.stringify(param)
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error.message || `Error: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}
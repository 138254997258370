var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('plus',{attrs:{"canAdd":_vm.orderStore.hashMappedBasketItems
      ? _vm.orderStore.hashMappedBasketItems[_vm.id]
        ? _vm.orderStore.hashMappedBasketItems[_vm.id].count >= _vm.countPermissions(_vm.product)
          ? false
          : true
        : true
      : true},nativeOn:{"click":function($event){return _vm.orderStore.addToBasketItems(_vm.id, _vm.product)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }
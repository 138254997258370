<template>
  <plus
    @click.native="orderStore.addToBasketItems(id, product)"
    :canAdd="
      orderStore.hashMappedBasketItems
        ? orderStore.hashMappedBasketItems[id]
          ? orderStore.hashMappedBasketItems[id].count >= countPermissions(product)
            ? false
            : true
          : true
        : true
    "
  />
</template>
<script>
import { orderStore } from "@/stores";
import { Plus } from "@/components/svg";
import { observer } from "mobx-vue";
export default observer({
  name: "ProductPlusCounter",
  props: ["id", "product"],
  data() {
    return {
      orderStore,
    };
  },
  components: {
    Plus,
  },
  computed: {
    countPermissions() {
      return (product) => {
        if (product.maxCountPerOrder && product.maxCountPerOrder > 0) {
          return product.maxCountPerOrder;
        } else {
          return 0;
        }
      };
    },
  },
});
</script>

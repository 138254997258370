<template>
    <div class="gift-item-list">
      <h2 class="gift-header-title text-base text-IranSans-Bold">جوایز اخذ شده</h2>
      <div v-for="(item,index) in items" :key="index" class="gift-item">
        <span :class="['status-badge', item.status]">{{  statusPersianName(item.status) }}</span>
        <div class="gift-item-header">
          <div class="gift-item-title">{{ item.title }}</div>
          <p class="gift-item-updated-at"> {{ formatDate(item.wonAt) }}</p>
        </div>
        <div class="gift-item-body">
          <p>{{ item.subtitle }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GiftVerticalList',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    methods: {
      formatDate(dateParam) {
            let date = new Date(dateParam.substring(0, 10)).toLocaleDateString('fa-IR');
            return date;
        },
      statusPersianName(status) {
      if (status === "success") {
        return "موفق";
      } else if (status === "pending") {
        return "در انتظار";
      } else if (status === "fail") {
        return "ناموفق";
      }else{
        return ""
      }
    }
    },
   
  }
  </script>
  
  <style scoped>
  .gift-header-title{
    text-align: center;
  
    margin-bottom: 20px;
    margin-top: 10px;

  }
  .gift-item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 5px;
    position: relative;
  }
  
  .gift-item {
    background: #fff;
    border-radius: 10px;
    padding: 5px 15px;
    border: 1px solid #EBEBEB;
    position: relative;

  }
  
  .gift-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    margin-top: 10px;
  }
  
  .gift-item-title {
    margin: 0;
    font-size: 14px;
    text-align: right;

  }
  
  .status-badge {
    padding: 3px 6px;
    border-radius: 4px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: 3px;
    left: 5px;
    width: 73px;
  }
  
  .status-badge.success {
    background-color: #669C35;
  }
  
  .status-badge.fail {
    background-color: rgb(230, 57, 70);
  }
  
  .status-badge.pending {
    background-color: #FBC02D;
  }
  
  .gift-item-body {
    margin-top: 10px;
    direction: rtl;
    
  }
  .gift-item-body p{
    font-size: 11px;
    text-align: right;
  }
  
  .gift-item-updated-at {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  </style>
<template>
  <div class="carousel-wrap" dir="rtl">
    <h2 class="carousel-title text-base text-IranSans-Bold ">جوایز روزانه</h2>
    <div v-if="cards.length > 0" 
         :class="{'carousel-content-single': cards.length === 1, 'carousel-content-multiple': cards.length > 1}">
      <GiftCard
        v-for="(card, index) in cards"
        :key="index"
        :title="card.title"
        :subtitle="card.subtitle"
        :description="card.description"
        :icon="card.icon"
        :imageUrl="card.imageUrl"
        :color="card.color"
        :buttonText="card.buttonText"
        :headerClass="card.headerClass"
      />
    </div>
    <div v-else class="flex justify-center items-center p-10">
      <p>در حال حاضر چالشی فعال نیست.</p>
    </div>
  </div>
</template>

<script>
import GiftCard from './GiftCard.vue';

export default {
  name: 'GiftCardCarousel',
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  components: {
    GiftCard
  }
}
</script>

<style scoped>
.carousel-wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fcfcfc;
  position: sticky;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 5px 0 #eee;
  top: 0px;
  z-index: 10;
}

.carousel-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.carousel-content-single {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.carousel-content-multiple {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  padding: 10px 0px;
  width: 100%;
  scroll-behavior: smooth;
}

.carousel-content-multiple::-webkit-scrollbar {
    height: 5px;
  }
  
  .carousel-content-multiple::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 10px;
  }
  
  .carousel-content-multiple::-webkit-scrollbar-track {
    background: transparent;
  }
  
</style>

<template>
    <div class="mp-product-detail">
        <p class="hidden">pp : {{JSON.stringify(orderStore.hashMappedBasketItems)}}</p>
        <mp-action-sheet 
            ref="mpActionSheet"
            :isFullScreen="true"
            :maxHeight="'100%'"
        >
            <div class="flex justify-end w-full">
                <div style="cursor: pointer;">                    
                    <img @click="$refs.mpActionSheet.close()" src="@/assets/close.svg" />          
                </div>
            </div>

            <div class="mt-4 w-full">
                <img class="rounded-xl" :src="banner" />
            </div>

            <product-card class="mt-6" :border="true">
                <template v-if="icon" #icon>
                    <img class="ml-2" :src="icon" />
                </template> 
                <template #title>
                    <p class="text-IranSans-Medium text-xs mb-1">{{ title }}</p>
                </template>
                <template #desc>
                    <p v-show="!hide" class="text-IranSans-Light text-xs mt-1" style="color: #696969;">سهم من {{sellerShare}} {{currency}}</p>
                </template>  
                <template #left>                                        
                    <div>                                
                        <div class="flex items-center justify-center">
                            <plus @click.native="orderStore.addToBasketItems(product?.id)" :canAdd="orderStore.hashMappedBasketItems ? (orderStore.hashMappedBasketItems[product?.id] ? (orderStore.hashMappedBasketItems[product?.id].count > countPermissions(product) ? false : true) : true) : true"/>
                            <p class="mx-1">{{ orderStore.hashMappedBasketItems ? (orderStore.hashMappedBasketItems[product?.id] ? orderStore.hashMappedBasketItems[product?.id].count : 0) : 0}}</p>
                            <minus @click.native="orderStore.removeFromBasketItems(product?.id)" :canRemove="orderStore.hashMappedBasketItems ? (orderStore.hashMappedBasketItems[product?.id] ? (orderStore.hashMappedBasketItems[product?.id].count > 0 ? true : false) : false) : false" />
                        </div>
                        <p class="text-IranSans-Medium text-xs">{{ price }} {{currency}}</p>                                                               
                    </div>
                </template>
            </product-card>

            <p class="text-xs text-right mt-4 leading-5" style="white-space: pre-line; direction: rtl; color: #333333;">
                {{description}}
            </p>

            <div class="fixed bottom-4 basket_btn py-6 px-4 w-full left-0">
                <mp-button @click="goToBasket" style="cursor: pointer;" title="مشاهده سبد فروش" />
            </div> 

        </mp-action-sheet>
    </div>
</template>

<script>
import MpActionSheet from '@/components/MpActionSheet.vue'
import {ArrowRight, Plus, Minus} from "@/components/svg"
import {productStore, orderStore} from "@/stores"
import ProductCard from "@/components/ProductCard.vue"
import MpButton from "@/components/MpButton.vue"
import { observer } from 'mobx-vue'

export default observer ({
    components: {MpActionSheet, ArrowRight, ProductCard, Plus, Minus, MpButton},
    props:['hide'],
    data() {
        return {
            selectedSellerProductId: null,
            orderStore
        }
    },
    methods: {        
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");   
        },
        goToBasket(){           
            this.$refs.mpActionSheet.close();
            this.$emit('changeView')
        }
    },
    computed: {
        product() {
            if(this.selectedSellerProductId)
                return productStore.sellerProducts.find(sp => sp.id == this.selectedSellerProductId)
            return undefined;
        },
        banner(){                               
            if(this.product?.images?.banner)
                return require(`@/assets/products/${this.product.images?.banner}`)

            else
                return require(`@/assets/package.svg`);
        },
        icon() {
            if(this.product?.images?.icon)
                return require(`@/assets/products/${this.product.images?.icon}`)
            else
                return require(`@/assets/package.svg`);
        },
        title() {
            if(this.product)
                return this.product.title;            
            return null;
        },
        sellerShare() {
            if(this.product)
                return this.numberWithCommas(this.product.sellerShare);            
            return null;
        },
        currency() {
            if(this.product)
                return this.product.currency;
            return null;
        },
        price() {
            if(this.product)
                return this.numberWithCommas(this.product.price);
            return null;
        },   
        description(){
            return this.product?.description
        },
        countPermissions() {
        return (product) => {
        if (product.maxCountPerOrder && product.maxCountPerOrder > 0) {
          return product.maxCountPerOrder;
          
        }else{
          return 0;
        }
      };
  },
    }
})
</script>

<style lang="less" scoped>
.mp-product-detail {
    &_back-btn {
        border: 1px solid #F1F1F1;
        border-radius: 8px;
    }
}

</style>
import axios from 'axios';

export function SetupAxiosInterceptor() {
    const baseURL = process.env.VUE_APP_BACKEND_LEAD_API;
    const RABEEEN_BACKEND = process.env.VUE_APP_BACKEND_API;

    axios.interceptors.request.use(
        config => {
            // console.log(config)
            const token = localStorage.getItem('token');


            // const leadToken = localStorage.getItem("leadToken");

            // // Check if the request URL includes the VUE_APP_BACKEND_LEAD_API
            // if (config.url.includes(baseURL)) {
            //     // If Authorization header is not set, use leadToken
            //     if (!config.headers['Authorization'] && leadToken) {
            //         config.headers['Authorization'] = `${leadToken}`;
            //     }
            // } else if (token) {
            //     // Use the standard token if not already set
            //     config.headers['Authorization'] = `Bearer ${token}`;
            // }
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            // if (response.config.url.includes(RABEEEN_BACKEND)) {
                if (response.data.err) {
                    let code = response.data.err.code;
                    if (code === 401 || code === 403) {
                        localStorage.removeItem('token');
                        window.location.href = '/';
                    }
                }
            // }
            return response;
        }
        // ,
        // error => {
        //     // if (error.response.status === 401 || error.response.status === 403) {
        //     //     localStorage.removeItem('token');
        //     //     window.location.href = '/';
        //     // }
        // }
    );
}

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { LmnErrorMsg as LmnFieldError, LmnOtpInput, LmnResendOTP } from "@/components/login";
import { ArrowLeft } from '@/components/svg'
import { userStore , leadStore } from '@/stores'
export default {
  name: "LmndMobileLogin",
  components: {
    LmnFieldError,
    LmnOtpInput,
    LmnResendOtp: LmnResendOTP,
    ArrowLeft
  },
  props: {
    rtl: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userStore,
      OTPCode: null,
      loading: false,
      serverError: null
    };
  },
  validations: {
    OTPCode: {
      required,
      minLength: minLength(6),
    },
  },

  methods: {
    async submitLoginWithMobileForm() {
      this.loading = true;
      this.serverError = null;

      try {
      
        await userStore.loginWithMobileNumber({mobileNumber: userStore.sellerInfo.mobileNumber, countryID: userStore.countryID, OTPCode: this.OTPCode})
        this.loading = false;
        this.serverError = null;
        this.$router.push('/')
      } catch (error) {
        this.loading = false;
        console.log('error: ', error);
        if (error.err.code == 404) {
          this.serverError = "صفحه مورد نظر یافت نشد";
        } else if (error.err.code == 400) {
          this.serverError = "لطفا اطلاعات خود را صحیح وارد نمایید";
        } else if (error.err.code == 401) {
          this.serverError = "اطلاعات کاربر ثبت نشده است";
        } else if (error.err.code == 403) {
          this.serverError = "اطلاعات شما به‌عنوان فروشنده ثبت نشده است";
        }else {
          this.serverError = "خطا دسترسی به سرور";
        }
      }

    },

    handleOtpInputOnChange(value) {
      this.OTPCode = value;

    },
    handleOtpInputOnComplete(value) {
      this.$v["OTPCode"].$touch();
    },
    resendOtpHandler() {
      userStore.registerMobileNumberToSendOTP({mobileNumber: userStore.sellerInfo.mobileNumber, countryID: userStore.countryID})

    }
  },
}
</script>

<template>
  <div class="lmnd-login-with-mobile login-register-template"
    :class="{ 'login-register-template--rtl text-IranSans-Medium': rtl }">
    <div class="login-register-template__main">
      <!-- login with mobile form -->
      <form id="login-with-mobile-form" class="login-register-form-template"
        @submit.prevent="submitLoginWithMobileForm">
        <p class="login-register-form-template__label">
          لطفا کد ارسال شده به شماره همراه {{ userStore.mobileNumber }} را وارد نمایید
        </p>
        <div class="login-register-form-template__fields-container">
          <div class="login-register-form-template__field-container">
            <lmn-otp-input ref="lmnOtpInput" @onChange="handleOtpInputOnChange"
              @onComplete="handleOtpInputOnComplete" />
            <div v-if="$v.$anyError" class="login-register-form-template__field__errors-container mt-4">
              <lmn-field-error class="login-register-form-template__field__error"
                v-if="$v.OTPCode.$error && !$v.OTPCode.required" :rtl="rtl">
                {{ "لطفا کد ارسال شده را وارد نمایید" }}
              </lmn-field-error>
              <lmn-field-error class="login-register-form-template__field__error"
                v-if="$v.OTPCode.$error && !$v.OTPCode.minLength" :rtl="rtl">
                {{ "لطفا کد ارسال شده را کامل وارد نمایید" }}
              </lmn-field-error>
            </div>
          </div>
        </div>
      </form>
      <section v-if="serverError" class="login-register-template__errors-container">
        <lmn-field-error class="login-register-template__error" :rtl="true">
          {{ serverError || "server error" }}
        </lmn-field-error>
      </section>
    </div>
    <footer class="login-register-template__footer">

      <!-- resend otp section-->
      <lmn-resend-otp @onResend="resendOtpHandler" method="mobile" :value="userStore.mobileNumber"
        style="margin-bottom: 1rem" />
      <button type="submit" form="login-with-mobile-form" :class="loading && 'lmn-btn--loading'"
        class="login-register-form-template__submit-btn lmn-btn lmn-btn--primary"
        :disabled="$v.$anyError || !$v.$dirty">
        <div v-if="loading" class="loading-spinner" />
        <span v-else>ادامه</span>
      </button>

    </footer>
  </div>
</template>



<style lang="less" scoped >
@import "../../styles/_login-register-form-template.less";
@import "../../styles/_login-register-template.less";
@import "../../styles/_buttons.less";

.lmnd-login-with-mobile {
  .to-login-with-reverse-otp {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__content {
      padding: 0;
      padding-top: 0.5rem;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      letter-spacing: -0.2px;
      color: #757575;
    }

    &__btn {
      width: 100%;
      height: 3rem;
      color: #3d7534;
      border: 1px #bdbdbd solid;
      border-radius: 0.5rem;

      &:hover {
        border-color: #3d7534;
        background-color: #3d7534;
        color: white;
      }
    }
  }

  .to-login-with-file-number {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0px;
    color: #519b44;
  }
}
</style>



<template>
  <transition name="notification-slide">
    <div v-if="notification.visible" class="notification px-6" dir="rtl" :class="notification.type" @click="hideNotification">
      <span class="text-IranSans-Bold">{{ notification.text }}</span>
      <button v-if="notification.closable" class="close-btn" @click="hideNotification">×</button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      notification: {
        text: '',
        type: 'default',
        closable: true,
        visible: false
      }
    };
  },
  methods: {
    showNotification(text, type = 'default', time = 25000, closable = true) {
      this.notification.text = text;
      this.notification.type = type;
      this.notification.closable = closable;
      this.notification.visible = true;
      setTimeout(this.hideNotification, time);
    },
    hideNotification() {
      this.notification.visible = false;
    }
  }
};
</script>

<style scoped>
.notification-slide-enter-active,
.notification-slide-leave-active {
  transition: opacity 0.5s ease;
}
.notification-slide-enter,
.notification-slide-leave-to {
  opacity: 0;
}
.notification {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 50px;
  border-radius: 5px;
  color: #fff;
  z-index: 9999;
  min-width: 250px;
}
.default {
  background-color: #333;
}
.warning {
  background-color: #ff9800;
}
.success {
  background-color: #4caf50;
}
.error {
  background-color: #bd2525;
}
.close-btn {
  position: absolute;
  top: 23px;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 1px 8px;
  border: 1px solid #fff;
  margin: 0px 10px;
  border-radius: 50%;
}
</style>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { userStore,configStore } from '@/stores'

export async function sendSMS(
  customer,
  orderCustomerId,
  seller,
  productId,
  role
) {
  await userStore.getService()
  let product = packagesFromHtml[role].find((product) => {
    return product.id == productId ? product : null;
  });
  let bodyData = {
    destinationMobile: customer.mobile,
    message:
      role === `${userStore.service}.seller`
        ? `${customer.firstName} عزیز،\n` +
        `شما از طرف ${seller.firstName} ${seller.lastName} دعوت شده‌اید که محصول "${product.description}" را بخرید. برای خرید محصول، روی لینک زیر بزنید.\n` +
        "https://panel.rabeeen.com/payment/" +
        orderCustomerId
        : `${customer.firstName} عزیز،\n` +
        `برای استفاده از طرح ویژه روانشناس لیمومی، روی لینک زیر کلیک کنید:\n` +
        "http://mind.limoome.com/buy-psychology-package/" +
        orderCustomerId +
        "\n" +
        "50% تخفیف برای شما",
    smsServiceProvider: "ASANAK",
  };
  let result = await axios({
    method: "post",
    url: `${process.env.VUE_APP_API_SMS_URL}/api/smsRequests/`,
    data: bodyData,
  })
    .then((res) => {
      if (!res.data.err) {
        return true;
      } else {
        return {
          err: {
            code: "404",
            details: null,
            message:
              res.data.err.details.messages[0] ==
                "WebService is temporarily unavailable."
                ? "خطا در ارسال پیامک"
                : "خطا در سرور",
          },
        };
      }
    })
    .catch(() => {
      return {
        err: {
          code: "404",
          details: null,
          message:
            res.data.err.details[0].messages[0] ==
              "WebService is temporarily unavailable."
              ? "خطا در ارسال پیامک"
              : "خطا در سرور",
        },
      };
    });

  return result;
}

export async function createOrder(param, basketItems) {
  await userStore.getService()
  let bodyData = {
    customer: { ...param.customer },
    paymentGateways:param.selectedGateways,
    sellerProducts: basketItems.map((bi) => ({id: bi.sellerProductId, count: bi.count})),
    service: userStore.service,
  };

  try {
    const { data } = await axios({
      method: "post",
      url: `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders`,
      data: bodyData,
    });

    if (data.err) {
      throw data;
    }

    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOrder(orderCustomerId) {
  let result = await axios
    .get(
      `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${orderCustomerId}`
    )
    .then((res) => {
      if (!res.data.err) {
        return res.data;
      } else {
        // console.log("getOrder err: ", res.data);
      }
    })
    .catch(() => {
      console.log("catch");
    });
  return result;
}

export async function setOrderDetails(sellerCustomerOrderId, details) {
  let result = await axios({
    method: "patch",
    url: `${process.env.VUE_APP_BACKEND_API}/api/sellerCustomerOrders/${sellerCustomerOrderId}`,
    data:  {
      meta:{
        details: details
      }
    }
  }).then((res) => {
      if (!res.data.err) {
        return res.data;
      } else {
        // console.log("setOrderDetails err: ", res.data);
      }
    })
    .catch(() => {
      console.log("catch");
    });
  return result;
}

export async function getOrdersBySellerID(params = {},paginate) {
  // console.log("params",params);
  // console.log("paginate",paginate);
  let result = await axios({
    method: "get",
    url: `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders?skip=${paginate.skip}&take=${paginate.take}`,
    params 
  })
    .then((res) => {
      if (!res.data.err) return res.data.data;
      else console.log("getOrdersBySellerID err: ", res.data);
    })
    .catch(() => {
      console.log("catch");
    });
  return result;
}

export async function checkout(
  orderId,
  customerId,
  gateway = "ZEENAJI_VANDAR",
  Sco,
  config = {}
) {
  await userStore.getService()
  let paymentResultUrl = configStore.configService.originAddress + '/verify-payment?Sco='+Sco;
  let result = await axios
    .post(`${process.env.VUE_APP_BACKEND_API}/api/payments`, {
      orderId: orderId,
      service: userStore.service,
      customerId: customerId,
      paymentConfig: {
        gateway: gateway,
        paymentResultUrl: paymentResultUrl,
      },
      ...config
    })
    .then((result) => {
      // console.log("checkout result", result);
      if (!result.data.err) return result.data;
      else {
        return {
          err: {
            code: "404",
            details: null,
            message:
              result.data.err.message == "bank-error"
                ? "خطا در برقراری ارتباط با درگاه پرداخت"
                : "خطا در انتقال به درگاه پرداخت",
          },
        };
      }
    })
    .catch(() => {
      console.log("checkout catch");
      return {
        err: {
          code: "404",
          details: null,
          message: "لطفا اتصال خود به اینترنت را بررسی کنید",
        },
      };
    });
  return result;
}

export async function setAnalytics(orderCustomerId) {
  await axios.post(`${process.env.VUE_APP_BACKEND_API}/api/analytics`, {
    action: "page_view",
    category: "sale-network",
    label: "order_view",
    meta: {
      orderCustomerId: orderCustomerId,
    },
    userId: "string",
    value: 0,
  });
}

export async function c2cCheckout(
  orderId = null,
  customerId = null,
  paymentData = {}
) {
  await userStore.getService()
  if (userStore.service === "LIMOOME_SHOP") {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_BACKEND_API}/api/payments`,
        {
          orderId: orderId,
          service: userStore.service,
          customerId: customerId,
        customerService:"SALES_NETWORK",
          paymentConfig: {
            gateway: "C2C",
          },
          meta: {
            externalSaleConfirm: false,
            ...paymentData,
          },
        }
      );
      if (data.err) {
        throw data;
      }
      return data;
    } catch (error) {
      console.error("c2ccheckout error", error);
    }
  } else {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_BACKEND_API}/api/payments`,
        {
          orderId: orderId,
          service: userStore.service,
          customerId: customerId,
          paymentConfig: {
            gateway: "C2C",
          },
          meta: {
            externalSaleConfirm: false,
            ...paymentData,
          },
        }
      );
      if (data.err) {
        throw data;
      }
      return data;
    } catch (error) {
      console.error("c2ccheckout error", error);
    }
  }

}

export async function getAllRewardUserPerDate( dateRange ) {
  let token = localStorage.getItem('token')
  let sellerId = jwt_decode(token).sub;
  try {
    const { data } = await axios.post(
      `${process.env.VUE_APP_BACKEND_API}/api/seller-reward/sum-of-rewards`,
      {
        sellerId: sellerId,
        createdAt: dateRange,
      }
    );
    if (data.err) {
      throw data;
    }
    return data
  } catch (error) {
    console.error("c2ccheckout error", error);
  }
}
export async function getInvoiceById(id){
  try {
    const { data } = await axios.get(`${process.env.VUE_APP_BACKEND_API}/api/orders/${id}/invoice`);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}

export async function deleteOrderIsGift(id){
  try {
    const { data } = await axios.delete(`${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${id}/gift-tag`);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}

export async function createOrderIsGift(id,body){
  try {
    const { data } = await axios.post(`${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${id}/recipient`, body);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}

export async function getPaymentById(id){
  try {
    const { data } = await axios.get(`${process.env.VUE_APP_BACKEND_API}/api/payments/${id}`);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}

export async function checkSnappPayEligibity(body){
  try {
    const { data } = await axios.post(`${process.env.VUE_APP_BACKEND_API}/api/payments/snapp-pay/eligibility`, body);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}

export async function getSellerMonthlyShare() {
  try {
    const { data } = await axios.get(`${process.env.VUE_APP_BACKEND_API}/api/sellers/monthly-shares`);
    if (data.err) {
      throw data;
    }
    return data;
  } catch (error) {
    throw error
  }
}
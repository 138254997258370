<script>
import { Converter } from '@/utils';

export default {
  name: "LmnOtpInput",
  data() {
    return {
      otpValues: Array(6).fill(''),
    };
  },
  methods: {
    handleOnInput(event, index) {
      const value = event.target.value;
      if (/[\d۰-۹٠-٩]/.test(value)) { 
        this.otpValues[index] = value;
        this.$emit("onChange", this.otpValues.join(''));
        this.$nextTick(() => this.focusNextInput(index));
        if (this.otpValues.every(val => val !== '')) {
          this.$emit("onComplete", Converter.toEnNumber(this.otpValues.join('')));
        }
      } else {
        event.target.value = this.otpValues[index];
      }
    },
    handleOnKeyDown(event, index) {
      if (event.key === 'Backspace' && !this.otpValues[index]) {
        this.$nextTick(() => this.focusPreviousInput(index));
      }
    },
    focusNextInput(index) {
      const nextIndex = index + 1;
      if (nextIndex < this.otpValues.length) {
        const nextInput = this.$refs[`otpInput${nextIndex}`][0];
        if (nextInput) {
          nextInput.focus();
        }
      }
    },
    focusPreviousInput(index) {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        const prevInput = this.$refs[`otpInput${prevIndex}`][0];
        if (prevInput) {
          prevInput.focus();
        }
      }
    },
    handleClearInput() {
      this.otpValues = Array(6).fill('');
      const firstInput = this.$refs['otpInput0'][0];
      if (firstInput) {
        firstInput.focus();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const firstInput = this.$refs.otpInput0[0];
      if (firstInput) {
        firstInput.focus();
      }
    });
  },
};
</script>

<template>
  <div class="lmn-otp-input">
    <input
      v-for="(value, index) in otpValues"
      :key="index"
      :ref="`otpInput${index}`"
      class="otp-input"
      type="number"
      maxlength="1"
      v-model="otpValues[index]"
      @input="handleOnInput($event, index)"
      @keydown="handleOnKeyDown($event, index)"
    />
  </div>
</template>

<style lang="less">
.lmn-otp-input {
  direction: ltr !important;
  display: flex;
  justify-content: space-around;  
  @media screen and (max-width: 360px) {
    justify-content: space-between;
  }
}

.otp-input {
  outline: none;
  width: 3rem;
  height: 3rem;
  @media screen and (max-width: 360px) {
    width: 2.5rem;
    height: 2.5rem;
  }
  padding: 4px;
  font-size: 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
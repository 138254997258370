<template>
    <div class="relative">
        <div v-if="discount" class="card_discount bottom-11 left-3 text-IranSans-Bold text-xs" style="direction: rtl;">
          {{discount}}% تخفیف
        </div>
        <div 
            :class="[
                    'card',
                    'relative',
                    {'card_border': border && !selected},
                    {'card_selected': border && selected}
                ]" 
            :style="[{'background-color': bgColor}, {'height': height}]"
        >            
            <div class="flex items-center">
                <slot name="icon"></slot>
                <div class="flex flex-col items-start justify-between">
                    <slot name="title"></slot>
                    <slot name="desc"></slot>
                </div>
            </div>
            <div>            
                <slot name="price"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MpCard',
    props: {  
        bgColor: {
            type: String,
            default: '#fff'
        },
        height: {
            type: String,
            default: '56px'
        } ,
        border: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        discount: {
            type: String
        }
    }
}
</script>

<style lang="less" scoped>
.card {
    direction: rtl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;    
    border-radius: 8px;
    padding: 8px;

    &_border {
        border: 1px solid #F1F1F1;
    }

    &_selected {
        background-color: #EEF5ED;
        border: 1px solid #559847;
    }
    &_discount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28%;
        position: absolute;
        height: 24px;
        background-color: #FF4E4E;
        color: #fff;
        z-index: 10;
        border-radius: 4px;
    }
}
</style>
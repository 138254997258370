<template>
  <div :class="['lmn-error-msg', {'lmn-error-msg--rtl': rtl}]">
    <img
      class="lmn-error-msg__icon"
      src="@/assets/lmn-svg-error-icon.svg"
      alt="error icon"
    />
    <span class="lmn-error-msg__text"><slot></slot> </span>
  </div>
</template>

<script>
export default {
  name: 'LimooError',
  props: {
    message: {
      type: String
    },
    rtl: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.lmn-error-msg {

    &--rtl {
      direction: rtl;
      .lmn-error-msg__icon {
        margin-right: 0 !important;
        margin-left: .5rem !important;
      }

    }
    display: flex;
    align-items: center;
    padding: .1rem 0;
    &__icon {
      margin-right: .5rem;
    }
    &__text {
      color: rgba(255, 107, 107, 1);
      font-size: 12px;
      line-height: 14px;
    }

}
</style>

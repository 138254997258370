<template>
    <div class="checkout-error-section text-center ">
      <InfoCircle :fill="'#FFC837'" class="mb-4 mx-auto" />
      <p class="text-sm font-bold text-center text-lmn-black-1 leading-6">خطا رخ داده است!</p>
      <p class="text-xs font-normal text-center text-lmn-black-2 leading-6">لطفا بعد از چند دقیقه دوباره تلاش کنید.</p>
      <div class="button-container mt-14 max-w-max mx-auto">
        <MpButton @click="onclick" title="تلاش مجدد" class="px-4"></MpButton>
      </div>
    </div>
  </template>
  
  <script>
  import InfoCircle from '@/components/svg/InfoCircle.vue';
  import MpButton from '@/components/MpButton.vue';
    export default {
      name: 'checkout-error-section',
      components: {
        InfoCircle,
        MpButton
      },
      methods: {
        onclick() {
          this.$emit('onclick')
        }
      }
    }
  </script>
  
  <style lang="less" scoped>
  .checkout-error-section {
  
  }
  </style>
  
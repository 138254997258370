import { render, staticRenderFns } from "./LoginRegisterPageTemplate.vue?vue&type=template&id=9274d3ca&scoped=true"
import script from "./LoginRegisterPageTemplate.vue?vue&type=script&lang=js"
export * from "./LoginRegisterPageTemplate.vue?vue&type=script&lang=js"
import style0 from "./LoginRegisterPageTemplate.vue?vue&type=style&index=0&id=9274d3ca&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9274d3ca",
  null
  
)

export default component.exports
<template>
    <div class="w-8 h-8">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4917 1.6665H6.50841C3.47508 1.6665 1.66675 3.47484 1.66675 6.50817V13.4832C1.66675 16.5248 3.47508 18.3332 6.50841 18.3332H13.4834C16.5167 18.3332 18.3251 16.5248 18.3251 13.4915V6.50817C18.3334 3.47484 16.5251 1.6665 13.4917 1.6665ZM13.3334 10.6248H10.6251V13.3332C10.6251 13.6748 10.3417 13.9582 10.0001 13.9582C9.65841 13.9582 9.37508 13.6748 9.37508 13.3332V10.6248H6.66675C6.32508 10.6248 6.04175 10.3415 6.04175 9.99984C6.04175 9.65817 6.32508 9.37484 6.66675 9.37484H9.37508V6.6665C9.37508 6.32484 9.65841 6.0415 10.0001 6.0415C10.3417 6.0415 10.6251 6.32484 10.6251 6.6665V9.37484H13.3334C13.6751 9.37484 13.9584 9.65817 13.9584 9.99984C13.9584 10.3415 13.6751 10.6248 13.3334 10.6248Z" :fill="canAdd ? '#559847' : '#BDBDBD'"/>
    </svg>
    </div>
</template>

<script>
export default {
    props: {
        canAdd: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style>

</style>
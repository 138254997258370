export const months = [
    {
      name: "فروردین",
      value: "1",
    },
    {
      name: "اردیبهشت",
      value: "2",
    },
    {
      name: "خرداد",
      value: "3",
    },
    {
      name: "تیر",
      value: "4",
    },
    {
      name: "مرداد",
      value: "5",
    },
    {
      name: "شهریور",
      value: "6",
    },
    {
      name: "مهر",
      value: "7",
    },
    {
      name: "آبان",
      value: "8",
    },
    {
      name: "آذر",
      value: "9",
    },
    {
      name: "دی",
      value: "10",
    },
    {
      name: "بهمن",
      value: "11",
    },
    {
      name: "اسفند",
      value: "12",
    },
  ];
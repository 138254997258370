import { makeObservable, observable, computed, action,reaction  } from "mobx";
import { LeadService } from "@/services";
import jwtDecode from 'jwt-decode';
class LeadStore {
  _leadList = [];
  _leadJwtToken = null
  constructor() {
    makeObservable(this, {
        _leadJwtToken:observable,
        _leadList: observable,
        fetchLeadList:action,
        setLeadToken:action,
    });

  }
  setLeadToken(token){
    this._leadJwtToken = token;
  }
  getLeadToken() {
    return this._leadJwtToken;
  }
  async getLeadToken(){

  function checkLeadTokenValidation() {
    const token = localStorage.getItem('leadToken');
    if (!token) {
      return false;
    }else{
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
  
      if (decodedToken.exp < currentTime) {
       return false;
      }else{
        return true;
      }
    }    
  }
  let valid = checkLeadTokenValidation()
  if (!valid) {
    try {
      let result = await LeadService.getLeadToken()
      return result
    } catch (error) {
      throw error
    }
  }

  }
  async fetchLeadList(){
    try {
        let result = await LeadService.fetchLeadList()
        return result;
      } catch (error) {
        throw error
      }
    
  }
  async setStatus(param){
    try {
      let result = await LeadService.setStatus(param)
      return result
    } catch (error) {
      throw error
    }
  }
  async getSuppliers(){
    try {
      let result = await LeadService.getSuppliers()
      return result
    } catch (error) {
      throw error
    }
  }
  async sendLeadRequest(param){
    try {
      let result = await LeadService.leadRequest(param)
      return result
    } catch (error) {
      throw error
    }
  }

  
}
export const leadStore = new LeadStore();

<template>
    <mp-action-sheet ref="mpActionSheet" :isFullScreen="true" class="details pb-3">
        <div class="fixed right-3 flex flex-row-reverse  items-center justify-between bg-white">
            <img @click="close()" src="@/assets/close.svg" />
        </div>

        <div class="details_dashed-seperator w-full mb-5 mt-12"></div>
  
        <form class="overflow-hidden">
                <div>
                    <mp-field class="text-IranSans-Medium"  @change="setFirstName" :value="filter.firstName" placeholder="نام" />
                    <mp-field  @change="setLastName" :value="filter.lastName" class="mt-5 text-IranSans-Medium"
                        placeholder="نام خانوادگی" />
                    <mp-field  @change="setMobile"  :value="filter.mobile" placeholder="شماره موبایل" class="mt-5 text-IranSans-Medium"
                        type="tel"  />
                     
                            <mp-field   v-model="formattedDateRange"  class="mt-5 text-IranSans-Medium custom-input"
                            placeholder=" بازه ی تاریخ" />
                        
                       
                        <date-picker
                        v-model="range"
                        @change="changeDate($event)"
                        @open="closeEvent"
                        range
                        format="YYYY-MM-DD"
                        display-format="jMMMM jD"
                        custom-input=".custom-input"
                        class="text-IranSans-Medium"
                        />
                    </div>
                <div class="button-container mt-14 max-w-max mx-auto flex flex-row ">
                    <MpButton @click="onclick" title=" فیلتر " class="px-4 m-2"></MpButton>
                    <MpButton @click="onRemove" title="حذف  " class="px-4 m-2 bg-btn-remove"></MpButton>
                </div>
            </form>




    </mp-action-sheet>
</template>

<script>
import MpActionSheet from "@/components/MpActionSheet.vue"
import MpButton from "@/components/MpButton.vue"
import MpField from "@/components/MpField.vue";
import { observer } from "mobx-vue"
import moment from 'moment-jalaali';
export default observer({
    components: { MpActionSheet, MpButton, MpField },
    data() {
        return {
            range:[],
            filter:{
                    firstName: "",
                    lastName: "",
                    mobile: "",
                    createdAt:{
                        from: "",
                        to: ""
                    },
                
            }
        }
    },
    methods: {
        closeEvent(){
            this.range = []
            this.filter.createdAt.from = '';
            this.filter.createdAt.to = '';
        },
        changeDate(e) {
            this.filter.createdAt.from = moment(e[0]).toISOString();
            this.filter.createdAt.to = moment(e[1]).toISOString();
        },
        setFirstName(e) {
            this.filter.firstName = e.target.value;
            this.$v.filter.firstName.$touch();
            this.setSmsStatus();
        },
        setLastName(e) {
            this.filter.lastName = e.target.value;
            this.$v.filter.lastName.$touch();
            this.setSmsStatus();
        },
        setMobile(e) {
            this.filter.mobile = e.target.value;
            this.$v.filter.mobile.$touch();
            this.setSmsStatus();
        },
        close() {
            this.$refs.mpActionSheet.close();
        },
        onclick() {
            this.$refs.mpActionSheet.close();
            const cleanedFilter = {};
            for (const key in this.filter) {
            if (this.filter[key] !== "") {
                cleanedFilter[key] = this.filter[key];
            }
            }

            if (cleanedFilter.createdAt && !cleanedFilter.createdAt.from && !cleanedFilter.createdAt.to) {
            delete cleanedFilter.createdAt;
            }
            this.$emit("filter", cleanedFilter);
        },
        onRemove(){
            this.filter = {
                firstName: "",
                lastName: "",
                mobile: "",
                createdAt:{
                    from: "",
                    to: ""
                },
            }
            this.range = [];
            this.$emit("filter", this.filter);
        }
    },
    computed: {
    formattedDateRange() {
        if (this.range.length === 0) {
            return '';
        }else {
            const startDate = moment(this.range[0], 'YYYY-MM-DD').format('jMMMM jD');
            const endDate = moment(this.range[1], 'YYYY-MM-DD').format('jMMMM jD');
            return `${startDate} - ${endDate}`;
        }
    },
  },

})
</script>

<style lang="less" scoped>
.bg-btn-remove{
    background-color: rgb(243, 88, 88) !important;
}
</style>
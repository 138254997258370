<template>
    <div class="flex flex-col w-full">
        <div class="w-full flex flex-row justify-between">
            <div class=" flex flex-row">
                <div>
                    <p class="text-sm text-right text-gray-500 ml-2"> نام و نام خانوادگی : </p>
                </div>
                <div>
                    <p class="text-sm text-right text-black">{{ item.firstname }} {{ item.lastname }}</p>
                </div>
            </div>
            <div class=" flex flex-row">
                <div>
                    <p class="text-xs text-right text-gray-500 ml-2"> تاریخ انقضا : </p>
                </div>
                <div>
                    <p class="text-xs text-right text-red-600">{{ getDate(item.expiredAt) }} </p>
                </div>
            </div>
        </div>
        <div class="w-full flex flex-row justify-between mt-4">
            <div class=" flex flex-row">
                <div>
                    <p class="text-sm text-right text-gray-500 ml-2"> موبایل : </p>
                </div>
                <div>
                    <p class="text-sm text-right text-black">{{ item.mobile }} </p>
                </div>
            </div>
            <div class="1 flex flex-row">
                <div>
                    <p class="text-xs text-right text-gray-500 ml-2"> تاریخ دراپ : </p>
                </div>
                <div class=" min-w-24">
                    <p class="text-xs text-right text-black">{{ getDate(item.dropAt) || "__________" }} </p>
                </div>
            </div>
        </div>
 
        <div class="w-full flex flex-row justify-between mt-4">
            <div class=" flex flex-row">
                <div>
                    <p class="text-sm text-right text-gray-500 ml-2"> تامین کننده : </p>
                </div>
                <div>
                    <p class="text-sm text-right text-black">{{ item.supplier }} </p>
                </div>
            </div>
            <div v-if="item.meta?.callDate || item.meta?.reassignCount" class="flex flex-row">
                <div v-if="item.meta?.callDate" class="flex flex-row mr-4">
                    <p class="text-xs text-right text-gray-500 ml-2"> تاریخ تماس مجدد : </p>
                    <p class="text-xs text-right text-black">{{ getDate(item.meta.callDate) }}  </p>
                </div>
                <div v-if="item.meta?.reassignCount" class="flex flex-row">
                    <p class="text-xs text-right text-gray-500 ml-2"> دفعات عدم پاسخگویی : </p>
                    <p class="text-xs text-right text-black">{{ item.meta.reassignCount }}  </p>
                </div>
            </div>
        </div>

        <div class="mt-4 flex flex-col w-full">
            <div class="">
                <p class="text-sm  text-gray-500  w-16">توضیحات : </p>
            </div>
            <div class="w-full text-right">
                <p class="text-sm mt-1 text-justify text-wrap w-full h-auto min-h-36 text-black"
                    v-html="processDescription(item.description)"></p>
            </div>
        </div>

        <div class="w-full h-1 border-b  border-gray-200 my-2"></div>
        <form @submit.prevent="sendStatus(item, selectedStatuses)" class="flex flex-row items-center">
            <multiselect v-model="selectedStatuses" :options="options" :multiple="false" :taggable="true"
                placeholder="انتخاب وضعیت" tag-placeholder=" " :select-label="'انتخاب '" :deselect-label="'حذف '"
                label="name" track-by="value" class="text-xs" :key="item.id">
                <template #option="{ option }">
                    <div class="flex items-center">
                        <span :class="['w-4', 'h-4', 'rounded-full', 'shadow-sm','border', option.color || 'bg-gray-200']"
                            class="mr-2"></span>
                        <span class="mx-2">{{ option.name || 'Unknown' }}</span>
                    </div>
                </template>
            </multiselect>

            <button
                class="bg-green-500 hover:bg-green-600 text-white px-5 py-2 rounded-md mr-2 text-sm transition-all duration-100">
                ثبت
            </button>
        </form>
        <mp-lead-form @submitForm="submitForm" :selectedStatuses="selectedStatuses" ref="MpLeadForm">
        </mp-lead-form>
        <MpNotification ref="notificationRef" />
    </div>
</template>

<script>
import MpActionSheet from "@/components/MpActionSheet.vue";
import MpLeadForm from "@/components/MpLeadForm.vue";
import MpNotification from "@/components/MpNotification.vue";
import Multiselect from "vue-multiselect";
import { leadStore } from "@/stores";
export default {
    components: { Multiselect, MpActionSheet, MpLeadForm, MpNotification },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            leadStore,
            notification: {
                visible: false,
                text: "",
                type: "",
                closable: true,
            },
            selectedStatuses: {},
            selectedItem: { id: "", status: "" },
            options: [
                { name: "لینک ارسال شد", value: "linkSent", color: "bg-green-500" },
                { name: "لینک ارسال نشد", value: "linkNotSent", color: "bg-red-500" },
                { name: "پاسخگو نبود", value: "notAnswered", color: "bg-yellow-400" },
                { name: "تکراری", value: "DUPLICATE", color: "bg-gray-500" },
                { name: "بلاک", value: "BLOCKED", color: "bg-black" },
                { name: "خرید کرده", value: "ALREADY_PURCHASED", color: "bg-yellow-600" },
                { name: "مشاوره شده توسط همکار", value: "CONTACTED_BY_ANOTHER_SELLER", color: "bg-pink-500" },
                { name: "تماس در آینده", value: "CALL_RESCHEDULED", color: "bg-white" },
            ],
        }
    },
    methods: {
      async  submitForm(form) {
            let sendData = {     
                id:this.selectedItem.id,
                status:this.selectedItem.status.value,
                sellerDescription:form.sellerDescription,
                meta:{
                    callDate:form.callDate
            }
            }
            if (this.selectedStatuses.value !== 'CALL_RESCHEDULED') {
                delete sendData.meta
            }
            try {
              let result = await leadStore.setStatus(sendData);  
              if (result) {
                this.$refs.MpLeadForm.$refs.mpActionSheet.close();
                this.$refs.notificationRef.showNotification("با موفقیت انجام شد", "success");
                this.$emit('initAgain');
              
              }else{
                this.$refs.notificationRef.showNotification(
                    "با خطا مواجه شد",
                    "error",
                    5000,
                    true
                  );
              }

            } catch (error) {

            }
        },
        sendStatus(lead, status) {
            // console.log("lead", lead);
            if (!status) {
                return;
            }
            this.selectedItem.id = lead.id;
            this.selectedItem.status = status;

            if (this.$refs.MpLeadForm && this.$refs.MpLeadForm.$refs.mpActionSheet) {
                this.$refs.MpLeadForm.$refs.mpActionSheet.open();
            } else {
                console.error("mpActionSheet or MpLeadForm ref is undefined");
            }
        },
        getDate(dateParam) {
            if (dateParam && dateParam !== null) {
                let date = new Date(dateParam.substring(0, 10)).toLocaleDateString('fa-IR',);

                return date;
            } else {
                return dateParam
            }

        },
        processDescription(description) {
            if (!description) return '';

            // Regex to find the text between ##...##
            const regex = /##(.*?)##/g;

            // Replace the matched text with an anchor tag
            return description.replace(regex, (match, p1) => {
                const url = p1.trim(); // Extract the URL

                // Create an anchor element to parse the URL and get the domain
                const anchor = document.createElement('a');
                anchor.href = url;
                const domain = anchor.hostname; // Extract the domain (e.g., "planium.ir")

                // Return the anchor tag with the clickable link
                return `<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${url}</a>`;
            });
        },
    },
    mounted() {

    }
}
</script>
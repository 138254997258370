<script>
import {LoginRegisterPageTemplate, LmnTopNav, LmndMobileLogin } from "@/components/login";

export default {
  name: "LoginWithMobilePage",
  components: {
    LmndMobileLogin,
    LmnTopNav,
    LoginRegisterPageTemplate,
  },
};
</script>

<template>
  <login-register-page-template>
    <template #top-nav>
      <lmn-top-nav rtl />
    </template>
    <template #main>
      <lmnd-mobile-login rtl />
    </template>
  </login-register-page-template>
</template>

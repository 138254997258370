<template>
<svg :fill="fill" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve">
<g>
	<g>
		<path d="M478.33,51.307H33.67C15.105,51.307,0,66.411,0,84.977v273.637c0,18.566,15.105,33.67,33.67,33.67h153.391l-6.547,52.376
			h-10.025c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h171.023c4.427,0,8.017-3.589,8.017-8.017
			c0-4.427-3.589-8.017-8.017-8.017h-10.025l-6.547-52.376h153.39c18.566,0,33.67-15.105,33.67-33.67V84.977
			C512,66.411,496.895,51.307,478.33,51.307z M196.672,444.66l6.547-52.376h105.563l6.546,52.376H196.672z M495.967,358.614
			c0,9.725-7.912,17.637-17.637,17.637H33.67c-9.725,0-17.637-7.912-17.637-17.637V84.977c0-9.725,7.912-17.637,17.637-17.637
			h444.66c9.725,0,17.637,7.912,17.637,17.637V358.614z"/>
	</g>
</g>
<g>
	<g>
		<path d="M469.779,85.511H42.221c-4.427,0-8.017,3.589-8.017,8.017v256.534c0,4.427,3.589,8.017,8.017,8.017h427.557
			c4.427,0,8.017-3.589,8.017-8.017V93.528C477.795,89.101,474.206,85.511,469.779,85.511z M461.762,342.046H50.238V101.545h411.524
			V342.046z"/>
	</g>
</g>
<g>
	<g>
		<path d="M247.449,119.716H93.528c-13.851,0-25.119,11.268-25.119,25.119v153.921c0,13.851,11.268,25.119,25.119,25.119h153.921
			c13.851,0,25.119-11.268,25.119-25.119V144.835C272.568,130.984,261.3,119.716,247.449,119.716z M188.125,307.841h-35.273v-10.319
			l4.713,2.828c3.986,2.392,8.455,3.587,12.924,3.587s8.938-1.196,12.924-3.587l4.713-2.828V307.841z M196.665,273.701
			l-21.502,12.902c-2.883,1.731-6.466,1.731-9.349,0l-21.502-12.902c-5.281-3.169-8.563-8.964-8.563-15.124v-28.501
			c0-0.289,0.231-0.518,0.537-0.534c31.805-1.654,49.435-7.884,59.202-14.165l9.583,9.583c0.1,0.1,0.156,0.235,0.156,0.377v13.561
			h0.001c0,9.725-7.912,17.637-17.637,17.637h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
			c4.883,0,9.522-1.053,13.714-2.931C200.021,271.235,198.463,272.622,196.665,273.701z M204.159,307.841v-19.938l0.756-0.453
			c10.083-6.05,16.347-17.113,16.347-28.872v-33.24c0-4.426-1.723-8.586-4.853-11.715l-14.598-14.598
			c-1.503-1.503-3.542-2.348-5.668-2.348s-4.165,0.844-5.668,2.348c-7.692,7.692-28.259,13.114-55.019,14.506
			c-8.825,0.459-15.738,7.727-15.738,16.546v28.501c0,11.759,6.264,22.822,16.347,28.872l0.756,0.453v19.938h-26.691
			c0.37-51.842,4.402-85.653,12.938-108.941c9.506-25.932,24.132-37.498,47.424-37.498s37.918,11.565,47.424,37.498
			c8.536,23.287,12.568,57.098,12.938,108.941H204.159z M256.534,298.756c0,5.01-4.076,9.086-9.086,9.086h-0.565
			c-0.385-53.851-4.72-89.368-13.917-114.458c-11.843-32.308-32.28-48.014-62.478-48.014s-50.635,15.705-62.478,48.014
			c-9.197,25.09-13.532,60.607-13.917,114.458h-0.565c-5.01,0-9.086-4.076-9.086-9.086V144.835c0-5.01,4.076-9.086,9.086-9.086
			h153.921c5.01,0,9.086,4.076,9.086,9.086V298.756z"/>
	</g>
</g>
<g>
	<g>
		<path d="M324.409,136.818h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
			c4.427,0,8.017-3.589,8.017-8.017S328.837,136.818,324.409,136.818z"/>
	</g>
</g>
<g>
	<g>
		<path d="M324.409,171.023h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
			c4.427,0,8.017-3.589,8.017-8.017S328.837,171.023,324.409,171.023z"/>
	</g>
</g>
<g>
	<g>
		<path d="M324.409,205.228h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
			c4.427,0,8.017-3.589,8.017-8.017S328.837,205.228,324.409,205.228z"/>
	</g>
</g>
<g>
	<g>
		<path d="M427.023,136.818h-68.409c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h68.409
			c4.427,0,8.017-3.589,8.017-8.017S431.45,136.818,427.023,136.818z"/>
	</g>
</g>
<g>
	<g>
		<path d="M427.023,171.023h-68.409c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h68.409
			c4.427,0,8.017-3.589,8.017-8.017S431.45,171.023,427.023,171.023z"/>
	</g>
</g>
<g>
	<g>
		<path d="M427.023,205.228h-68.409c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h68.409
			c4.427,0,8.017-3.589,8.017-8.017S431.45,205.228,427.023,205.228z"/>
	</g>
</g>
<g>
	<g>
		<path d="M418.472,239.432H315.858c-9.136,0-16.568,7.432-16.568,16.568v51.307c0,9.136,7.432,16.568,16.568,16.568h102.614
			c9.136,0,16.568-7.432,16.568-16.568V256C435.04,246.864,427.608,239.432,418.472,239.432z M419.006,307.307
			c0,0.295-0.239,0.534-0.534,0.534H315.858c-0.295,0-0.534-0.239-0.534-0.534V256c0-0.295,0.239-0.534,0.534-0.534h102.614
			c0.295,0,0.534,0.239,0.534,0.534V307.307z"/>
	</g>
</g>
</svg>
 </template>
 
 <script>
 export default {
     props: {
         fill: {
             type: String,
             default: '#4B4C4D'
         }
     }
 }
 </script>
 
 <style>
 
 </style>
<template>
  <div class="pt-4 basket pb-36">
    <mp-action-sheet ref="mpActionSheetTaraForm" @closed="resetData()">
      <p class="text-IranSans-Bold text-base text-right mb-4 my-6">
        مشخصات خریدار
      </p>
      <mp-field
        @blur="setFirstName"
        @input="setFirstName"
        :value="customer.firstName"
        placeholder="نام"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.firstName.$error &&
          $v.customer.firstName.$dirty &&
          !$v.customer.firstName.required
        "
      >
        لطفا نام را وارد کنید
      </lmn-field-error>

      <mp-field
        @blur="setLastName"
        @input="setLastName"
        :value="customer.lastName"
        class="mt-5"
        placeholder="نام خانوادگی"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.lastName.$error &&
          $v.customer.lastName.$dirty &&
          !$v.customer.lastName.required
        "
        >لطفا نام‌خانوادگی را وارد کنید</lmn-field-error
      >

      <mp-field
        @blur="setMobile"
        @input="setMobile"
        :value="customer.mobile"
        placeholder="09"
        class="mt-5"
        type="tel"
        :rtl="false"
      />
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.mobile.$error &&
          $v.customer.mobile.$dirty &&
          !$v.customer.mobile.required
        "
        >لطفا شماره موبایل را وارد کنید</lmn-field-error
      >
      <lmn-field-error
        :rtl="true"
        class="mt-1"
        v-if="
          $v.customer.mobile.$error &&
          $v.customer.mobile.$dirty &&
          !$v.customer.mobile.IRMobileNumber
        "
        >لطفا شماره موبایل را با فرمت 09 وارد کنید</lmn-field-error
      >

      <mp-button
        :disable="sendSmsBtnDisable"
        @click="createOrderAndGetOrders"
        class="mt-5"
        :loading="createOrderAndGetOrdersNetworkState === 'PENDING'"
        title="ارسال لینک خرید"
      />
      <template
        v-if="
          createOrderAndGetOrdersNetworkState === 'SUCCESS' ||
          createOrderAndGetOrdersNetworkState === 'FAILED'
        "
      >
        <div
          :class="[
            'mb-5',
            'mt-3',
            `basket_${messages.sendSms.status}`,
            'text-xs',
          ]"
        >
          {{ messages.sendSms.desc }}
        </div>
        <!-- <p
          v-if="orderCustomerUrl"
          class="text-right text-xs text-IranSans-Light mb-2"
          style="direction: rtl"
        >
          در صورت عدم ارسال پیامک، می‌توانید لینک زیر را کپی و ارسال کنید.
        </p>
        <mp-field
          v-if="orderCustomerUrl"
          :value="orderCustomerUrl"
          :disable="true"
          :rtl="false"
          :canCopy="true"
        /> -->
      </template>
    </mp-action-sheet>

  </div>
</template>

<script>
import { orderStore, productStore } from "@/stores/index";
import { OrderSevices } from "@/services/index";
import ProductCard from "@/components/ProductCard.vue";
import MpButton from "@/components/MpButton.vue";
import { Plus, Minus } from "@/components/svg";
import { observer } from "mobx-vue";
import { required, helpers } from "vuelidate/lib/validators";
import LmnFieldError from "@/components/login/LmnErrorMsg.vue";
import MpActionSheet from "@/components/MpActionSheet.vue";
import MpField from "@/components/MpField.vue";

import InfoBox from "@/components/InfoBox.vue";
import SellerShareTable from "@/components/SellerShareTable.vue";
const IRMobileNumber = helpers.regex(
  "IRMobileNumber",
  /^(((09|9)[0-9]{9})|((۰۹|۹)[۰-۹]{9})|(([\u06F0][\u06F9]|[\u06F9])[\u06F0-\u06F9]{9})|(([\u0660][\u0669]|[\u0669])[\u0660-\u0669]{9}))$/
);

export default observer({
  components: {
    ProductCard,
    Plus,
    Minus,
    MpButton,
    LmnFieldError,
    MpActionSheet,
    MpField,
    InfoBox,
    SellerShareTable,
  },
  data() {
    return {
      orderStore,
      productStore,
      sendSmsBtnDisable: true,
      pendingSendSms: null,
      selectedProductId: null,
      createOrderAndGetOrdersNetworkState: "IDLE",
      fetchOrderNetworkState: "IDLE",
      shareAmount: {
        primary: 0,
        extra: 0,
        total: 0,
      },
      customer: {
        firstName: null,
        lastName: null,
        mobile: null,
      },
      buyerActionSheetStatus: false,
      messages: {
        sendSms: {
          status: null,
          desc: null,
        },
      },
      orderCustomerUrl: null,
    };
  },
  validations: {
    customer: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      mobile: {
        required,
        IRMobileNumber,
      },
    },
  },
  mounted() {
    this.setShareAmount({
      primary: orderStore.basketItemsTotalSellerShare,
      extra: 0,
      total: orderStore.basketItemsTotalSellerShare,
    });
  },

  methods: {
    setShareAmount(
      { primary, extra, total } = { primary: 0, extra: 0, total: 0 }
    ) {
      this.shareAmount = {
        primary,
        extra,
        total,
      };
    },
    mapSellerShareItems(items = []) {
      if (items.length > 0) {
        return items.reduce();
      } else {
        return [];
      }
    },
    getIcon(product) {
      if (product?.images?.icon)
        return require(`@/assets/products/${product.images?.icon}`);
      else return require(`@/assets/package.svg`);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async createOrderAndGetOrders() {
      this.createOrderAndGetOrdersNetworkState = "PENDING";
      try {
        const customerOrderId = await this.createOrder();
        const orders = await this.getOrder(customerOrderId);
        this.createOrderAndGetOrdersNetworkState = "SUCCESS";
      } catch (error) {
        this.createOrderAndGetOrdersNetworkState = "FAILED";
      }
    },
    async createOrder() {
      if (this.sendSmsBtnDisable) return;
      try {
        this.pendingSendSms = true;
        this.customer.mobile = this.toEnNumber(this.customer.mobile);
        const data = await orderStore.createTaraOrder(this.customer);
        if (data.err) {
          throw data;
        }

        const customerOrderId = data.id;
        this.pendingSendSms = false;
        if (data.smsStatus === 'sent') {
      
            this.messages.sendSms.desc = "✓ پیامک با موفقیت ارسال شد";
          this.messages.sendSms.status = "success";
          
        } else if (data.smsStatus === 'unsent') {
          this.messages.sendSms.desc = "مشکل در ارسال پیامک. لطفا دوباره تلاش کنید";
          this.messages.sendSms.status = "error";
          
        }
        this.orderCustomerUrl = `http://panel.zeenaji.com/payment/${customerOrderId}`;
        return customerOrderId;
      } catch (error) {
        this.pendingSendSms = false;
        if (error.err) {
        }
        this.messages.sendSms.desc = "متاسفانه مشکلی پیش آمده لطفا مجددا تلاش نمایید!";
        this.messages.sendSms.status = "error";
        throw error;
      }
    },
    async getOrder(customerOrderId = null) {
      try {
        this.fetchOrderNetworkState = "PENDING";
        let data = await OrderSevices.getOrder(customerOrderId);
        if (data.err) {
          throw data;
        }

        const sellerShare = data.order.basketItems.reduce(
          (acc, curr) => (acc += curr.meta.sellerShare || 0),
          0
        );
        const extraSellerShare = data.order.basketItems.reduce(
          (acc, curr) => (acc += curr.meta.extraShareForNewCustomer || 0),
          0
        );

        this.setShareAmount({
          primary: sellerShare - extraSellerShare,
          extra: extraSellerShare,
          total: sellerShare,
        });

        this.fetchOrderNetworkState = "SUCCESS";
        return data;
      } catch (error) {
        this.fetchOrderNetworkState = "FAILED";
        throw error;
      }
    },
    setFirstName(e) {
      this.customer.firstName = e.target.value;
      this.$v.customer.firstName.$touch();
      this.setSmsStatus();
    },
    setLastName(e) {
      this.customer.lastName = e.target.value;
      this.$v.customer.lastName.$touch();
      this.setSmsStatus();
    },
    setMobile(e) {
      this.customer.mobile = e.target.value;
      this.$v.customer.mobile.$touch();
      this.setSmsStatus();
    },
    resetData() {
      this.customer.firstName = null;
      this.customer.lastName = null;
      this.customer.mobile = null;
      this.messages.sendSms.status = null;
      this.messages.sendSms.desc = null;
      this.$v.customer.firstName.$reset();
      this.$v.customer.lastName.$reset();
      this.$v.customer.mobile.$reset();
      this.createOrderAndGetOrdersNetworkState = "IDLE";
      this.orderStore.removeToBasketTaraItems()
      // console.log("test",this.orderStore.basketTaraItems);
    },
    setSmsStatus() {
      if (
        this.$v.customer.firstName.$invalid ||
        this.$v.customer.lastName.$invalid ||
        this.$v.customer.mobile.$invalid
      ) {
        this.sendSmsBtnDisable = true;
      } else {
        this.sendSmsBtnDisable = false;
      }
    },
    toEnNumber(value) {
      const persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
      ];
      const arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g,
      ];
      if (typeof value === "string") {
        for (let i = 0; i < 10; i++) {
          value = value
            .replace(persianNumbers[i], String(i))
            .replace(arabicNumbers[i], String(i));
        }
      }
      return value;
    },
    getParentId(el) {
      if (el.id) {
        return el.id;
      }
      return this.getParentId(el.parentElement);
    },
    changeViewHandler() {
      this.$emit("changeView", 2);
    },
  },
});
</script>

<style lang="less" scoped>
.basket {
  // font-family: "IranSans-Medium" !important;

  &_btn {
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
    max-width: 600px;
  }

  &_error {
    color: rgba(255, 107, 107, 1);
    direction: rtl;
  }

  &_success {
    color: #519b44;
    direction: rtl;
  }
}
</style>

import jwtDecode from 'jwt-decode';
export function checkTokenValidation() {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
  
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
  
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  }
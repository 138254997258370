var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rg-btn-container-foreground",style:(_vm.cssVariables)},_vm._l((_vm.localOptions),function(button,index){return _c('div',{key:`ring-btn-${index}`,staticClass:"w-full"},[(button.enabled)?_c('div',{staticClass:"rg-btn-container",on:{"click":function($event){return _vm.handleButtonClick(button)}}},[_c('div',{class:{
      'rg-btn-item': true,
      'rg-btn-border': button.selected || false,
      'rg-btn-border-deselect': !button.selected
    }},[_c('div',{class:[
          'rg-btn-icon',
          { 'rg-btn-icon-active': button.selected  },
          { 'rg-btn-icon-deselect': !button.selected },
        ]},[_vm._t("icon",function(){return [_c(button.icon.name,_vm._b({tag:"component"},'component',button.selected ? button.icon.props : null,false))]},{"props":button}),(button.badge > 0)?_c('div',{staticClass:"rg-btn-badge"}):_vm._e()],2),_c('div',{class:['rg-btn-title', { 'rg-btn-title-active': button.selected }]},[_vm._t("title",function(){return [_vm._v(_vm._s(button.title))]},{"props":button})],2)])]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
import jwt_decode from "jwt-decode";
export function getService () {
 let service = sessionStorage.getItem('service') || window.service || 'sales_network'
 return service
}
export async function registerMobileNumberToSendOTP({
  mobileNumber,
  countryID,
}) {

  try {
    const res = await axios.post(`${process.env.VUE_APP_BACKEND_API}/api/generate-otp`, {
      mobile: `0${mobileNumber}`,
      service: getService()
    })

    if (res.data) {
      if (res.data.err) {
        throw res.data;
      }
      return res.data;
    }

    throw {
      err: {
        code: res.status,
        details: null,
        message: res.statusText,
      },
    };
  } catch (error) {
    throw error;
  }
}

export async function loginWithMobileNumber({
  mobileNumber,
  countryID,
  OTPCode,
}) {
  const postData = {
    mobileNumber,
    countryId: countryID,
    otp: OTPCode,
  };
  try {
    const res = await axios.post(`${process.env.VUE_APP_BACKEND_API}/api/verify-otp`, {
      mobile: `0${mobileNumber}`,
      otpCode: OTPCode,
      service: getService(),
      registerIfNecessary: false
    })

    if (res.data) {
      if (res.data.err) {
        throw res.data.err;
      }
      return res.data;
    }
    console.log('throw');
    throw {

      err: {
        code: res.status,
        details: null,
        message: res.statusText,
      },
    };
  } catch (error) {
    console.log('catch: ', error);
    throw {
      err: {
        code: error.code,
        details: error.details.messages[0],
        message: error.message,
      },
    };
  }
}

export async function logout() {
  localStorage.clear();
  // try {
  //   const res = await fetch("/app/auth/logout", {
  //     method: "POST",
  //   });
  //   if (res.status === 200) {
  //     const data = await res.json();
  //     if (data.err || data.status === "error") {
  //       throw data;
  //     }
  //     return data;
  //   }
  //   throw {
  //     err: {
  //       code: res.status,
  //       details: null,
  //       message: res.statusText,
  //     },
  //   };
  // } catch (error) {
  //   throw error;
  // }
}

export async function checkAuthWithMobileNumber({ mobileNumber, countryID }) {
  const postData = {
    mobileNumber,
    countryId: countryID,
  };
  try {
    const res = await fetch(`${process.env.VUE_APP_API_RINGEL_URL}/auth/check-mobile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });

    if (res.status === 200) {
      const data = await res.json();
      if (data.err) {
        throw data;
      }
      return data;
    }

    throw {
      err: {
        code: res.status,
        details: null,
        message: res.statusText,
      },
    };
  } catch (error) {
    throw error;
  }
}

export async function isLoggedIn() {
  try {
    const res = await fetch(`${process.env.VUE_APP_API_RINGEL_URL}/api/auth/isloggedin`);

    if (res.status === 200) {
      const data = await res.json();
      if (data.status == "error") {
        throw data;
      }
      return data.msg;
    }

    throw {
      err: {
        code: res.status,
        details: null,
        message: res.statusText,
      },
    };
  } catch (error) {
    throw error;
  }
}

export async function getSellerIdentify() {
  let token = localStorage.getItem('token');
  let decodeToken = jwt_decode(token);
  let result = await axios({
    method: 'get',
    url: `${process.env.VUE_APP_BACKEND_API}/api/authUsers/${decodeToken.sub}`,
  })
  if (result.data) {
    if (result.data.err) {
      throw res.data.err;
    }
    return result.data;
  }
  console.log('throw');
  throw {

    err: {
      code: result.status,
      details: null,
      message: result.statusText,
    },
  };
}
export async function getSellerConversionRates({ queryId, mobile }) {
  const queryParams = {
    queryId,
    mobile
  };

  try {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_API}/api/query-results/seller-conversion-rates`, {
      params: queryParams,
    });

    if (res.data) {
      if (res.data.err) {
        throw res.data.err;
      }
      return res.data;
    }

    throw {
      err: {
        code: res.status,
        details: null,
        message: res.statusText,
      },
    };
  } catch (error) {
    console.log('catch: ', error);
    throw {
      err: {
        code: error.response?.status || error.code,
        details: error.response?.data || error.message,
        message: error.message,
      },
    };
  }
}

<template>
  <div class="header p-4 mb-4">
    <div class="flex items-center justify-between text-IranSans-Medium mb-6">
      <img
        @click="logout"
        style="width: 24px; cursor: pointer"
        src="@/assets/logout.svg"
      />
      <div class="flex items-center">
        <p class="text-sm mr-1 text" style="direction: rtl">
          {{ userStore.sellerInfo.firstName }} عزیز
        </p>
        <img src="@/assets/pesonel-pic.svg" />
      </div>
    </div>

    <p v-show="!hide" class="text-IranSans-Bold text-base text-right mb-2">حساب من</p>

    <mp-card v-show="!hide" class="mb-3">
      <template #icon>
        <basket class="ml-2" />
      </template>
      <template #title>
        <p class="text-IranSans-Medium text-sm" style="color: #696969">فروش ماهانه من</p>
      </template>
      <template #price>
        <p v-if="!loadingMonthlyShare" class="text-IranSans-Medium text-sm">
          {{totalSales ? numberWithCommas(totalSales):"" }} تومان
        </p>
        <div v-else class="header_loader ml-4"></div>
      </template>
    </mp-card>

    <mp-card v-show="!hide" :bgColor="'#BBD6B5'">
      <template #icon>
        <img class="ml-2" src="@/assets/card-receive.svg" />
      </template>
      <template #title>
        <p class="text-IranSans-Medium text-sm">سهم ماهانه من </p>
      </template>
      <!-- <template #desc>
                <p class="text-IranSans-Light text-xs text-white">آبان 1401</p>
            </template> -->
      <template #price>
        <p v-if="!loadingMonthlyShare" class="text-IranSans-Medium text-sm">
         {{ sellerShares? numberWithCommas(sellerShares):"" }} تومان
        </p>
        <div v-else class="header_loader ml-4"></div>
      </template>
    </mp-card>
    <mp-card v-show="!hide" :bgColor="'#fff'" class="mt-4">
      <template #icon>
        <Month class="ml-2" />
      </template>
      <template #title>
        <p class="text-IranSans-Medium text-sm">پاداش ماهانه من</p>
      </template>
      <template #price>
        <p
          v-if="!loading && allRewardPrice !== null"
          class="text-IranSans-Medium text-sm"
        >
          {{ new Intl.NumberFormat("fa-IR").format(allRewardPrice) }} تومان
        </p>
        <div v-else class="header_loader ml-4"></div>
      </template>
    </mp-card>
  </div>
</template>

<script>
import MpCard from "@/components/MpCard.vue";
import { OrderSevices } from "@/services";
import { orderStore, userStore } from "@/stores";
import { observer } from "mobx-vue";
import { Basket, Month } from "@/components/svg";
import { configStore } from "@/stores";

export default observer({
  name: "Header",
  components: {
    MpCard,
    Basket,
    Month,
  },
  data() {
    return {
      hide: false,
      userStore,
      orderStore,
      saleHistory: null,
      firstname: null,
      lastname: null,
      mobile: null,
      loading: false,
      loadingMonthlyShare:false,
      allRewardPrice: null,
      sellerShares: null,
      totalSales: null
    };
  },
  created() {
    this.initialize();
    this.getSaleHistories();
    this.getSellerIdentify();
    this.getRewardPrice();
    this.getAllSellerMonthlyShare()
  },
  methods: {
    async initialize() {
      this.hide = configStore.configService.service === "limoome_shop" ? true : false;
      
    },
    async getRewardPrice() {
      try {
        this.loading = true;
        const res = await orderStore.getAllRewardPrice();
        if (res.data && res.data[0]) {
          this.allRewardPrice = res.data[0].totalAmount;
        } else {
          this.allRewardPrice = 0;
        }
      } catch (error) {
        console.error("Error", error);
        this.allRewardPrice = 0;
      } finally {
        this.loading = false;
      }
    },
    async getSaleHistories() {
      let token = localStorage.getItem("token");
      let paginate = {skip:0,take:50}
      let results = await OrderSevices.getOrdersBySellerID(null,paginate);
      this.saleHistory = results;
      this.$emit("getSaleHistories", this.saleHistory);
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async getSellerIdentify() {
      this.loading = true;
      try {
        await userStore.sellerIdentify();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    logout() {
      userStore.logout();
    },
    async getAllSellerMonthlyShare(){
      try {
        const res =await orderStore.getSellerMonthlyShare()
        this.loadingMonthlyShare=true
        this.sellerShares = res.sellerShares;
        this.totalSales = res.totalSales;
      } catch (error) {
        
      }  finally {
    // Ensure loading is set to false in both success and failure
    this.loadingMonthlyShare = false;
  }
    }
  },

});
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  background-color: #eef5ed;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  &_loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #bbd6b5;
    width: 20px;
    height: 20px;
    animation: spin-e9563f6c 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
>

import { render, staticRenderFns } from "./PaymentResult.vue?vue&type=template&id=7c9a8042&scoped=true"
import script from "./PaymentResult.vue?vue&type=script&lang=js"
export * from "./PaymentResult.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c9a8042",
  null
  
)

export default component.exports
<script>
import LmnFieldError from '@/components/login/LmnErrorMsg.vue'
import { required, helpers } from "vuelidate/lib/validators";
import { observer } from 'mobx-vue'
import { userStore } from '@/stores'
import {Converter} from '@/utils';

export default observer({
  components: { LmnFieldError },
  name: "LmndEnterMobile",
  data() {
    return {
      token:null,
      mobileNumber: null,
      userStore,
      loading: false,
      serverError: null
    };
  },
  props: {
    rtl: {
      type: Boolean,
      default: true,
    },
  },
  validations: {
    mobileNumber: {
      required,
    },
  },
  created(){
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.loginByToken()
    }
  },
  methods: {
   async loginByToken(){
    let res=  await userStore.loginWithToken(this.token)
      await userStore.sellerIdentify();
    if (res) {
      this.$router.push('/')
    }
    },
    async submitEnterMobileForm() {
      this.loading = true
      this.serverError = null
      try {
        //await userStore.checkAuthWithMobileNumber({mobileNumber: this.mobileNumber, countryID: '1'})
        //this.loading = false
        this.serverError = null
        //if (userStore.isRegistered) {
          // console.log('submitEnterMobileForm');
          this.mobileNumber = Converter.toEnNumber(this.mobileNumber)
          await userStore.registerMobileNumberToSendOTP({mobileNumber: this.mobileNumber, countryID: userStore.countryID})
          this.$router.push('login-with-mobile')
        // } else {
        //   this.serverError = "شماره شما ثبت نشده است"
        // }
      } catch (error) {
        this.loading = false;
        if (error.err.code == 404) {
          this.serverError = "صفحه مورد نظر یافت نشد";
        } else if (error.err.code == 400) {
          this.serverError = "لطفا اطلاعات خود را صحیح وارد نمایید";
        } else {
          this.serverError = "خطا دسترسی به سرور";
        }
      }
    },
    setMobileNumber(e) {
      if (this.mobileNumber && (this.mobileNumber.startsWith("09") || this.mobileNumber.startsWith("۰۹")) ) {
        this.mobileNumber = this.mobileNumber.substring(1);
      }
      this.$v.mobileNumber.$touch();
    }
  },
});
</script>

<template>
  <div :class="['lmnd-enter-mobile', { 'lmnd-enter-mobile--rtl text-IranSans-Medium': rtl }]">
    <div class="lmnd-enter-mobile__main text-IranSans-Medium">
      <label class="enter-mobile-form__label text-IranSans-Medium">{{
          "لطفا شماره موبایل خود را وارد کنید"
      }}</label>
      <form @submit.prevent="submitEnterMobileForm" id="enter-mobile-form" class="enter-mobile-form">
        <div class="enter-mobile-form__field-container">
          <div class="enter-mobile-form__field">
            <!-- mobile number input -->
            <div class="enter-mobile-form__field__mobile-number-input">
              <input v-model="mobileNumber" maxlength="10"
                class="enter-mobile-form__field__mobile-number-input__control" type="tel" placeholder="9*********"
                @blur="setMobileNumber" @change="setMobileNumber" @input="setMobileNumber" />
            </div>
          </div>

          <!-- client form validation errors -->
          <div class="enter-mobile-form__field__errors-container" v-if="$v.$anyError">
            <div class="enter-mobile-form__field__error" v-if="$v.mobileNumber.$error && !$v.mobileNumber.required">
              <lmn-field-error :rtl="rtl">شماره تلفن وارد نشده است</lmn-field-error>
            </div>
            <div class="enter-mobile-form__field__error" v-if="$v.mobileNumber.$error && !$v.mobileNumber.isMobile">
              <lmn-field-error :rtl="rtl">شماره تلفن صحیح وارد نشده است</lmn-field-error>
            </div>
          </div>
        </div>

        <!-- server error -->
        <div v-if="serverError" class="enter-mobile-form__field__errors-container">
          <lmn-field-error :rtl="true">
            {{ serverError }}
          </lmn-field-error>
        </div>
      </form>
    </div>
    <div class="lmnd-enter-mobile__footer">

      <button type="submit" form="enter-mobile-form" :class="loading && 'lmn-btn--loading'"
        class="login-register-form-template__submit-btn lmn-btn lmn-btn--primary"
        :disabled="$v.$anyError || !$v.$dirty">
        <div v-if="loading" class="loading-spinner" />
        <span v-else>ادامه</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../styles/_login-register-form-template.less";
@import "../../styles/_buttons.less";

.lmnd-enter-mobile {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;


  &--rtl {
    direction: rtl;
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
    line-height: 22px;
    letter-spacing: 0em;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }

  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }


  &__main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .enter-mobile-form {
      font-weight: 400;

      &__label {
        direction: rtl;
        //font-weight: bold;
        color: #5A5A5A;
      }

      &__field {
        direction: ltr;
        display: flex;
        border: solid 1px #bdbdbd;
        border-radius: 8px;
        min-height: 3rem;
        height: 3rem;
        overflow: hidden;
        background-color: white;
        color: #5A5A5A;

        &__seprator {
          border-left: 0.5px solid #bdbdbd;
          margin: auto;
          height: 32px
        }

        &__country-precode-input {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
        }

        &__mobile-number-input {
          // padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;

          &__control {
            border-radius: 8px;
            outline: none;
            padding: 1rem;
            height: 100%;
            width: 100%;
          }
        }

        &__errors-container {
          margin: 8px 0;
          display: flex;
          flex-direction: column;
          gap: 4px;

        }

        &__error {
          // border: 1px solid red;
        }
      }


      .errors {
        margin-top: 0.5rem;
      }
    }


  }
}
</style>

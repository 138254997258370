<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9987 3.33398C10.8154 3.33398 3.33203 10.8173 3.33203 20.0007C3.33203 29.184 10.8154 36.6673 19.9987 36.6673C29.182 36.6673 36.6654 29.184 36.6654 20.0007C36.6654 10.8173 29.182 3.33398 19.9987 3.33398ZM18.7487 13.334C18.7487 12.6507 19.3154 12.084 19.9987 12.084C20.682 12.084 21.2487 12.6507 21.2487 13.334V21.6673C21.2487 22.3507 20.682 22.9173 19.9987 22.9173C19.3154 22.9173 18.7487 22.3507 18.7487 21.6673V13.334ZM21.532 27.3007C21.4487 27.5173 21.332 27.684 21.182 27.8507C21.0154 28.0007 20.832 28.1173 20.632 28.2007C20.432 28.284 20.2154 28.334 19.9987 28.334C19.782 28.334 19.5654 28.284 19.3654 28.2007C19.1654 28.1173 18.982 28.0007 18.8154 27.8507C18.6654 27.684 18.5487 27.5173 18.4654 27.3007C18.382 27.1007 18.332 26.884 18.332 26.6673C18.332 26.4507 18.382 26.234 18.4654 26.034C18.5487 25.834 18.6654 25.6507 18.8154 25.484C18.982 25.334 19.1654 25.2173 19.3654 25.134C19.7654 24.9673 20.232 24.9673 20.632 25.134C20.832 25.2173 21.0154 25.334 21.182 25.484C21.332 25.6507 21.4487 25.834 21.532 26.034C21.6154 26.234 21.6654 26.4507 21.6654 26.6673C21.6654 26.884 21.6154 27.1007 21.532 27.3007Z"
        :fill="fill" />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      fill: {
        type: String,
        default: 'black'
      }
    }
  }
  
  </script>
  
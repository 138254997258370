<template>
  <minus
    @click.native="orderStore.removeFromBasketItems(id)"
    :canRemove="
      orderStore.hashMappedBasketItems
        ? orderStore.hashMappedBasketItems[id]
          ? orderStore.hashMappedBasketItems[id].count > 0
            ? true
            : false
          : false
        : false
    "
  />
</template>
<script>
import { orderStore } from "@/stores";
import { Minus } from "@/components/svg";
import { observer } from "mobx-vue";
export default observer({
  name: "ProductReduceCounter",
  props: ["id"],
  data() {
    return {
      orderStore,
    };
  },
  components: {
    Minus,
  },
});
</script>

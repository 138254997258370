<template>
  <div 
      :class="['btn', 'text-IranSans-Medium', {'btn_disable': disable}]"
      v-bind="$attrs"  
      :id="id"
      v-on="$listeners"
    >  
      <p v-if="!loading">{{title}}</p>
      <div v-else :class="{'loading-spinner': loading}"></div>  
  </div>
</template>

<script>
export default {
    name: 'MpButton',
    props: {
      id: {
      type: String,
      },
      title: {
        type: String,
        require
      },
      loading: {
        type: Boolean,
        default: false
      },
      disable: {
        type: Boolean,
        default: false
      }
    }
    
}
</script>

<style lang="less" scoped>

.loader {
    border: 5px solid #fff;
    border-top: 5px solid #F1F1F1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
  }
  .loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #559847;
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: #519B44;
    border-radius: 4px;
    color: white;
    cursor: pointer;

    &_disable {
      background-color: rgb(180, 180, 180);
      cursor: not-allowed;      
    }

    
}
</style>
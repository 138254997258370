import Vue from 'vue'
import "./assets/tailwind.css";
import App from './App.vue'
import router from './router'
// import "./fonts/IranSans/IranSans.less"
import OtpInput from '@bachdgvn/vue-otp-input'
import Vuelidate from 'vuelidate'
import { SetupAxiosInterceptor } from './configs/axiosInterceptor'
import {checkTokenValidation} from './configs/checkTokenValidation'
// import { createPinia, PiniaVuePlugin } from 'pinia'

import 'vue-multiselect/dist/vue-multiselect.min.css'; // Import the CSS globally



import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'font-awesome/css/font-awesome.min.css';
Vue.component('font-awesome-icon', FontAwesomeIcon);







import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);

// const pinia = createPinia()
Vue.component("v-otp-input", OtpInput);
Vue.use(Vuelidate)
// Vue.use(PiniaVuePlugin)
Vue.config.productionTip = false
SetupAxiosInterceptor();
checkTokenValidation()
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
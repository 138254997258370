import { makeObservable, observable, computed, action } from "mobx";
class ConfigStore {
  _configService = {};
  _showPerformanceTab = false;
  _showNews=false;
  _showPrize=false;
  _showPaymentGateways=false;
  _showLead=false;
  _initialized = false;
  constructor() {
    makeObservable(this, {
      _configService: observable,
      configService: computed,
      initialize: action,
      showPerformanceTab: computed,
      showPaymentGateways:computed,
      showPrize:computed,
      showLead:computed,
      checkAndSetPerformanceTab: action,
    });
  }
  get configService() {
    return this._configService;
  }
  async initialize() {
    if (!this._initialized) {
      let selectedItem = window.configList[window.service];
      this._configService = selectedItem;
      this._showPerformanceTab = this._configService.showPerformanceTab;
      this._showPrize = this._configService.showPrize;
      this._showNews = this._configService.showNews
      this._showPaymentGateways = this._configService.showPaymentGateways;
      this._showLead = this._configService.showLead
      this._initialized = true;
    }
    return this._configService;
  }
  get showPerformanceTab() {
    return this._showPerformanceTab;
  }
  get showPrize(){
    return this._showPrize;
  }
  get showPaymentGateways(){
    return this._showPaymentGateways;
  }
  get showNews(){
    return this._showNews;
  }
  get showLead(){
    return this._showLead;
  }
  checkAndSetPerformanceTab() {
    const urlParams = new URLSearchParams(window.location.search);
    const showPerformanceTabParam = urlParams.get('showPerformanceTab');
    if (showPerformanceTabParam !== null) {
      sessionStorage.setItem('showPerformanceTab', showPerformanceTabParam);
      this._showPerformanceTab = showPerformanceTabParam === 'true';
    } else {
      const storedValue = sessionStorage.getItem('showPerformanceTab');
      if (storedValue !== null) {
        this._showPerformanceTab = storedValue === 'true';
      }
    }
  }
  checkAndSetShowPrize() {
    const urlParams = new URLSearchParams(window.location.search);
    const showPrizeParam = urlParams.get('showPrize');
    if (showPrizeParam !== null) {
      sessionStorage.setItem('showPrize', showPrizeParam);
      this._showPrize = showPrizeParam === 'true';
    } else {
      const storedValue = sessionStorage.getItem('showPrize');
      if (storedValue !== null) {
        this._showPrize = storedValue === 'true';
      }
    }
  }
  checkAndSetShowPaymentGateways() {
    const urlParams = new URLSearchParams(window.location.search);
    const showPaymentGatewaysParam = urlParams.get('showPaymentGateways');
    if (showPaymentGatewaysParam !== null) {
      sessionStorage.setItem('showPaymentGateways', showPaymentGatewaysParam);
      this._showPaymentGateways = showPaymentGatewaysParam === 'true';
    } else {
      const storedValue = sessionStorage.getItem('showPaymentGateways');
      if (storedValue !== null) {
        this._showPaymentGateways = storedValue === 'true';
      }
    }
  }
  checkAndSetShowNews(){
    const urlParams = new URLSearchParams(window.location.search);
    const showNewsParam = urlParams.get('showNews');
    if (showNewsParam !== null) {
      sessionStorage.setItem('showNews', showNewsParam);
      this._showNews = showNewsParam === 'true';
    } else {
      const storedValue = sessionStorage.getItem('showNews');
      if (storedValue !== null) {
        this._showNews = storedValue === 'true';
      }
    }
  }
  checkAndSetShowLead(){
    const urlParams = new URLSearchParams(window.location.search);
    const showLeadParam = urlParams.get('showLead');
    if (showLeadParam !== null) {
      sessionStorage.setItem('showLead', showLeadParam);
      this._showLead = showLeadParam === 'true';
    } else {
      const storedValue = sessionStorage.getItem('showLead');
      if (storedValue !== null) {
        this._showLead = storedValue === 'true';
      }
    }
  }
}
export const configStore = new ConfigStore();
configStore.initialize();
configStore.checkAndSetPerformanceTab();
configStore.checkAndSetShowPrize();
configStore.checkAndSetShowPaymentGateways();
configStore.checkAndSetShowNews();
configStore.checkAndSetShowLead();
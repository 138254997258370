<template>
    <mp-action-sheet ref="mpActionSheet" :isFullScreen="true"   @closed="closeSheet()" class="details pb-3">
      <div
        class="fixed right-3 flex flex-row-reverse items-center justify-between bg-white"
      >
        <img @click="close()" src="@/assets/close.svg" />
       
    
      </div>
      <p class="text-IranSans-Bold text-base ml-11 ">جزئیات جایزه </p>
      <div class="flex flex-col justify-center mt-7" dir="rtl">
        <div>
            <p class="mt-4 text-lg text-right"> {{ giftStore.giftItem.title }}</p>
            <div class="mt-10  whitespace-pre-wrap text-right">
                 {{ giftStore.giftItem.description }} 
            
            </div>
        </div>
      </div>
    </mp-action-sheet>
  </template>
  
  <script>
  import MpActionSheet from "@/components/MpActionSheet.vue";
  import { giftStore } from "@/stores";
  import MpButton from "@/components/MpButton.vue";
  import MpField from "@/components/MpField.vue";
  import { observer } from "mobx-vue";
  export default observer({
    components: { MpActionSheet, MpField, MpButton },
    data() {
      return {
        giftStore,
      };
    },

    methods: {
      numberWithCommas(x) {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      getDate() {
        let date = new Date(
          this.saleHistory?.order?.createdAt.substring(0, 10)
        ).toLocaleDateString("fa-IR");
        return date;
      },
      getIcon(product) {
        if (product?.meta?.images?.icon)
          return require(`@/assets/products/${product?.meta?.images?.icon}`);
        else return require(`@/assets/package.svg`);
      },
      close() {
        this.$refs.mpActionSheet.close()
        this.giftStore.toggleActionGift(false)
      },
      closeSheet(){
        this.giftStore.toggleActionGift(false)
      }
    },
    created() {

    },

  });
  </script>
  
  <style lang="less" scoped>
  .details {
    &_dashed-seperator {
      height: 2px;
      color: #d6d6d6;
      background-image: linear-gradient(to right, #d6d6d6 60%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 25px 1px;
      background-repeat: repeat-x;
    }
    &_seperator {
      border-top: 1px solid #ebebeb;
    }
  
    &_profit-btn {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 16%);
      background-color: #fff;
    }
  
    &_profit {
      height: 48px;
      background-color: #eef5ed;
      border-radius: 8px;
    }
  }
  </style>
  
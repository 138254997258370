<template>
  <div v-if="shouldDisplay"  class="payment_btn fixed bottom-7 pt-6 px-4 w-full z-10">
    <div v-if="formStep === null" class="w-full h-20 loading-spinner"></div>
    <div v-else>
      <div v-show="formStep === '1'" class="w-full">
        <mp-button @click="openAddressForm" title="تکمیل خرید" />
      </div>
      <div v-show="formStep === '2'" class="w-full">
        <div>
          <mp-button @click="openPaymentBox" title="پرداخت" />
          <mp-button class="mt-2 btn-edit-address" @click="openAddressForm"
            :title="isGift ? 'ویرایش گیرنده' : ' ویرایش آدرس'" />
        </div>
      </div>
      <div v-show="formStep === '3'">
        <div class="flex justify-between items-center mb-3" style="direction: rtl">
          <div class="flex flex-row items-center">
            <div
              class="flex flex-col items-center bg-gray-100 hover:bg-gray-200 transition ease-in-out delay-50 w-8 h-8 justify-center rounded-full mx-2 cursor-pointer border-gray-200 border"
              @click="backToBeforeState">
              <ArrowRight class="w-3 h-3" :fill="'#fff'" />
            </div>
            <p class="text-IranSans-Bold text-sm">مجموع قابل پرداخت:</p>
          </div>
          <p v-show="totalFinalPrice" class="text-IranSans-Medium text-sm">
            {{ numberCommas(totalFinalPrice) }} تومان
          </p>
        </div>
        <mp-button
          v-show="allowedPaymentGatwaysList.includes('ZEENAJI_PAYPING')" 
          @click="checkout('ONLINE')"
          title="پرداخت آنلاین" 
          id="online-rabeen" 
          :loading="loading" 
          :disable="loading" 
         />
        <mp-button 
          v-show="allowedPaymentGatwaysList.includes('LIMOOME_SHOP_ONLINE')" 
          id="online-limoome"
          @click="checkout('LIMOOME_SHOP_ONLINE')" 
          title="پرداخت آنلاین"
          :loading="loading" 
          :disable="loading" 
         />
        <mp-button 
          v-show="allowedPaymentGatwaysList.includes('C2C')" 
          @click="gotoC2cCheckout" 
          class="mt-2" 
          title="پرداخت کارت به کارت" 
          id="c2c"
          :disable="loading" 
          :loading="loading" 
        />
        <mp-button-snapp 
          v-show="isSnappPaymentEligible && allowedPaymentGatwaysList.includes('ZEENAJI_SNAPP_PAY')"
          @click="checkout('SNAPP_PAY')" 
          class="mt-2" 
          id="snapppay-rabeen"
          title="پرداخت اسنپ‌پی"
          :loading="loading" 
          :disable="loading" 
          :description="snappPayDescription"
         />
          <mp-button-snapp 
          v-show="isSnappPaymentEligible && allowedPaymentGatwaysList.includes('SNAPP_PAY')"
          @click="checkout('LIMOOME_SHOP_SNAPP_PAY')" 
          class="mt-2" 
          id="snapppay-limoome"
          title="پرداخت اسنپ‌پی"
          :loading="loading" 
          :disable="loading" 
          :description="snappPayDescription"
           />
        <mp-button-tara 
          v-show="allowedPaymentGatwaysList.includes('RABEEEN_TARA')" 
          @click="checkout('RABEEEN_TARA')"
          title="پرداخت تارا"
          description="الان بخر ، بعدا پرداخت کن" 
          id="tara-rabeen"
          class="mt-2" 
          :loading="loading" 
          :disable="loading" 
        />
        <div :class="['mt-4', 'text-IranSans-Medium', `payment_${messages.checkout.status}`, 'text-xs']">
          {{ messages.checkout.desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MpButton from "@/components/MpButton.vue";
import MpButtonSnapp from "@/components/MpButtonSnapp.vue";
import MpButtonTara from "@/components/MpButtonTara.vue";
import { ArrowRight } from "@/components/svg";
import { Converter } from '@/utils';
export default {
  name: "PaymentButton",
  components: {
    MpButton,
    MpButtonSnapp,
    MpButtonTara,
    ArrowRight,
  },
  props: {
    allowedPaymentGatwaysList: Array,
    OrderStatus: String,
    isC2C: Boolean,
    formStep: String,
    isGift: Boolean,
    totalFinalPrice: Number,
    loading: Boolean,
    isSnappPaymentEligible: Boolean,
    snappPayDescription: String,
    messages: Object,
  },
  computed: {
    shouldDisplay() {
      return this.allowedPaymentGatwaysList.length > 0 &&
        (this.OrderStatus === 'CREATED' || this.OrderStatus === 'PAYMENT_FAILURE' ||
          (this.OrderStatus === 'PAYMENT_PENDING' && !this.isC2C));
    }
  },
  methods: {
    openAddressForm() {
      this.$emit('openAddressForm');
    },
    openPaymentBox() {
      this.$emit('openPaymentBox');
    },
    backToBeforeState() {
      this.$emit('backToBeforeState');
    },
    checkout(type) {
      this.$emit('checkout', type);
    },
    gotoC2cCheckout() {
      if ((this.OrderStatus === 'PAYMENT_PENDING' && this.isC2C) || this.OrderStatus === 'PAYMENT_SUCCESS') {
      }else{
        this.$emit('gotoC2cCheckout');
      }
    },
    numberCommas(x) {
      return Converter.numberWithCommas(x)
    }
  }
}
</script>


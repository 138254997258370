<template>
  <div
    class="payment-success-page p-4 h-screen flex flex-col items-center justify-center text-IranSans-Bold"
  >
    <img class="" src="@/assets/tick-circle.svg" />
    <h2 class="mt-14 text-base text-center font-YekanBakh font-bold">
      اطلاعات پرداخت شما با موفقیت ثبت و به واحد مالی ارسال شد.
    </h2>
    <p class="text-sm font-normal mt-6 text-center leading-6 font-YekanBakh">
      نتیجه بررسی حداکثر تا ۴۸ ساعت آینده از طریق پیامک برای شما ارسال خواهد شد.
    </p>
    <!-- <div class="lmn-button-custom">
      <div class="button-container mt-8">
        <MpButton
          @click="handleConfirmationClick"
          class="p-4"
          title="بازگشت به صفحه اصلی"
        ></MpButton>
      </div>
    </div> -->
  </div>
</template>

<script>
import MpButton from "@/components/MpButton.vue";

export default {
  created() {
    this.loading = false;
  },
  components: {
    MpButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {

    handleConfirmationClick() {
      this.$router.push('/')
    },
  },
};
</script>

<style lang="less" scoped>
.payment-success-page {
  display: flex;
  flex-direction: column;
  direction: rtl;
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
}
</style>

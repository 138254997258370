<template>
    <div>
      <mp-action-sheet ref="mpActionSheet" :isFullScreen="true" class="details pb-3">
        <div>
          <div class="fixed right-3 flex flex-row-reverse items-center justify-between bg-white">
            <img @click="close()" src="@/assets/close.svg" />
            <p class="text-IranSans-Bold text-base mx-2"> تکمیل اطلاعات</p>
            <div></div>
          </div>
          <div class="flex flex-col w-full px-2 items-start justify-between pt-12" style="direction: rtl;">
            <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
              <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            </div>
            <div v-else class="flex flex-row w-full justify-around">
              <div class="flex flex-col items-start w-full">
                <mp-field :value="form.state" @change="form.state = $event.target.value" id="province"
                  class="ml-1 text-IranSans-Light" placeholder="استان" />
                <label v-if="$v.form.state.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
              </div>
              <div class="flex flex-col items-start w-full">
                <mp-field @change="form.city = $event.target.value" :value="form.city"
                  class="mr-1 text-IranSans-Light" placeholder="شهر" />
                <label v-if="$v.form.city.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <textarea rows="5" @change="form.details = $event.target.value" :value="form.details"
                class="mt-5 text-IranSans-Light text-md w-full  border-gray-100 outline-none border p-2 rounded-md text-gray-600 placeholder-gray-600" placeholder="آدرس" ></textarea>
              <label v-if="$v.form.details.$error" class="label-field text-IranSans-Light" for="province">اجباری</label>
            </div>
            <div class="flex flex-col items-start w-full">
              <mp-field @input="limitPostalCodeLength" @change="form.postalCode = $event.target.value"
                :value="form.postalCode" class="mt-5 text-IranSans-Light" placeholder="کدپستی" type="number" />
              <span v-if="$v.form.postalCode.$error " class="label-field text-IranSans-Light">  {{ !$v.form.postalCode.minLength === true ? " کدپستی باید ۱۰ رقم باشد" :"اجباری" }}</span>
            </div>
          </div>

          <div class="details_profit-btn fixed bottom-auto  left-0 p-5 w-full">
        
            <MpButton @click="onclick" title="ثبت اطلاعات " class="px-4"></MpButton>
            <div v-show="errorNetwork" class="text-IranSans-Medium text-red-500 my-3">
               <p>   <span class="mx-4 text-red-500 text-sm"> {{error.code}}</span>  
                   خطایی  پیش آمده است    </p>
                  </div>
          </div>
        </div>
      </mp-action-sheet>
    </div>  
  </template>
  
  <script>
  import MpActionSheet from "@/components/MpActionSheet.vue"
  import MpField from '@/components/MpField.vue'
  import MpButton from '@/components/MpButton.vue';
  import { observer } from "mobx-vue"
  import axios from "axios";
  import { required, minLength, maxLength ,numeric } from 'vuelidate/lib/validators';
  export default observer({
    components: { MpActionSheet, MpField, MpButton },
    props: {
      sellerCustomerOrderId: {
        type: String,
        required: true
      },
      orderAddress:{
        type:Object,
        required:false
      },
      orderIdForAddress:{
      type:String,
      required:false
    },
    },
    data() {
      return {
        loading: false,
        form: {
          state: null,
          city: null,
          details: null,
          postalCode: null,
          sellerCustomerOrderId: this.sellerCustomerOrderId,
          
        },
        isPatch:false,
        errorNetwork:false,
        error:{code:'',message:''}
      }
    },
    validations: {
      form: {
        state: { required },
        city: { required },
        details: { required },
        postalCode: {
          required,
          minLength: minLength(10)
        }
      }
    },
    methods: {
      async onclick() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
     
        this.loading = true;
       
        try {
          let res;
          if (this.isPatch) {
            const url = `${process.env.VUE_APP_BACKEND_API}/api/sales-network/address/${this.orderIdForAddress}`;
            let postData = {
             
                // firstName: this.personalInfo.firstName,
                // lastName: this.personalInfo.lastName,
                // mobile: this.personalInfo.mobile,
                country: this.form.country,
                state: this.form.state,
                city: this.form.city,
                details: this.form.details,
                postalCode: this.form.postalCode
            
            };
            res = await axios.patch(url,postData);
          }else{
            const url = `${process.env.VUE_APP_BACKEND_API}/api/salesNetwork/orders/${this.orderIdForAddress}/recipient`;
            let postData = {
              isGift: false,
              recipient: {
                // firstName: this.personalInfo.firstName,
                // lastName: this.personalInfo.lastName,
                // mobile: this.personalInfo.mobile,
                country: this.form.country,
                state: this.form.state,
                city: this.form.city,
                details: this.form.details,
                postalCode: this.form.postalCode
              }
            };
            res = await axios.post(url, postData);
          }
          
          if (res.data.err) {
            console.error(res.data.err);
            this.loading = false;
            this.errorNetwork = true
            this.error = res.data.err
            setTimeout(() => {
              this.errorNetwork  = false;
              this.error = {code:'',message:''}
            }, 3000);
          
          } else {
            this.loading = false;
            this.$refs.mpActionSheet.close();
            this.$emit('formAddressSubmitted', this.form);
          }
        } catch (error) {
          this.loading = false;
            this.errorNetwork = true
            this.error = {code:' ',message:'خطایی پیش آمده است لطفا مجدد تلاش نمایید'}
            setTimeout(() => {
              this.errorNetwork  = false;
              this.error = {code:'',message:''}
            }, 3000);
          console.error(error);
        }
      },
      close() {
        this.$refs.mpActionSheet.close();
      },
      limitPostalCodeLength(event) {
            const inputValue = event.target.value;
            const numericValue = inputValue.replace(/\D/g, '');
            this.form.postalCode = numericValue;
            if (numericValue.length > 10) {
                this.isValidPostalCode = false; 
                this.form.postalCode = numericValue.slice(0, 10);
            } else {
                this.isValidPostalCode = true; 
            }
      },
    },
    watch: {
    orderAddress: {
      handler: function(newVal) {

        if (newVal) {
          this.form.state = newVal.state || null;
          this.form.city = newVal.city || null;
          this.form.details = newVal.details || null;
          this.form.postalCode = newVal.postalCode || null;
          this.isPatch = true
        }
      },
      deep: true 
    }
  }
  })
  </script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    :fill="fill"
    clip-rule="evenodd"
    viewBox="0 0 512 502.778"
  >
    <path
      fill-rule="nonzero"
      d="M465.324 502.778H46.678C21.051 502.778 0 481.727 0 456.119V73.082C0 47.454 21.051 26.42 46.679 26.42h69.25v61.016c0 55.473 92.722 55.712 92.722 0V26.42h94.695v61.016c0 56.537 92.722 54.754 92.722 0V26.42h69.247c25.644 0 46.679 21.017 46.679 46.662 0 127.681.006 255.352.006 383.037 0 25.661-21.015 46.659-46.676 46.659zM309.023 304.395l-41.347 107.117h-48.417l38.348-96.619-51.416.642-3.214-37.919h106.046v26.779zM51.649 487.846h408.686c20.197 0 36.706-16.779 36.706-36.722v-253.07H14.956v253.082c0 20.149 16.49 36.71 36.693 36.71zM327.684 20.488c0-9.956 9.848-20.488 22.024-20.488 12.175 0 22.022 10.532 22.022 20.488v66.948c0 9.963-9.847 18.051-22.022 18.051-12.176 0-22.024-8.088-22.024-18.051V20.488zm-187.417 0c0-9.956 9.848-20.488 22.018-20.488 12.182 0 22.028 10.532 22.028 20.488v66.948c0 9.963-9.846 18.051-22.028 18.051-12.17 0-22.018-8.088-22.018-18.051V20.488z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#4B4C4D",
    },
  },
};
</script>

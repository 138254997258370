<template>
  <div>
    <v-chart class="chart" :option="option" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([CanvasRenderer, GaugeChart, TitleComponent, TooltipComponent, LegendComponent]);
export default {
  name: "GaugeChart",
  components: {
    VChart,
  },
  props: {
    chartValue: {
      default: 0,
      type: Number,
    },
    chartLabel:{
      default:0,
      type:Number
    }
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      //   option: {
      //     series: [
      //       {
      //         min: 0,
      //         max: 50,
      //         type: "gauge",
      //         axisLine: {
      //           lineStyle: {
      //             width: 8,
      //             color: [
      //               [0.03, "#a83232"],
      //               [0.05, "#eb9310"],
      //               [0.07, "#ebd510"],
      //               [0.3, "#099126"],
      //               [1, "#095291"],
      //             ],
      //           },
      //         },
      //         pointer: {
      //           itemStyle: {
      //             color: "auto",
      //           },
      //         },
      //         axisTick: {
      //           distance: -10,
      //           length: 5,
      //           lineStyle: {
      //             color: "#fff",
      //             width: 0,
      //           },
      //         },
      //         splitLine: {
      //           distance: -10,
      //           length: 2,
      //           lineStyle: {
      //             color: "#fff",
      //             width: 10,
      //           },
      //         },
      //         axisLabel: {
      //           color: "inherit",
      //           distance: 23,
      //           fontSize: 14,
      //         },
      //         detail: {
      //           valueAnimation: true,
      //           formatter: "{value}% ",
      //           color: "inherit",
      //         },
      //         data: [
      //           {
      //             value: parseInt(parseInt(this.chartValue * 100).toFixed(0)), // Initialize with prop value
      //           },
      //         ],
      //       },
      //     ],
      //   },
      option: {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "90%",
            min: 0,
            max: 100,
            splitNumber: 5,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.2, "#a83232"],
                  [0.4, "#eb9310"],
                  [0.6, "#ebd510"],
                  [0.8, "#099126"],
                  [1, "#095291"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "30%",
              width: 15,
              offsetCenter: [0, "-30%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: "auto",
                width: 6,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
            axisLabel: {
  color: "#559847",  
  fontSize: 11,      
  distance: 10,      
  formatter: function (value) {
    if (value === 0) {
      return "0"
    }
   else if (0 < value && value<= 20) {
      return "3";
    } else if (20 <value && value<= 40) {
      return "5";
    } else if (40 <value && value<= 60) {
      return "7";
    } else if (60 <value && value<= 80) {
      return "30";
    }
    else if (80 <value && value<= 100) {
      return "100";
    }else{
      return "";  
    }
  
  },
},
            title: {
              offsetCenter: [0, "-10%"],
              fontSize: 20,
            },
            detail: {
              fontSize: 25,
              offsetCenter: [0, "0%"],
              valueAnimation: true,
              formatter:  (value)=> {
                return `${parseInt(this.chartLabel * 100).toFixed(0)}%`;
              },
              color: "inherit",
            },
            data: [
              {
                value:
                  this.chartValue > 1
                    ? this.chartValue
                    : parseInt(this.chartValue * 100).toFixed(0),
                name: "",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {},
  watch: {
    chartValue(newVal) {
      if (newVal !== null) {
        this.option.series[0].data[0].value = parseInt(parseInt(newVal * 100).toFixed(0));
      }
    },
  },
};
</script>

<style scoped>
.chart {
  width: 250px;
  height: 250px;
}
</style>

<template>
  <mp-action-sheet
    ref="mpActionSheet"
    :isFullScreen="true"
    @closed="closeSheet()"
    class="details pb-3"
  >
    <div
      class="fixed right-3 flex flex-row-reverse items-center justify-between bg-white"
    >
      <img @click="close()" src="@/assets/close.svg" />
      <p v-html="announce.title" class="text-IranSans-Bold text-base mx-auto"></p>
    </div>

    <div class="flex flex-col justify-center mt-5" dir="rtl">
      <div>
        <template
          v-if="isImage(announce.image)
          "
        >
          <div class="relative">
            <img
              v-show="imageLoaded"
              :src="`${BLOG_BACKEND}/assets/${announce.image}`"
              @load="onImageLoad"
              alt="Media"
              class="rounded-lg mb-4 w-full"
            />
            <div
              v-show="!imageLoaded"
              class="w-full h-48 bg-gray-100 flex items-center justify-center rounded-lg mb-4"
            >
              <div class="loader"></div>
            </div>
          </div>
        </template>
        <template
          v-else-if="isVideo(announce.video)
          "
        >
          <div class="relative">
            <video
              v-show="videoLoaded"
                :src="`${BLOG_BACKEND}/assets/${announce.video}`"
              @loadeddata="onVideoLoad"
              controls
              class="rounded-lg mb-4 w-full"
            ></video>
            <div
              v-show="!videoLoaded"
              class="w-full h-48 bg-gray-100 flex items-center justify-center rounded-lg mb-4"
            >
              <div class="loader"></div>
            </div>
          </div>
        </template>
        <template
          v-else-if="isAudio(announce.audio)
          "
        >
          <div class="relative">
            <audio
              v-show="audioLoaded"
                :src="`${BLOG_BACKEND}/assets/${announce.audio}`"
              @loadeddata="onAudioLoad"
              controls
              class="rounded-lg mb-4 w-full"
            ></audio>
            <div
              v-show="!audioLoaded"
              class="w-full h-48 bg-gray-100 flex items-center justify-center rounded-lg mb-4"
            >
              <div class="loader"></div>
            </div>
          </div>
        </template>
        <div v-html="announce.body" class="mt-4 text-right"></div>
      </div>
    </div>
  </mp-action-sheet>
</template>

<script>
import MpActionSheet from '@/components/MpActionSheet.vue';
import MpButton from '@/components/MpButton.vue';
import MpField from '@/components/MpField.vue';
import { observer } from 'mobx-vue';

export default observer({
  components: { MpActionSheet, MpField, MpButton },
  data() {
    return {
      imageLoaded: false,
      videoLoaded: false,
      audioLoaded: false,
      BLOG_BACKEND: process.env.VUE_APP_BACKEND_BLOG_URL
    };
  },
  props: {
    announce: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    announce: {
      handler() {
        this.resetLoadingState();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.$refs.mpActionSheet.close();
    },
    closeSheet() {},
    isImage(url) {
      if (url && url !== null) {
        return true
      }
    },
    isVideo(url) {
      if (url && url !== null) {
        return true
      }
    },
    isAudio(url) {
      if (url && url !== null) {
        return true
      }
    },
    resetLoadingState() {
      this.imageLoaded = false;
      this.videoLoaded = false;
      this.audioLoaded = false;
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
    onVideoLoad() {
      this.videoLoaded = true;
    },
    onAudioLoad() {
      this.audioLoaded = true;
    },
  },
  created() {}
});
</script>

<style lang="less" scoped>
.details {
  .fixed {
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    padding: 1rem;
  }
  .text-IranSans-Bold {
    font-weight: bold;
  }
  .rounded-lg {
    border-radius: 8px;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #4f7a28;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>

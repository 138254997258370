<template>
  <p class="mx-1">
    {{
      orderStore.hashMappedBasketItems
        ? orderStore.hashMappedBasketItems[id]
          ? orderStore.hashMappedBasketItems[id].count
          : 0
        : 0
    }}
  </p>
</template>
<script>
import { orderStore } from "@/stores";
import { observer } from "mobx-vue";
export default observer({
  name: "ProductCount",
  props: ["id"],
  data() {
    return {
      orderStore,
    };
  },
});
</script>

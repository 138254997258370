<template>
  <div class="landing mx-auto mb-20">
        <img class="w-full" src="@/assets/buy-psychologist-package-banner.png"/>
        <div v-if="product" class="p-4">            
            <p class="text-IranSans-Bold mt-8 mb-3 text-right text-base">
                طرح ویژه روانشناس همراه لیمومی
            </p>

            <p class="text-IranSans-Medium text-right leading-6 mb-5" style="direction: rtl; font-size: 13px;">
                با خرید این طرح یکی از روانشناس‌های با تجربه‌ی تیم لیمومی
                در تمام مسیر رژیم در کنار شما خواهد بود تا با کمک هم مشکلاتی از قبیل استرس، بی حوصلگی، بی انگیزگی و فشارهای
                روانی را پشت سر بگذارید و سریع تر به هدف خود برسید!
            </p>

                <mp-card :border="true" class="mb-4 mt-6" bgColor="#EEF5ED" :selected="true" height="58px"
                    :discount="discount">
                    <template #icon>
                        <img class="ml-2" src="@/assets/mark.svg" />
                    </template>
                    <template #title>
                        <div class="flex items-center">
                            <p class="text-IranSans-Medium text-sm">{{ product?.meta?.title }}&nbsp;</p>
                            <p class="text-IranSans-Medium text-xs">({{ product?.meta?.subtitle }})</p>
                        </div>
                    </template>
                    <template #price>
                        <div class="flex items-center">
                            <p class="text-IranSans-Medium text-xs line-through ml-1"
                                style="color: #888888; text-decoration-thickness: 0px;">
                                {{ numberWithCommas(product.productPrice) }}</p>
                            <p class="text-IranSans-Medium text-sm">{{ numberWithCommas(finalPrice) }}&nbsp;</p>
                            <p class="text-IranSans-Medium text-xs">تومان</p>
                        </div>
                    </template>
                </mp-card>
                <div class="flex flex-grow"></div>

                <div class="landing_btn px-4 z-50 flex flex-col py-2">                    
                    <mp-button :loading="loading" @click="checkout" title="پرداخت" />
                    <div :class="['mt-2', 'text-IranSans-Medium', `landing_${messages.checkout.status}`, 'text-xs']">{{ messages.checkout.desc }}</div>
                </div>
            </div>
        <div v-else>Loading</div>
  </div>
</template>

<script>
import MpCard from '@/components/MpCard.vue'
import MpButton from "@/components/MpButton.vue"
import { OrderSevices } from '@/services/index'
//import Products from '@/configs/Products.json'
import { orderStore } from '@/stores'

export default {
    name: 'Payment',
    components: {
        MpCard,
        MpButton
    },
    data(){
        return {        
            orderStore,
            order: null,
            product: {       
                title: '۶ جلسه',
                desc: '(هر‌جلسه ۴۵ دقیقه)',
                price: 1700000,
                finalPrice: 850000,
                discount: "50"
            },
            loading: false,
            messages: {
                checkout: {
                    status: null,
                    desc: null
                }
            }      
        }
    },
    props: ['orderCustomerId'],
    created() {     
        this.getOrder();
    },
    methods: {  
        async checkout(){
            this.loading = true;
            this.resetData();          
            let result = await orderStore.checkout(this.order.basketItems[0].orderId);            
            if(result?.err){
                this.messages.checkout.desc = result.err.message;
                this.messages.checkout.status = 'error'
            }            
            this.loading = false;
        },
        resetData(){
            this.messages.checkout.desc = null;
            this.messages.checkout.status = null;
        },
        async getOrder(){                        
            let result = await OrderSevices.getOrder(this.orderCustomerId);                            
            this.order = result.order;
            this.seller = result.seller;
            this.customer = result.customer;
        }, 
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }, 
    },
    computed: {
        myProduct() {                
            if(!this.order)        
                return null;
            let productId = this.order ? this.order.basketItems[0].productId : null;    
            let product = packagesFromHtml.find((product) => {
                return product.id == productId ? product : null
            })                       
            return product;    
        },
        // productPrice(){           
        //     return this.order.basketItems[0].productPrice
        // },
        // customerFullName(){
        //     return this.order.customer.firstName + ' ' + this.order.customer.lastName
        // },
        // sellerFullName(){
        //     return this.seller.firstName + ' ' + this.seller.lastName
        // }
    }
}
</script>

<style lang="less" scoped>
.landing {
    max-width: 500px;    
    &_selected-product {
        height: 135px;
        width: 100%;       
    }

    &_btn {
        position: fixed;
        display: flex;
        align-items: center;
        background-color: #fff;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;       
        //box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    &_error {
        color: rgba(255, 107, 107, 1);
        direction: rtl;
    }
}

</style>
import Vue from "vue";
import VueRouter from "vue-router";
import { History, MainPage, PaymentResult, Payment, C2CPayment } from "@/views/"
import * as LoginRoutes from "@/views/login";
import { BuyPsychologistPackage } from "@/views/landing"
import { userStore,orderStore } from "@/stores";
import C2CPaymentSuccess from "@/views/payment/C2CPaymentSuccess.vue";
import Playground from "@/views/Playground.vue";


Vue.use(VueRouter);
const whitelist = ['payment'];
const routes = [
  {
    beforeEnter: async (to, from, next) => {
      if (whitelist.includes(to.name)) {
        next();
        return;
      }
      try {
        await userStore.checkIsLoggedIn();
        // console.log('router userStore.isLoggedIn: ', userStore.isLoggedIn);
        if (userStore.isLoggedIn) {
          next();
        } else {
          router.push("/enter");
        }
      } catch (error) {
        router.push("/enter");
      }
    },
    path: "/",
    name: "home",
    component: MainPage,
  },
  {
    beforeEnter: async (to, from, next) => {
      if (to.query?.showGift === "true" ) {
        window.sessionStorage.setItem("showGift",true)
      }
      if (to.query?.showGift === "false" ) {
        window.sessionStorage.setItem("showGift",false)
      }
      next()
    },
    path: '/payment/:orderCustomerId',
    name: 'payment',
    props: true,
    component: Payment
  },
  {
    path: "/history",
    name: "history",
    component: History,
  },
  {
    path: '/verify-payment',
    name: 'verify-payment',
    component: PaymentResult
  },
  {
    path: '/buy-psychology-package/:orderCustomerId',
    name: 'buy-psychology-package',
    props: true,
    component: BuyPsychologistPackage
  },
  {
    beforeEnter: async (to, from, next) => {
      if (whitelist.includes(to.name)) {
        next();
        return;
      }
      try {
        await userStore.checkIsLoggedIn();
        if (userStore.isLoggedIn) {
          router.push("/");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    },
    path: "/enter",
    component: LoginRoutes.EnterMobile,
  },
  {
    beforeEnter: async (to, from, next) => {
      try {
        await userStore.checkIsLoggedIn();
        if (userStore.isLoggedIn) {
          router.push("/");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    },
    path: "/login-with-mobile",
    component: LoginRoutes.LoginWithMobile,
  },
  { 
    beforeEnter: async (to, from, next) => {
      try {
        let fetchedData =  await orderStore.fetchOrderById(to.params.orderCustomerId); 
        let param = fetchedData.order.orderStatuses;
        let pendIsThere = false
        if (param && param.length > 0) {
          param.map((item) => {
            if (item.status === 'PAYMENT_PENDING') {
              pendIsThere = true
            }
          });
          if (pendIsThere) {
            try {
              const result = await orderStore.getPaymentById( fetchedData.order.paymentId );
              if (result  && result.gateway === "C2C") {
                next(`/payment/${to.params.orderCustomerId}`);
              }else{
                next()
              }
            } catch (error) {
              next()
            }
          }else{
            next()
          }
        }else{
          next()
        }
      } catch (error) {}
    },
    path: "/c2c-payment/:orderCustomerId",
    name: 'c2c-payment',
    component: C2CPayment,
  },
  {
    path: '/c2c-payment-success',
    name: 'c2c-payment-sucess',
    component: C2CPaymentSuccess
  }, {
    path: '/Playground',
    name: 'playground',
    component: Playground
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

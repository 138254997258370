<script>
import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment-jalaali";
import { observer } from "mobx-vue";
import { Constants } from "@/utils";
import { OrderSevices } from "@/services";
import { orderStore, configStore } from "@/stores";
import MpButton from "@/components/MpButton.vue";
import MpLoadingSpinner from "@/components/MpLoadingSpinner.vue";
import CheckoutErrorSection from "@/components/CheckoutErrorSection.vue";

export default observer({
  name: "c2c-payment",
  created() {
    this.getOrder();
    this.setupYears();
    this.initialize();
  },

  components: {
    MpButton,
    MpLoadingSpinner,
    CheckoutErrorSection,
  },
  mounted() {},
  data() {
    return {
      card_digits: "",
      pay_minute: "",
      pay_hour: "",
      pay_day: "",
      pay_month: "",
      pay_year: "",
      jalaali_months: Constants.months,
      years: [],
      totalFinalPrice: null,

      // loadings
      checkoutNetworkState: "IDLE", // IDLE, SUCCESS, FAILED, PENDING
      getOrderNetworkState: "IDLE", // IDLE, SUCCESS, FAILED, PENDING

      //
      image: null,
      order: null,
      customer: null,
      messages: {
        checkout: {
          status: null,
          desc: null,
        },
      },
    };
  },
  validations: {
    card_digits: {
      minLength: minLength(4),
      required,
    },
    pay_minute: {
      required,
    },
    pay_hour: {
      required,
    },
    pay_day: {
      required,
    },
    pay_month: {
      required,
    },
    pay_year: {
      required,
    },
  },
  methods: {
    async initialize() {
      this.image = require(`@/assets/${configStore.configService.c2cImage}`);
    },
    async getInvoiceData(id) {
      try {
        await orderStore.getInvoiceById(id);
      } catch (error) {}

      this.totalFinalPrice = orderStore.invoice?.totalFinalPrice
        ? orderStore.invoice?.totalFinalPrice
        : null;
    },
    handleGetOrderNeworkError() {
      this.$router.back();
    },

    setupYears() {
      const currentDate = new Date();
      this.years = [
        moment(currentDate).jYear(),
        moment(currentDate).subtract(1, "jYear").jYear(),
      ];
    },
    handlePaymentCard() {
      if (this.$v.$invalid) {
      } else {
        this.checkout(this.order.basketItems[0].orderId, this.customer.id);
      }
    },
    handleCheckoutNetworkErrorRetry() {
      this.checkoutNetworkState = "IDLE";
    },
    async getOrder() {
      try {
        const result = await orderStore.fetchOrderById(
          this.$route.params.orderCustomerId
        );
        this.order = result.order;
        this.customer = result.customer;
        this.getInvoiceData(this.order.id);
      } catch (error) {}
      this.getOrderNetworkState = "PENDING";
      if (orderStore.currentOrder) {
        this.getOrderNetworkState = "SUCCESS";
      } else {
        this.getOrderNetworkState = "FAILED";
      }
    },

    async checkout(orderId, customerId) {
      this.checkoutNetworkState = "PENDING";
      const paymentData = {
        lastDigits: this.card_digits
          .replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d))
          .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d)),
        day: this.pay_day,
        month: Number(this.pay_month) + 1,
        year: this.pay_year,
        minute: this.pay_minute,
        hour: this.pay_hour,
      };
      try {
        const result = await OrderSevices.c2cCheckout(orderId, customerId, paymentData);

        if (result.err) {
          throw result.err;
        }
        this.checkoutNetworkState = "SUCCESS";
        if (result.paymentId) {
          // TODO: implement functionality if c2c successfull
          this.$router.push("/c2c-payment-success");
        }
      } catch (error) {
        // TODO: implement error chekcing if there is
        this.checkoutNetworkState = "FAILED";
        this.message.checkout.desc = error.message;
        this.message.checkout.status = "error";
      }
    },
  },
});
</script>

<template>
  <div class="c2c-payment text-IranSans-Bold">
    <!-- LOADING -->
    <div
      v-if="getOrderNetworkState === 'PENDING'"
      class="loading h-screen flex flex-col items-center justify-center"
    >
      <MpLoadingSpinner
        @onclick="handleCheckoutNetworkErrorRetry"
        color="#559847"
        :size="32"
        class="mb-4"
      />
      <p>در حال بارگیری اطلاعات پرداخت ...</p>
    </div>
    <!-- ERROR -->
    <div
      v-else-if="getOrderNetworkState === 'FAILED'"
      class="error h-screen flex flex-col items-center justify-center"
    >
      <div class="border rounded-xl p-4">
        <p>متاسفانه مشکلی پیش آمده لطفا مجددا تلاش نمایید.</p>
      </div>
      <div class="button-container mt-4 w-32">
        <MpButton @click="handleGetOrderNeworkError" title="تلاش مجدد" />
      </div>
    </div>
    <!-- SUCCESS -->
    <div v-else class="text-IranSans-Bold text-right">
      <!-- header -->
      <div class="header"></div>
      <!-- checkout error -->
      <tempalate v-if="checkoutNetworkState === 'FAILED'">
        <div class="h-screen flex flex-col items-center justify-center">
          <CheckoutErrorSection @onclick="handleCheckoutNetworkErrorRetry" />
        </div>
      </tempalate>
      <!-- checkout ok -->
      <template v-else>
        <!-- main -->
        <div class="main flex-grow flex flex-col gap-4 p-4 rounded-sm">
          <h2 class="text-base font-bold">کارت به کارت</h2>
          <div class="border p-4 rounded-lg" style="border-color: #bdbdbd">
            <div class="flex justify-between items-center" style="color: #559847">
              <p class="text-sm font-bold">مبلغ قابل پرداخت</p>
              <p
                v-show="totalFinalPrice !== null"
                class="text-sm font-bold inline-flex gap-1"
              >
                <span class="font-bold font-IranSans-numbers">
                  {{
                    totalFinalPrice !== null ? totalFinalPrice.toLocaleString("fa") : ""
                  }}
                </span>

                <span>تومان</span>
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <h2 class="text-base font-bold">نکات واریز وجه</h2>
            <div
              class="border p-4 rounded-lg text-xs"
              style="border-color: #bdbdbd; color: #888888"
            >
              <ul class="text-right leading-5">
                <li class="list-item">لطفا به تفاوت ریال و تومان دقت فرمایید.</li>
                <li class="list-item">از پرداخت بیش از میزان مشخص شده پرهیز کنید.</li>
                <li class="list-item">
                  لطفا از اپلیکیشن «کلید» برای انتقال وجه استفاده نکنید.
                </li>
                <li class="list-item">
                  چنانچه در حین انتقال با مشکل مواجه شدید، مشکل خود را با پشتیبانی در میان
                  بزارید.
                </li>
              </ul>
            </div>
          </div>
          <!-- 1 -->
          <div class="flex flex-col gap-4">
            <p class="text-sm leading-6" style="color: #333333">
              ۱.جهت پرداخت کارت به کارت، ابتدا مبلغ قابل پرداخت را به شماره کارت زیر
              انتقال داده و رسید واریزی را نزد خود نگه دارید.
            </p>
            <img :src="image" alt=" card info" />
          </div>
          <!-- 2 -->

          <div class="flex flex-col gap-4">
            <p class="text-sm leading-6" style="color: #333333">
              ۲.مطابق اطلاعات رسید واریزیتان اطلاعات زیر را تکمیل و سپس دکمه ثبت و ارسال
              را بزنید تا به منظور بررسی و تایید به واحد مالی ارسال شود.
            </p>
            <div class="flex flex-col gap-4">
              <p class="text-xs font-medium" style="color: #333333">
                ۴ رقم آخر شماره کارت بانکی خود که با آن مبلغ فوق را پرداخت کرده اید اینجا
                وارد نمایید:
              </p>
              <div class="flex justify-between items-center">
                <input
                  v-model="card_digits"
                  type="text"
                  class="rounded-lg p-2 font-IranSans-numbers flex justify-center items-center text-center"
                  maxlength="4"
                  placeholder="- - - -"
                  style="border: 1px solid #559847; width: 72px"
                />
                <input
                  disabled
                  type="text"
                  class="rounded-lg p-2 flex justify-center items-center text-center"
                  placeholder="- - - -"
                  style="background: #f1edee; width: 72px"
                />
                <input
                  disabled
                  type="text"
                  class="rounded-lg p-2 flex justify-center items-center text-center"
                  placeholder="- - - -"
                  style="background: #f1edee; width: 72px"
                />
                <input
                  disabled
                  type="text"
                  class="rounded-lg p-2 flex justify-center items-center text-center"
                  placeholder="- - - -"
                  style="background: #f1edee; width: 72px"
                />
              </div>
            </div>
            <div class="flex flex-col gap-4">
              <p class="text-xs font-medium" style="color: #333333">
                تاریخ موجود در رسید واریزی را وارد نمایید:
              </p>
              <div class="flex justify-center items-center gap-4">
                <select v-model="pay_day" class="lmn-select font-IranSans-numbers">
                  <option disabled hidden value="">روز</option>
                  <option
                    v-for="i in Array.from({ length: 31 }, (_, i) => i)"
                    :value="i + 1"
                  >
                    {{ i + 1 }}
                  </option>
                </select>
                <select v-model="pay_month" class="lmn-select font-IranSans-numbers">
                  <option disabled hidden value="">ماه</option>
                  <option v-for="(month, i) in jalaali_months" :value="i">
                    {{ month.name }}
                  </option>
                </select>
                <select v-model="pay_year" class="lmn-select font-IranSans-numbers">
                  <option disabled hidden value="">سال</option>
                  <option v-for="(year, i) in years" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex flex-col gap-4">
              <p class="text-xs font-medium" style="color: #333333">
                ساعت واریز را وارد نمایید:
              </p>
              <div class="flex justify-center items-center gap-4">
                <select v-model="pay_minute" class="lmn-select font-IranSans-numbers">
                  <option disabled hidden value="">دقیقه</option>
                  <option v-for="i in Array.from({ length: 60 }, (_, i) => i)" :value="i">
                    {{ i }}
                  </option>
                </select>
                <span>:</span>
                <select v-model="pay_hour" class="lmn-select font-IranSans-numbers">
                  <option disabled hidden value="">ساعت</option>
                  <option v-for="i in Array.from({ length: 24 }, (_, i) => i)" :value="i">
                    {{ i }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- footer -->
        <div
          class="footer bg-white z-10 flex justify-center items-center w-full p-4 pb-8"
        >
          <mp-button
            @click="handlePaymentCard"
            :disable="$v.$invalid"
            :loading="checkoutNetworkState === 'PENDING'"
            title="تایید مشخصات پرداخت"
          ></mp-button>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="less" scoped>
.c2c-payment {
  direction: rtl;

  .list-item::before {
    content: "";
    margin-left: 1px;
    display: inline-flex;
    align-items: flex-end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ffc837;
  }

  .lmn-select {
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0;
    border: 1px solid #559847;
    min-height: 40px;
    max-width: 108px;
    width: 108px;
  }
}
</style>
